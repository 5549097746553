const mutationsTypes = {
  SET_EXCESSES_LIST: 'SET_EXCESSES_LIST',
  PUSH_EXCESSES_LIST: 'PUSH_EXCESSES_LIST'
};

// initial state
const initState = {
  excessesList: {
    data: [],
    lastUpdate: null
    // isMore: true,
  }
};

// getters
const getters = {
  excesses: (state) => state.excessesList.data,
  isMoreExcesses: (state, getter, rootState, rootGetters) => !rootGetters['db/getCounts'].excesses
};

// actions
const actions = {
  registerTable: {
    root: true,
    handler ({ dispatch }) {
      dispatch('db/addTable', { excesses: '++, ts, tag.id' }, { root: true });
    }
  },
  reloadExcesses ({
    state,
    dispatch,
    rootGetters
  }, tagId) {
    const limit = state.excessesList.data.length + rootGetters &&
      rootGetters.config &&
      rootGetters.config.api &&
      rootGetters.config.api.requestLimit
      ? rootGetters.config.api.requestLimit
      : 10;
    if (Number.isInteger(tagId)) {
      this.$http.get('tag/excesses', {
        params: {
          id: tagId
        }
      }).then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'excesses', data: result.data.data }, { root: true });
        }
      }).finally(() => {
        dispatch('db/select', {
          table: 'excesses', callback: `excesses/${mutationsTypes.SET_EXCESSES_LIST}`, limit, where: { 'tag.id': tagId }, attribute: 'excesses'
        }, { root: true });
      });
    } else {
      this.$http.get('client/excesses').then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'excesses', data: result.data.data }, { root: true });
        }
      }).finally(() => dispatch('db/select', { table: 'excesses', callback: `excesses/${mutationsTypes.SET_EXCESSES_LIST}`, limit }, { root: true }));
    }
  },
  getExcesses ({
    state,
    dispatch,
    rootGetters
  }, tagId) {
    const limit = rootGetters &&
      rootGetters.config &&
      rootGetters.config.api &&
      rootGetters.config.api.requestLimit
      ? rootGetters.config.api.requestLimit
      : 10;
    const refresh = rootGetters &&
      rootGetters.config &&
      rootGetters.config.api &&
      rootGetters.config.api.refreshEvery
      ? rootGetters.config.api.refreshEvery
      : 5 * 60 * 1000;
    if (Number.isInteger(tagId)) {
      dispatch('db/select', {
        table: 'excesses', callback: `excesses/${mutationsTypes.SET_EXCESSES_LIST}`, limit, where: { 'tag.id': tagId }
      }, { root: true });
      this.$http.get('tag/excesses', {
        params: {
          id: tagId
        }
      }).then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'excesses', data: result.data.data }, { root: true });
        }
      })
        .finally(() => {
          dispatch('db/select', {
            table: 'excesses', callback: `excesses/${mutationsTypes.SET_EXCESSES_LIST}`, limit, where: { 'tag.id': tagId }
          }, { root: true });
        });
    } else {
      dispatch('db/select', { table: 'excesses', callback: `excesses/${mutationsTypes.SET_EXCESSES_LIST}`, limit }, { root: true });
      if (new Date() - state.excessesList.lastUpdate > refresh) {
        this.$http.get('client/excesses').then((result) => {
          if (result && result.data && result.data.data) {
            dispatch('db/add', { table: 'excesses', data: result.data.data }, { root: true });
          }
        })
          .finally(() => dispatch('db/select', { table: 'excesses', callback: `excesses/${mutationsTypes.SET_EXCESSES_LIST}`, limit }, { root: true }));
      }
    }
  },
  getMoreExcesses ({
    state,
    // commit,
    rootGetters,
    dispatch
  }, tagId) {
    const limit = state.excessesList.data.length + (rootGetters &&
  rootGetters.config &&
  rootGetters.config.api &&
  rootGetters.config.api.requestLimit
      ? rootGetters.config.api.requestLimit
      : 10);
    const refresh = rootGetters &&
        rootGetters.config &&
        rootGetters.config.api &&
        rootGetters.config.api.refreshEvery
      ? rootGetters.config.api.refreshEvery
      : 5 * 60 * 1000;
    if (Number.isInteger(tagId)) {
      if (new Date() - state.excessesList.lastUpdate > refresh) {
        dispatch('reloadExcesses', tagId);
      } else {
        const offset = state.excessesList.data[state.excessesList.data.length - 1].ts.to;
        this.$http.get('tag/excesses', {
          params: {
            id: tagId,
            offset,
            limit: rootGetters &&
          rootGetters.config &&
          rootGetters.config.api &&
          rootGetters.config.api.requestLimit
              ? rootGetters.config.api.requestLimit
              : 10
          }
        }).then((result) => {
          if (result && result.data && result.data.data) {
            dispatch('db/add', { table: 'excesses', data: result.data.data }, { root: true });
          }
        })
          .finally(() => {
            dispatch('db/select', {
              table: 'excesses', callback: `excesses/${mutationsTypes.SET_EXCESSES_LIST}`, limit, where: { 'tag.id': tagId }
            }, { root: true });
          });
      }
    } else if (new Date() - state.excessesList.lastUpdate > refresh) {
      dispatch('reloadExcesses');
    } else {
      const offset = state.excessesList.data[state.excessesList.data.length - 1].ts.to;
      this.$http.get('client/excesses', {
        params: {
          offset,
          limit: rootGetters &&
        rootGetters.config &&
        rootGetters.config.api &&
        rootGetters.config.api.requestLimit
            ? rootGetters.config.api.requestLimit
            : 10
        }
      }).then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'excesses', data: result.data.data }, { root: true });
        }
      })
        .finally(() => {
          dispatch('db/select', { table: 'excesses', callback: `excesses/${mutationsTypes.SET_EXCESSES_LIST}`, limit }, { root: true });
        });
    }
  }
};

// mutations
const mutations = {
  [mutationsTypes.SET_EXCESSES_LIST]: (state, excesses) => {
    state.excessesList.isMore = state.excessesList.data !== excesses;
    state.excessesList.data = excesses;
    state.excessesList.lastUpdate = new Date();
  },
  [mutationsTypes.PUSH_EXCESSES_LIST]: (state, excesses) => {
    state.excessesList.data.push(...excesses);
    state.excessesList.lastUpdate = new Date();
  }
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations
};
