<template>
  <section class="login-form box">
    <b-field :label="$t('signup.cellular.cellular')">
      <b-input
        v-model="cellular"
        v-mask="'(+##) # ## ## ## ##'"
        placeholder="+33 XXXXXXXXX"
        icon="mobile fa-xs"
        disabled
      />
    </b-field>
    <b-field
      grouped
      group-multiline
      expanded
      position="is-centered"
    >
      <div class="control">
        <button
          class="button is-success is-pulled-left"
          @click="askCode"
        >
          {{ $t('signup.cellular.confirmCellular') }}
        </button>
      </div>
      <div class="control">
        <button
          class="button is-danger is-pulled-right"
          @click="$router.push({name: 'Cellular Reset'})"
        >
          {{ $t('signup.cellular.changeCellular') }}
        </button>
      </div>
    </b-field>
    <b-field
      v-if="step"
      :label="$t('signup.cellular.enterCode')+cellular"
    >
      <b-input
        v-model="secretCode"
        placeholder="XXXXXX"
        icon="key fa-xs"
      />
    </b-field>
    <b-field
      v-if="step"
      grouped
      group-multiline
      expanded
      position="is-centered"
    >
      <div class="control">
        <button
          class="button is-success is-pulled-left"
          @click="sendCode"
        >
          {{ $t('signup.cellular.confirmCode') }}
        </button>
      </div>
    </b-field>
  </section>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  name: 'CellularReset',
  directives: {
    mask
  },
  data () {
    return {
      clientId: '',
      clientInput: '',
      cellular: '',
      secretCode: '',
      step: false
    };
  },
  mounted () {
    this.$http.get('signup/info').then((result) => {
      this.cellular = result.data.data.cellular;
    });
  },
  methods: {
    askCode () {
      this.$http.post('signup/cellular/reset', {
        cellular: this.cellular
      }).then(() => {
        this.step = 'check';
      });
    },
    sendCode () {
      this.$http.post('signup/cellular/reset', {
        cellular: this.cellular,
        code: this.secretCode
      }).then(() => {
        this.$router.push({
          name: 'signupRouteur'
        });
      }).catch((error) => {
        this.$buefy.notification.open({
          duration: 30000,
          message: this.$t(error.code),
          type: 'is-danger',
          'has-icon': true
        });
      });
    }
  }
};
</script>

<style>

</style>
