import Axios from 'axios';
// import {
//   cacheAdapterEnhancer,
//   throttleAdapterEnhancer
// } from 'axios-extensions';
import { NotificationProgrammatic as Notification } from 'buefy/dist/components/notification';
import { Snackbar } from 'buefy/dist/components/snackbar';

import config from '@/config';
import enhanceError from './utils/enhanceError';
import { nodeIsDebug } from './utils/utils';

import vuexStore from './vuex/store';

export function install (Vue) {
  if (install.installed && Vue) {
    if (nodeIsDebug()) {
      // console.warn('[frontlines] already installed.
      // Vue.use(VueWait) should be called only once.');
    }
    return;
  }

  Vue.mixin({
    /**
     * VueWait init hook, injected into each instances init hooks list.
     */
    beforeCreate () {
      const {
        frontlines,
        store,
        parent,
        router,
        i18n
      } = this.$options;

      let instance = null;
      if (frontlines) {
        instance = typeof frontlines === 'function' ? new frontlines() : frontlines; // eslint-disable-line
        // Inject store
        instance.init(Vue, store, router, i18n);
      } else if (parent && parent.$frontlineInstance) {
        instance = parent.$frontlineInstance;
        instance.init(Vue, parent.$store, parent.$router, parent.$i18n);
      }
      if (instance) {
        // Store helper for internal use
        this.$frontlineInstance = instance;
        this[instance.options.accessorName] = instance;
        this.$http = instance.http;
        if (this.$store) this.$store.$http = instance.http;
        this.$httpSignage = instance.httpSignage;
        if (this.$store) this.$store.$httpSignage = instance.httpSignage;
      }
    }
  });

  install.installed = true;
}

export default class Frontlines {
  constructor (options = {}) {
    const defaults = {
      accessorName: '$frontlines',
      // Vuex Options
      vuexModuleName: 'frontlines',
      baseURL: ''
    };
    this.options = {
      ...defaults,
      ...options
    };
    this.initialized = false;
  }

  init (Vue, store, router, i18n) {
    if (nodeIsDebug() && !install.installed) {
      // console.warn('[frontlines] not installed.
      // Make sure to call `Vue.use(VueWait)` before init root instance.');
    }

    if (this.initialized) {
      return;
    }

    const {
      vuexModuleName
    } = this.options;
    if (!store) {
      throw new Error('[frontlines] Vuex not initialized.');
    }
    this.store = store;

    if (!store._modules.get([vuexModuleName])) { // eslint-disable-line
      store.registerModule(vuexModuleName, vuexStore);
    }

    if (!router) {
      throw new Error('[frontlines] vue-router not initialized.');
    }
    this.router = router;

    this.bearerHandler = new Vue({
      computed: {
        bearer: () => store.getters[`${vuexModuleName}/bearer`]
      }
    });

    const axios = Axios.create({
      withCredentials: true,
      baseURL: this.options.baseURL
      // adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(
      //   Axios.defaults.adapter,
      //   { enabledByDefault: false }
      // ), { threshold: 2 * 1000 })
    });

    const axiosSignage = Axios.create({
      baseURL: config.signageUri
    });

    // implement cancelToken to limit requests after an error
    const { CancelToken } = Axios;
    let axiosSource = CancelToken.source();

    const offlineQueue = [];

    function sendRequest (request) {
      return axios.post('notification/request', {
        request
      });
    }

    function sendTask (task) {
      return axios.post('task/request', {
        task
      });
    }
    // Add a request interceptor
    axios.interceptors.request.use((requestConfig) => {
      this.store.dispatch(`${vuexModuleName}/startLoading`);
      const wRequestConfig = requestConfig;
      wRequestConfig.cancelToken = axiosSource.token;
      wRequestConfig.headers.Authorization = this.bearerHandler.bearer;
      return wRequestConfig;
    }, (error) => {
      Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use((response) => {
      this.store.dispatch(`${vuexModuleName}/stopLoading`);
      if (!response) return false;
      if (response && response.data && !response.data.success) {
        // if (process.env.NODE_ENV !== 'production') {
        //   Notification.open({
        //     duration: 30000,
        //     message: `axios: ${response.data.status}: ${response.data}`,
        //     position: 'is-bottom',
        //     type: 'is-danger',
        //     'has-icon': true
        //   });
        // }
        // return response;
        // if (response.data.data && response.data.data.code) {
        //   return Promise.reject(enhanceError(new Error(response.data.data.code),
        //     response.config, response.data.data.code, response.request, response));
        // }
        // return Promise.reject(enhanceError(new Error(response.status),
        //   response.config, response.data.data.code, response.request, response));
        const errorCode = (response &&
          response.data &&
          response.data.data &&
          response.data.data.code)
          ? response.data.data.code
          : 'code.notSuccess';
        return Promise.reject(enhanceError(new Error(errorCode),
          response.config, errorCode, response.request, response));
        // return Promise.reject(new Error(errorCode));
      }
      if (response && response.data && response.data.data && response.data.data.mpToken) {
        this.store.dispatch(`${vuexModuleName}/setBearer`, response.data.data.mpToken, {
          root: true
        });
      }
      if (response && response.data && response.data.data && response.data.data.request) {
        sendRequest(response.data.data.request)
          .catch((e) => Snackbar.open({
            indefinite: true,
            message: `${i18n.t('request.error', e)}`,
            type: 'is-warning',
            actionText: i18n.t('request.retry'),

            onAction: () => sendRequest(response.data.data.request)
          }));
      }
      if (response && response.data && response.data.data && response.data.data.task) {
        sendTask(response.data.data.task)
          .catch((e) => Snackbar.open({
            indefinite: true,
            message: `${i18n.t('task.error', e)}`,
            type: 'is-warning',
            actionText: i18n.t('task.retry'),

            onAction: () => sendTask(response.data.data.task)
          }));
      }
      if (response.config && response.config.isRetry) {
        offlineQueue.map((snack) => snack.close());
      }
      return response;
    }, (error) => {
      this.store.dispatch(`${vuexModuleName}/stopLoading`);
      const errorResponse = error.response;
      if (error && error.message === 'Network Error' && error.config && error.config.method === 'get') {
        axiosSource = CancelToken.source();
        const lastSnack = Snackbar.open({
          indefinite: true,
          message: `${i18n.t('application.offline')}`,
          type: 'is-info',
          actionText: i18n.t('application.retry'),

          onAction: () => axios({ ...error.config, isRetry: true })
        });
        offlineQueue.push(lastSnack);
        return {
          data: {
            success: false,
            data: false,
            offline: true
          }
        };
      }
      if (errorResponse && errorResponse.status === 403) {
        return undefined;
      }
      if (errorResponse && errorResponse.status === 401) {
        if (errorResponse.config && !errorResponse.config.autoRetry) {
          axiosSource.cancel(`cancel: ${error}`);
          axiosSource = CancelToken.source();
          errorResponse.config.autoRetry = true;
          return axios(errorResponse.config);
        }
        axiosSource = CancelToken.source();
        this.store.dispatch('auth/setRememberMe', false);
        this.store.dispatch('db/clearIndexedDb');
        offlineQueue.map((snack) => snack.close());
        Notification.open({
          duration: 30000,
          message: `${i18n.t('application.sessionExpired')}`,
          position: 'is-bottom',
          type: 'is-danger',
          'has-icon': true
        });
        return router.push({
          name: 'Login'
        });
      }
      if (Axios.isCancel(error)) {
        return undefined;
      }
      axiosSource.cancel(`cancel: ${error}`);
      if (process.env.NODE_ENV !== 'production') {
        Notification.open({
          duration: 30000,
          message: `axios: Something's not good: "${error}" Please retry later`,
          position: 'is-bottom',
          type: 'is-danger',
          'has-icon': true
        });
      }

      // else {
      //   axiosSource = CancelToken.source();
      //   this.store.dispatch('auth/setRememberMe', false);
      //   this.store.dispatch('db/clearIndexedDb');
      //   router.push({
      //     name: 'Login'
      //   });
      // }
      // if (errorResponse && errorResponse.config && errorResponse.config.method === 'get') {
      //   router.push({ name: 'Déconnexion' });
      // }
      axiosSource = CancelToken.source();
      return Promise.reject(error);
    });
    this.http = axios;
    this.initialized = true;
    this.httpSignage = axiosSignage;
  }
}

Frontlines.install = install;
