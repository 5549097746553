<template>
  <div>
    <section class="section">
      <div class="container">
        <breadcrumbs />
        <div class="columns">
          <div class="column is-6 has-text-centered">
            <h1 class="title">
              {{ $t('tags.rechargeTag') }}
            </h1>
          </div>
          <div class="column is-6">
            <div class="columns is-mobile">
              <div class="column is-8 is-offset-2">
                <b-field v-if="tags.some(tag => tag.balance.is_cd)">
                  <b-select
                    v-model="selectedCard"
                    expanded="expanded"
                    icon="credit-card"
                  >
                    <option
                      selected="selected"
                      value="All"
                    >
                      {{ $t('tags.selectTag') }}
                    </option>
                    <template
                      v-for="tag in tags"
                    >
                      <option
                        v-if="tag && tag.balance && tag.props &&
                          !tag.props.terminated && (tag.applicable.to === null ||
                            $moment(tag.applicable.to).isAfter($moment(new Date()))) &&
                          tag.balance.is_cd && tag.balance.type === 'EUR'"
                        :key="tag.id"
                        :value="tag.id"
                      >
                        {{ tag.id }}
                        -
                        {{ tag.holder.title }}
                        {{ tag.holder.firstname }}
                        {{ tag.holder.lastname }}
                        -
                        {{ tag.balance.value.cd }}
                        {{ tag.balance.type }}
                      </option>
                    </template>
                  </b-select>
                </b-field>
                <div
                  v-else
                  class="notification has-text-centered"
                >
                  {{ $t('tags.noCD') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="tags.some(tag => tag.balance.is_cd)"
      class="hero is-primary"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6 has-text-centered">
              <h1 class="title">
                {{ $t('tags.rechargeAmount') }}
              </h1>
            </div>
            <div class="column is-6">
              <div class="columns is-mobile">
                <div class="column is-8 is-offset-2">
                  <b-field
                    :label="$t('tags.selectOrType')"
                    custom-class="has-text-white"
                  >
                    <b-field>
                      <b-autocomplete
                        v-model.number="tagRechargeAmount"
                        :disabled="selectedCard==='All'"
                        icon="euro-sign"
                        :open-on-focus="true"
                        :data="amountList"
                        expanded
                        @keypress.native="onlyNumber"
                        @paste.native.prevent
                      />
                      <p class="control">
                        <span class="button is-static">EUR</span>
                      </p>
                    </b-field>
                  </b-field>
                </div>
              </div>
              <div
                v-if="tagBalance<140"
                class="column is-8 is-offset-2"
              >
                <p v-if="selectedCard !== 'All'">
                  {{ $t('tags.tagId') }}
                  <strong class="coCardNumber">{{ selectedCard }}</strong>
                  <span
                    class="coShow"
                    style="display: inline;"
                  >{{ $t('tags.willBeCredited') }}</span>
                  <strong class="coAmount">{{ tagRechargeAmount }}</strong>
                  <span
                    class="coShow"
                    style="display: inline;"
                  >
                    <small>EUR</small>
                  </span>
                </p>
              </div>
              <div v-else>
                {{ $t('tags.cantRechargeMaxBalance') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="tags.some(tag => tag.balance.is_cd)"
      class="hero is-light"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns has-text-centered-touch">
            <div class="column is-6 has-text-centered">
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('tags.resumeTagId') }}
              </span><br>
              <small class="title is-size-3 is-size-4-touch coCardName">
                {{ selectedCard }}
                -
                {{ tagHolder.title }}
                {{ tagHolder.firstname }}
                {{ tagHolder.lastname }}</small>
              <br>
            </div>
            <div class="column is-6">
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('tags.resumeAmount') }}
              </span>
              <h2 class="has-text-centered is-size-2">
                <span class="coAmount">{{ tagRechargeAmount }}</span>
                <small>
                  EUR</small>
              </h2>
              <hr>
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('tags.resumeNewSold') }}
              </span>
              <h2 class="has-text-centered is-size-2">
                <span class="coCredit">{{ $n(tagRechargeAmount + tagBalance, 'currency') }}</span>
              </h2>
              <small
                v-if="tagBalance+tagRechargeAmount>150"
                class="has-text-danger"
              >{{ $t('tags.cantRechargeOverMaxBalance') }}</small>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="tags.some(tag => tag.balance.is_cd)"
      class="hero is-dark"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-half content">
              <recharge-form
                :montant="tagRechargeAmount"
                :payment-disabled="tagBalance+tagRechargeAmount>150"
                :tag-id="selectedCard"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import rechargeForm from './RechargeForm';

export default {
  name: 'Recharge',
  components: {
    rechargeForm
  },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      selectedCard: this.id || 'All',
      tagRechargeAmount: 20,
      amountList: [10, 20, 30, 50, 100]
    };
  },
  computed: {
    // tagRechargeAmount: {
    //   get () {
    //     return this.localTagRechargeAmount;
    //   },
    //   set (value) {
    //     this.localTagRechargeAmount = Number(value) || 0;
    //   }
    // },
    tagHolder () {
      return (this.tags.find((tag) => tag.id === this.selectedCard) || this.tags[0])
        ? (this.tags.find((tag) => tag.id === this.selectedCard) || this.tags[0]).holder
        : {};
    },
    tagBalance () {
      return (this.tags.find((tag) => tag.id === this.selectedCard) ||
        this.tags[0])
        ? parseFloat((this.tags.find((tag) => tag.id === this.selectedCard) ||
        this.tags[0]).balance.value.cd) || 0
        : 0;
    },
    ...mapGetters('tags', ['tags'])
  },
  watch: {
    selectedCard (val) {
      this.$router.push({
        name: 'Recharger ma Carte',
        params: {
          id: val
        }
      });
    },
    $props: {
      handler (val, oldVal) {
        this.selectedCard = val.id;
      },
      deep: true
    }
  },
  mounted () {
    this.$store.dispatch('tags/getTags');
  },
  methods: {
    onlyNumber ($event) {
      // console.log($event); // keyCodes value
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        // 46 is dot && keyCode !== 44 is coma ','
        $event.preventDefault();
      }
    }
    // reallyOnlyNumber ($event) {
    //   console.log($event);
    //   console.log(typeof $event.srcElement.value !== 'number');
    //   if (typeof $event.srcElement.value !== 'number') {
    //     this.$nextTick(() => {
    //       this.tagRechargeAmount = 20;
    //     });
    //   }
    // }
  }
};
</script>

<style></style>
