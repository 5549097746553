<template>
  <section class="login-form box">
    <form @submit.prevent="submitForm">
      <b-field :label="$t('signup.cellular.newCellular')">
        <b-field position="is-centered">
          <p class="control">
            <span class="button is-static">+33</span>
          </p>
          <b-input
            v-model="cellular"
            v-mask="cellularMask"
            placeholder="X XX XX XX XX"
            expanded
            :disabled="step ? true : false"
          />
        </b-field>
      </b-field>
      <b-field
        grouped
        group-multiline
        position="is-centered"
      >
        <div class="control">
          <button
            type="button"
            class="button is-pulled-left"
            :class="step ? 'is-info' : 'is-success'"
            @click="askCode"
          >
            {{ step ? $t('signup.cellular.resendCode') : $t('signup.cellular.sendCellular') }}
          </button>
        </div>
        <div
          v-if="step"
          class="control"
        >
          <button
            type="button"
            class="button is-danger"
            @click="step = null"
          >
            {{ $t('signup.cellular.changeCellular') }}
          </button>
        </div>
        <div
          v-else
          class="control"
        >
          <button
            type="button"
            class="button is-warning"
            @click="skipCellular"
          >
            {{ $t('signup.cellular.skipCellular') }}
          </button>
        </div>
      </b-field>
      <b-field
        v-if="step"
        :label="$t('signup.cellular.enterCode')+formattedCellular"
      >
        <b-input
          v-model="secretCode"
          placeholder="XXXXXX"
          icon="key fa-xs"
        />
      </b-field>
      <b-field
        v-if="step"
        grouped
        group-multiline
        expanded
        position="is-centered"
      >
        <div class="control">
          <button class="button is-success is-pulled-left">
            {{ $t('signup.cellular.confirmCode') }}
          </button>
        </div>
      </b-field>
    </form>
  </section>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  name: 'CellularReset',
  directives: {
    mask
  },
  data () {
    return {
      clientId: '',
      clientInput: '',
      cellular: '',
      secretCode: '',
      step: false,
      cellularMask: {
        mask: 'P ## ## ## ##',
        tokens: {
          P: {
            pattern: /[67]/
          },
          '#': {
            pattern: /\d/
          }
        }
      }
    };
  },
  computed: {
    formattedCellular () {
      return `+33${this.cellular}`;
    }
  },
  mounted () {},
  methods: {
    submitForm () {
      if (!!this.cellular && !!this.secretCode) this.sendCode();
      else this.askCode();
    },
    askCode () {
      this.$http.post('signup/cellular/reset', {
        cellular: this.formattedCellular
      }).then(() => {
        this.step = 'check';
      });
    },
    sendCode () {
      this.$http.post('signup/cellular/reset', {
        cellular: this.formattedCellular,
        code: this.secretCode
      }).then(() => {
        this.$router.push({
          name: 'signupRouteur'
        });
      }).catch((error) => {
        this.$buefy.notification.open({
          duration: 30000,
          message: this.$t(error.code),
          type: 'is-danger',
          'has-icon': true
        });
      });
    },
    skipCellular () {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        confirmText: this.$t('signup.cellular.confirmSkipCellularButton'),
        message: this.$t('signup.cellular.confirmSkipCellular'),
        onConfirm: () => this.$http.post('signup/cellular/skip').then(() => {
          this.$router.push({
            name: 'signupRouteur'
          });
        }).catch((error) => {
          this.$buefy.snackbar.open({
            indefinite: true,
            message: `${this.$t('signup.cellular.skipCellularError', error)}`,
            type: 'is-warning',
            actionText: this.$t('myAccount.retry'),
            onAction: this.skipCellular()
          });
        })
      });
    }
  }
};
</script>

<style>

</style>
