export const mutationsTypes = {
  INIT_STORE: 'INIT_STORE',
  INIT_STORE_DB: 'INIT_STORE_DB',
  SET_USER_STATS: 'SET_USER_STATS'
};

export default {
  [mutationsTypes.INIT_STORE] (state, { config, version }) {
    // Check if the local store exists
    if (localStorage.getItem('store')) {
      const localStore = JSON.parse(localStorage.getItem('store'));
      const { token } = localStore.frontlines;
      const { rememberMe = false } = localStore.auth;
      // Check the version stored against current. If different, don't
      // load the cached version
      if (localStore.version === version && !config.toolymctoolface.ignoreCache) {
        this.replaceState(Object.assign(state, localStore));
      } else {
        state.version = version;
        this.dispatch('frontlines/setBearer', token);
        this.dispatch('auth/setRememberMe', rememberMe);
      }
      state.config = config;
    }
  }
};
