
/**
 * Update an Error with the specified config, error code, and response.
 *
 * @param {Error} error The error to update.
 * @param {Object} config The config.
 * @param {string} [code] The error code (for example, 'ECONNABORTED').
 * @param {Object} [request] The request.
 * @param {Object} [response] The response.
 * @returns {Error} The error.
 */
module.exports = function enhanceError (error, config, code, request, response) {
  const myError = error;
  myError.config = config;
  if (code) {
    myError.code = code;
  }
  myError.request = request;
  myError.response = response;
  return myError;
};
