<template>
  <div class="app-body">
    <progress
      v-if="isLoading"
      class="progress is-small is-info"
      max="100"
    />
    <layout-header />
    <user-notifications
      type="is-danger"
      :is-active="overdueInvoices.length"
      :invoices="overdueInvoices"
    />
    <user-notifications
      type="is-info"
      :is-active="overdueInvoices.length"
      :invoices="overdueInvoices"
    />
    <main class="secure-content">
      <router-view :key="$route.fullPath" />
    </main>
    <layout-pre-footer />
    <layout-footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import UserNotifications from './UserNotifications';

export default {
  name: 'HomeLayout',
  components: {
    UserNotifications
  },
  computed: {
    // outstandingInvoices () {
    //   return this.overdueInvoices;
    // },
    ...mapGetters('invoices', ['outstandingInvoices']),
    ...mapGetters('invoices', ['overdueInvoices']),
    ...mapGetters('frontlines', ['isLoading'])
  },
  mounted () {
    this.$store.dispatch('invoices/getDueInvoices');
  }
};
</script>

<style lang="sass">
progress.progress.is-small:indeterminate
  border-radius: 0
  margin-bottom: 0
  position: fixed
  z-index: 25
</style>
