fix
<template>
  <section class="section">
    <div class="container">
      <div
        v-if="dueInvoices.length"
        class="columns"
      >
        <div class="column is-6 has-text-centered">
          <h1 class="title">
            {{ $t('invoices.myDueInvoices') }}
          </h1>
        </div>
        <div class="column is-6">
          <div
            v-for="contract in dueInvoices"
            :key="`due${contract.contractId}`"
          >
            <h4
              class="is-title is-size-4"
              v-html="$t('invoices.contractId', [contract.contractId])"
            />
            <b-table
              :data="contract.invoices"
              striped
            >
              <b-table-column
                v-slot="props"
                field="id"
                :label="$t('invoices.invoiceId')"
                centered
              >
                <router-link :to="{name: 'Ma Facture Détaillée', params: {id : props.row.id}}">
                  <b-icon
                    size="is-small"
                    icon="file-invoice-dollar"
                  />
                  {{ props.row.id }}
                  <b-icon
                    v-if="props.row.balance > 0 &&
                      $moment(props.row.ts.term).isBefore($moment(new Date()))"
                    icon="exclamation-circle"
                    type="is-danger"
                  />
                  <b-icon
                    v-else-if="props.row.balance > 0 &&
                      $moment(props.row.ts.term).isAfter($moment(new Date()))"
                    icon="exclamation-triangle"
                    type="is-danger"
                  />
                  <b-icon
                    v-else
                    icon="check-circle"
                    type="is-success"
                  />
                </router-link>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="amount"
                :label="$t('invoices.amount')"
                centered
              >
                {{ $n(props.row.amount, 'currency') }}
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="balance"
                :label="$t('invoices.balance')"
                centered
              >
                <span
                  :class="props.row.balance > 0 &&
                    $moment(props.row.ts.term).isBefore($moment(new Date())) ?
                      'has-text-danger' : ''"
                >
                  {{ props.row.balance ? $n(-props.row.balance, 'currency') :'' }}
                </span>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="term"
                :label="$t('invoices.term')"
                :class="props.row.balance > 0 &&
                  $moment(props.row.ts.term).isBefore($moment(new Date())) ?
                    'has-text-danger' : ''"
              >
                <small>{{ props.row.ts.term | moment('LL') }}</small>
              </b-table-column>
            </b-table>
            <hr>
          </div>
          <div class="content">
            <div class="columns is-mobile">
              <div class="column is-8 is-offset-2">
                <router-link
                  :to="{name: 'Mes Factures'}"
                  class="button is-primary is-fullwidth is-outlined"
                >
                  <span class="icon">
                    <i class="fas fa-list-ul" />
                  </span>
                  <span>{{ $t('invoices.showAll') }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="columns"
      >
        <div class="column is-3 has-text-centered">
          <h1 class="title">
            {{ $t('invoices.myLastInvoices') }}
          </h1>
        </div>
        <div class="column is-9">
          <b-field
            grouped
            group-multiline
          >
            <b-select
              v-model="perPage"
              :disabled="!isPaginated"
            >
              <option value="5">
                5 par page
              </option>
              <option value="10">
                10 par page
              </option>
              <option value="15">
                15 par page
              </option>
              <option value="20">
                20 par page
              </option>
            </b-select>
            <div class="control is-flex">
              <b-switch v-model="isPaginated">
                Pagination
              </b-switch>
            </div>
            <b-field v-if="isMobile">
              <b-input
                v-model="filterText"
                type="text"
                placeholder="N° Contrat"
              />
            </b-field>
          </b-field>
          <b-table
            :data="backupInvoices"
            :paginated="isPaginated"
            :per-page="perPage"
          >
            <b-table-column
              v-slot="props"
              field="contractId"
              :label="$t('contrat')"
              sortable
              searchable
            >
              <router-link
                :to="{
                  name: 'Toutes mes Cartes',
                  params: {
                    contractId: props.row.contractId
                  }
                }"
              >
                <span>
                  <b-icon
                    icon="pen-alt"
                    size="is-small"
                  />
                  <span v-html="$t('tags.contractId', [props.row.contractId])" />
                </span>
              </router-link>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="lastInvoice.id"
              :label="$t('invoices.invoiceId')"
              sortable
              searchable
            >
              <router-link
                :to="{name: 'Ma Facture Détaillée', params: {id : props.row.lastInvoice.id}}"
              >
                <b-icon
                  size="is-small"
                  icon="file-invoice-dollar"
                />
                {{ props.row.lastInvoice.id }}
                <b-icon
                  v-if="props.row.lastInvoice.balance > 0 &&
                    $moment(props.row.lastInvoice.ts.term).isBefore($moment(new Date()))"
                  icon="exclamation-circle"
                  type="is-danger"
                />
                <b-icon
                  v-else-if="props.row.lastInvoice.balance > 0 &&
                    $moment(props.row.lastInvoice.ts.term).isAfter($moment(new Date()))"
                  icon="exclamation-triangle"
                  type="is-danger"
                />
                <b-icon
                  v-else
                  icon="check-circle"
                  type="is-success"
                />
              </router-link>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="amount"
              :label="$t('invoices.amount')"
            >
              {{ $n(props.row.lastInvoice.amount, 'currency') }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="term"
              :label="$t('invoices.term')"
            >
              <small
                :class="props.row.lastInvoice.balance > 0 &&
                  $moment(props.row.lastInvoice.ts.term).isBefore($moment(new Date())) ?
                    'has-text-danger' : ''"
              >
                {{ props.row.lastInvoice.ts.term | moment('LL') }}
              </small>
            </b-table-column>
          </b-table>
          <div class="content">
            <div class="columns is-mobile">
              <div class="column is-6 is-offset-3">
                <router-link
                  :to="{name: 'Mes Factures'}"
                  class="button is-primary is-fullwidth is-outlined"
                >
                  <span class="icon">
                    <i class="fas fa-list-ul" />
                  </span>
                  <span>{{ $t('invoices.showAll') }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'InvoicesWidget',
  data () {
    return {
      filterText: '',
      isPaginated: true,
      perPage: 5
    };
  },
  computed: {
    dueInvoices () {
      const filterText = this.filterText.toLowerCase();
      return this.invoices
        .filter((invoice) => invoice.balance > 0)
        .reduce((dueInvoices, invoice) => {
          const v = invoice.contract.id;
          const el = dueInvoices.find((r) => r && r.contractId === v);
          if (el) {
            el.invoices.push(invoice);
            el.invoices.sort((a, b) => (b.canceled ? -1 : b.id - a.id));
          } else {
            dueInvoices.push({
              contractId: v,
              isOpen: true,
              invoices: [invoice]
            });
          }
          return dueInvoices;
        }, [])
        .sort((a, b) => a.id - b.id).map((item) => {
          const newItem = { ...item }; // Create a copy to avoid mutation
          newItem.id = String(newItem.id); // Convert id to string
          newItem.contractId = String(newItem.contractId);
          if (newItem.invoices.length > 0) {
            const invoice = newItem.invoices[0];
            newItem.lastInvoice = {
              ...invoice,
              id: String(invoice.id).toLowerCase()
            };
          }
          return newItem;
        })
        .filter((item) => item.contractId.toLowerCase().includes(filterText) ||
         item.lastInvoice.id.toLowerCase().includes(filterText));
    },
    backupInvoices () {
      const filterText = this.filterText.toLowerCase();
      console.log(this.filterText.toLowerCase());
      return this.invoices.reduce((dueInvoices, invoice) => {
        const v = invoice.contract.id;
        const el = dueInvoices.find((r) => r && r.contractId === v);
        if (el) {
          el.invoices.push(invoice);
          el.invoices.sort((a, b) => (b.canceled ? -1 : b.id - a.id));
        } else {
          dueInvoices.push({
            contractId: v,
            isOpen: true,
            invoices: [invoice]
          });
        }
        return dueInvoices;
      }, [])
        .sort((a, b) => a.id - b.id).map((item) => {
          const newItem = { ...item }; // Create a copy to avoid mutation
          newItem.id = String(newItem.id); // Convert id to string
          newItem.contractId = String(newItem.contractId);
          if (newItem.invoices.length > 0) {
            const invoice = newItem.invoices[0];
            newItem.lastInvoice = {
              ...invoice,
              id: String(invoice.id).toLowerCase()
            };
          }
          return newItem;
        })
        .filter((item) => item.contractId.toLowerCase().includes(filterText) ||
         item.lastInvoice.id.toLowerCase().includes(filterText));
    },
    isMobile () {
      return window.innerWidth < 768; // Adjust the threshold as needed
    },
    ...mapGetters('invoices', ['invoices'])
  },
  mounted () {
  }
};
</script>

<style>

</style>
