<template>
  <footer class="footer">
    <div
      :class="goUp"
      @click="scrollTop()"
    />
    <div class="scroll-to-top" />

    <p class="container has-text-centered">
      <small>{{ $t('footer.copyright') }}
        |
        <a href="https://www.parkings.mc/conditions-utilisation.html">{{ $t('footer.cgu') }}</a>
      </small>
    </p>
  </footer>
</template>

<script>
export default {
  name: 'Myaccount',
  components: {},
  data () {
    return {
      goUp: 'go-up z-5 hidden'
    };
  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll (event) {
      if (event.view.pageYOffset > 100) {
        this.goUp = 'go-up z-5';
      } else {
        this.goUp = 'go-up z-5 hidden';
      }
    }
  }
};
</script>

<style></style>
