<template>
  <section
    id="change-password-anch"
    class="hero"
  >
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-6 has-text-centered">
            <h2 class="title is-size-3 has-text-centered-touch">
              {{ $t('myAccount.changePassword') }}
            </h2>
          </div>
          <div class="column is-6">
            <div class="title is-size-3 has-text-centered-touch">
&nbsp;
            </div>
            <div class="box">
              <div class="content">
                <div
                  v-if="user && user.notifPreferences === 'cellular'"
                  class="box has-text-centered"
                >
                  <p>{{ $t('myAccount.password.willReceiveCellular') }}</p>
                  <div class="control has-icons-left has-icons-right">
                    <b-input
                      v-if="user && user.cellular"
                      :key="user.cellular"
                      v-mask="'(+##) # ## ## ## ##'"
                      placeholder="X XX XX XX XX"
                      :value="user.cellular"
                      readonly="readonly"
                      icon="mobile-alt"
                    />
                    <b-input
                      v-else
                      placeholder="X XX XX XX XX"
                      readonly="readonly"
                      icon="mobile-alt"
                    />
                  </div>
                  <small>{{ $t('myAccount.password.incorrectCellular') }}</small>
                  <br>
                  <a
                    href="#change-cellular-anch"
                    class="is-small"
                  >
                    <small>{{ $t('myAccount.password.updateCellular') }}</small>
                  </a>
                </div>
                <div
                  v-else
                  class="box has-text-centered"
                >
                  <p>{{ $t('myAccount.password.willReceiveMail') }}</p>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      class="input"
                      :value="user ? user.email : ''"
                      readonly="readonly"
                      type="email"
                    >
                    <span class="icon is-small is-left">
                      <i class="fa fa-envelope" />
                    </span>
                    <span class="icon is-small is-right">
                      <i class="fa fa-check" />
                    </span>
                  </div>
                  <small>{{ $t('myAccount.password.incorrectMail') }}</small>
                  <br>
                  <a
                    href="#change-mail-anch"
                    class="is-small"
                  >
                    <small>{{ $t('myAccount.password.updateMail') }}</small>
                  </a>
                </div>
                <button
                  class="button is-fullwidth is-success"
                  @click="askReset()"
                >
                  <span>{{ $t('myAccount.password.requestChange') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  mask
} from 'vue-the-mask';

export default {
  name: 'AskPasswordReset',
  directives: {
    mask
  },
  data () {
    return {
      step: false
    };
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  methods: {
    askReset () {
      this.$http.post('client/password/reset', {
        clientId: this.user.clientId,
        clientMail: this.user.email
      }).then((response) => {
        if (response.data.success) {
          const media = (this.user && this.user.notifPreferences === 'cellular')
            ? this.$t('myAccount.password.bySMS', [this.user.cellular])
            : this.$t('myAccount.password.byMail', [this.user.email]);
          this.$buefy.notification.open({

            duration: 30000,
            message: this.$t('myAccount.password.successAskChange', [media]),
            type: 'is-success'
          });
        }
      }).catch((error) => {
        this.$buefy.snackbar.open({
          indefinite: true,
          message: `${this.$t('myAccount.password.errorAskChange', error)}`,
          type: 'is-warning',
          actionText: this.$t('adminChanges.retry'),

          onAction: this.sendCode
        });
      });
    },
    mounted () {
      this.$store.dispatch('user/getUser');
    }
  }
};
</script>

<style>

</style>
