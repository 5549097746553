import Vue from 'vue';
import Router from 'vue-router';

// LayoutLite
import LayoutLite from '@/components/layout/LayoutLite';

import Category from '@/components/layout/Category';

// Login Components
import Login from '@/components/login/Login';
import Logout from '@/components/login/Logout';
import AddDevice from '@/components/login/AddDevice';

// Signup Components
import SignupRouteur from '@/components/signup/SignupRouteur';
import SignupWelcome from '@/components/signup/SignupWelcome';
import AccountConfirm from '@/components/signup/Validate';

// Email Signup Components
import EmailSignup from '@/components/signup/email/Email';
import EmailConfirm from '@/components/signup/email/Confirm';
import EmailReset from '@/components/signup/email/Reset';

// Cellular Signup Components
import CellularSignup from '@/components/signup/cellular/Cellular';
import CellularConfirm from '@/components/signup/cellular/Confirm';
import CellularReset from '@/components/signup/cellular/Reset';

// Password Signup components
import PasswordSignup from '@/components/signup/password/Password';
import PasswordReset from '@/components/signup/password/Reset';

// Account components
import HomeLayout from '@/components/layout/HomeLayout';
import Dashboard from '@/components/home/Dashboard';

// Tags components
import TagsList from '@/components/home/pages/cards/All';
import TagsRecharge from '@/components/home/pages/cards/Recharge';
import TagsTransfer from '@/components/home/pages/cards/Transfer';
import TagsActivity from '@/components/home/pages/cards/Activity';

// Invoices Components
import InvoicesList from '@/components/home/pages/invoices/All';
import InvoiceDetail from '@/components/home/pages/invoices/Detail';
// import InvoicesExcesses from '@/components/home/pages/invoices/Excesses';
import ContractsList from '@/components/home/pages/invoices/Contracts';
import InvoicesPay from '@/components/home/pages/invoices/Pay';

// Account Components
import AccountAdmin from '@/components/home/pages/account/Admin';
import AccountAlerts from '@/components/home/pages/account/Alerts';
import AccountTasks from '@/components/home/pages/account/Tasks';
import AccountTransactions from '@/components/home/pages/account/Transactions';
import MyAccount from '@/components/home/pages/account/MyAccount';

import UserPasswordReset from '@/components/home/pages/account/MyAccount/PasswordReset';

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      // , offset: { x: 0, y: 10 }
      };
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [{
    path: '/home',
    component: HomeLayout,
    meta: {
      bodyClass: 'has-navbar-fixed-top',
      isLayout: true
    },
    children: [{
      path: '/',
      name: 'Accueil',
      label: 'menu.home',
      component: Dashboard,
      meta: {
        icon: 'home'
      }
    },
    {
      path: 'cards',
      name: 'Cartes',
      label: 'menu.cards',
      component: Category,
      meta: {
        isLayout: true,
        icon: 'id-card'
      },
      children: [{
        path: 'all/:contractId?/:tagId?',
        props: (route) => ({
          contractId: Number(route.params.contractId) || null,
          tagId: Number(route.params.tagId) || null
        }),
        params: { clientId: null, secretCode: null, clientMail: null },
        name: 'Toutes mes Cartes',
        label: 'menu.allCards',
        component: TagsList
      },
      {
        path: 'activity/:id?',
        name: 'Mon Activité',
        label: 'menu.myActivity',
        component: TagsActivity,
        props: (route) => ({ id: Number(route.params.id) }),
        meta: {
          hasDivider: true
        }
      },
      {
        path: 'recharge/:id?',
        name: 'Recharger ma Carte',
        label: 'menu.rechargeTag',
        component: TagsRecharge,
        props: (route) => ({ id: Number(route.params.id) }),
        params: { id: null }
      },
      {
        path: 'transfer',
        name: 'Transferer mon Solde',
        label: 'menu.transferCard',
        component: TagsTransfer
      }
      ]
    },
    {
      path: 'contracts',
      name: 'Contrats',
      label: 'menu.contracts',
      component: Category,
      meta: {
        isLayout: true,
        icon: 'file-signature'
      },
      children: [
        {
          path: 'contracts',
          name: 'Mes Contrats',
          label: 'menu.myContracts',
          component: ContractsList
        },
        {
          path: 'administratif',
          name: 'Modif. Administratives',
          label: 'menu.modifAdm',
          component: AccountAdmin,
          meta: {
            hasDivider: true
          }
        },
        {
          path: 'invoices/:contractId?',
          name: 'Mes Factures',
          label: 'menu.myInvoices',
          component: InvoicesList,
          props: (route) => ({ contractId: Number(route.params.contractId) }),
          params: { contractId: 0 },
          children: [
            {
              path: 'details/:id',
              name: 'Ma Facture Détaillée',
              component: InvoiceDetail,
              props: (route) => ({ id: Number(route.params.id) }),
              params: { id: 0 },
              meta: { isMenuHidden: true },
              children: [
                {
                  path: 'pay',
                  name: 'Payer Ma Facture',
                  component: InvoicesPay,
                  props: (route) => ({ id: Number(route.params.id) }),
                  params: { id: 0 },
                  meta: { isMenuHidden: true }
                }
              ]
            }
          ]
        }
        // {
        //   path: 'excesses',
        //   name: 'Dépassements',
        //   label: 'menu.excesses',
        //   component: InvoicesExcesses
        // }
      ]
    },
    {
      path: 'account',
      label: 'Compte',
      component: Category,
      meta: {
        isMenuHidden: true,
        isLayout: true
      },
      children: [
        {
          path: '/',
          name: 'Mon Compte',
          label: 'menu.myAccount',
          component: MyAccount
        },
        {
          path: 'alerts',
          name: 'Mes Alerts',
          label: 'menu.myAlerts',
          component: AccountAlerts
        },
        {
          path: 'tasks',
          name: 'Mes Opérations',
          label: 'menu.myTasks',
          component: AccountTasks
        },
        {
          path: 'transactions',
          name: 'Mes Transactions',
          label: 'menu.myTransactions',
          component: AccountTransactions
        },
        {
          path: 'logout',
          label: 'menu.logout',
          name: 'Déconnexion',
          component: Logout,
          meta: {
            icon: 'power-off'
          }
        }
      ]
    }
    ]
  },
  {
    path: '/',
    component: LayoutLite,
    children: [{
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/reset/password/:clientId?/:clientMail?/:secretCode?',
      name: 'resetPassword',
      props: (route) => ({
        clientId: route.params.clientId,
        clientMail: route.params.clientMail,
        secretCode: route.params.secretCode
      }),
      params: { clientId: '', secretCode: '', clientMail: '' },
      component: UserPasswordReset
    },
    {
      path: 'add-device',
      name: 'Add Device',
      component: AddDevice
    },
    {
      path: 'sus-act',
      name: 'Suspicous Activity',
      component: AddDevice
    },
    {
      path: 'signup',
      name: 'signup',
      component: SignupWelcome
    },
    {
      path: 'signupRouteur',
      name: 'signupRouteur',
      component: SignupRouteur,
      children: [{
        path: 'validate',
        name: 'Account Validate',
        component: AccountConfirm
      },
      {
        path: 'email',
        name: 'email',
        component: EmailSignup,
        children: [{
          path: 'confirm',
          name: 'Email Confirm',
          component: EmailConfirm
        },
        {
          path: 'reset',
          name: 'Email Reset',
          component: EmailReset
        }
        ]
      },
      {
        path: 'cellular',
        name: 'cellular',
        component: CellularSignup,
        children: [{
          path: 'confirm',
          name: 'Cellular Confirm',
          component: CellularConfirm
        },
        {
          path: 'reset',
          name: 'Cellular Reset',
          component: CellularReset
        }
        ]
      },
      {
        path: 'password',
        name: 'password',
        component: PasswordSignup,
        children: [{
          path: 'reset',
          name: 'Password Reset',
          component: PasswordReset
        }]
      }
      ]
    }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
  ]
});
