<template>
  <div>
    <form
      ref="asyncForm"
      method="post"
      name="MoneticoFormulaire"
      :action="config.monetico.post_url"
    >
      <input
        type="hidden"
        name="version"
        :value="order.version"
      >
      <input
        type="hidden"
        name="TPE"
        :value="order.TPE"
      >
      <input
        type="hidden"
        name="date"
        :value="order.date"
      >
      <input
        type="hidden"
        name="montant"
        :value="order.montant"
      >
      <input
        type="hidden"
        name="reference"
        :value="order.reference"
      >
      <input
        type="hidden"
        name="MAC"
        :value="order.MAC"
      >
      <input
        type="hidden"
        name="url_retour"
        :value="order.url_retour"
      >
      <input
        type="hidden"
        name="url_retour_ok"
        :value="order.url_retour_ok"
      >
      <input
        type="hidden"
        name="url_retour_err"
        :value="order.url_retour_err"
      >
      <input
        type="hidden"
        name="lgue"
        :value="order.lgue"
      >
      <input
        type="hidden"
        name="societe"
        :value="order.societe"
      >
      <input
        type="hidden"
        name="texte-libre"
        :value="order['texte-libre']"
      >
      <input
        type="hidden"
        name="mail"
        :value="order.mail"
      >
      <input
        v-if="order.aliascb"
        type="hidden"
        name="aliascb"
        :value="order.aliascb"
      >
      <input
        type="hidden"
        name="contexte_commande"
        :value="order.contexte_commande"
      >
    </form>
    <div class="checkbox">
      <div class="field">
        <b-checkbox v-model="cgv">
          {{ $t('tags.accept') }}
          <a
            class="is-underlined"
            href="https://www.parkings.mc/conditions-utilisation.html"
          >{{ $t('tags.CGV') }}</a>
          {{ $t('tags.ofMonacoParking') }}
        </b-checkbox>
      </div>
    </div>
    <p>
      <small>{{ $t('tags.acceptedPayments') }}</small>
    </p>
    <p class="has-text-centered payment-methods">
      <b-icon
        pack="fab"
        icon="cc-visa"
        size="is-large"
        style="margin-right:5px"
      />
      <b-icon
        pack="fab"
        icon="cc-mastercard"
        size="is-large"
      />
    </p>
    <div v-if="creditCards.length>0">
      <b-field :label="$t('tags.savedCreditCards')">
        <b-select
          v-model="selectedCreditCard"
          expanded="expanded"
          icon="credit-card"
        >
          <option value="">
            {{ $t('invoices.otherCreditCard') }}
          </option>
          <option
            v-for="card in creditCards"
            :key="card.id"
            :value="card.id"
          >
            {{ card.brand }} - {{ card.obfuscatedNumber }} - {{ card.validity | moment('MM/YYYY') }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="checkbox">
      <b-field :label="$t('tags.savedCreditCards')">
        <b-checkbox
          v-model="rememberCard"
          :disabled="selectedCreditCard!==''"
        >
          {{ $t('tags.rememberCard') }}
        </b-checkbox>
      </b-field>
    </div>
    <p>
      <button
        v-if="!maintenance.effective"
        class="
        button
        is-medium
        is-fullwidth
        is-success"
        :disabled="paymentDisabled || !tagId || montant === 0 || !cgv"
        @click="sendRechargeOrder()"
      >
        <span class="icon">
          <i class="fa fa-fw fa-credit-card" />
        </span>
        <span>{{ $t('tags.complete') }}</span>
      </button>
    </p>
    <button
      class="button is-fullwidth is-danger"
      @click="$router.push({ name: 'Accueil' });"
    >
      <span class="icon">
        <i class="fa fa-fw fa-times" />
      </span>
      <span>{{ $t('tags.cancel') }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'RechargeForm',
  props: ['montant', 'tagId', 'paymentDisabled'],
  data () {
    return {
      cgv: false,
      reference: '',
      lgue: '',
      texte_libre: '',
      selectedCreditCard: '',
      rememberCard: false,
      order: {
        MAC: '',
        TPE: '',
        date: '',
        lgue: '',
        mail: '',
        montant: '',
        reference: 0,
        societe: '',
        'texte-libre': '',
        version: '',
        options: ''
      }
    };
  },
  computed: {
    mac () {
      return `${this.config.monetico.tpe}*${this.date}*${this.montant}*${this.reference}*${this.texte_libre}*${this.config.monetico.version}*${this.lgue}*${this.config.monetico.societe}*${(this.user ? this.user.email : '')}**********`;
    },
    date () {
      return this.$moment().format('DD/MM/YYYY:HH:mm:ss');
    },
    ...mapGetters(['config']),
    ...mapGetters('user', ['user']),
    ...mapGetters('user', ['creditCards']),
    ...mapState('auth', ['maintenance'])
  },
  mounted () {
    this.$store.dispatch('user/getUser');
    this.$store.dispatch('user/getCreditCards');
  },
  methods: {
    sendRechargeOrder () {
      if (this.cgv) {
        this.$http.post('tag/refill', {
          tag: this.tagId,
          amount: this.montant,
          tokenize: this.rememberCard,
          creditCard: this.selectedCreditCard
        }).then((result) => {
          this.order = result.data.data;
          this.$nextTick(() => {
            this.$refs.asyncForm.submit();
          });
        }).catch((error) => {
          this.$buefy.snackbar.open({
            indefinite: true,
            message: `${this.$t('tags.errorRecharge', error)}`,
            type: 'is-warning',
            actionText: this.$t('tags.retry'),

            onAction: this.sendRechargeOrder
          });
        });
      } else {
        this.$buefy.notification.open({
          duration: 5000,
          message: this.$t('tags.pleaseAcceptCgv'),
          type: 'is-warning'
        });
      }
    }
  }
};
</script>

<style></style>
