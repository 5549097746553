import moment from 'moment';

const mutationsTypes = {
  SET_CONTRACTS_LIST: 'SET_TAGS_LIST'
};

// initial state
const initState = {
  contractList: {
    data: [],
    lastUpdate: null
  }
};

// getters
const getters = {
  contracts: (state) => state.contractList.data
};

// actions
const actions = {
  registerTable: {
    root: true,
    handler ({ dispatch }) {
      dispatch('db/addTable', { contracts: '&id' }, { root: true });
    }
  },
  getContracts ({
    dispatch
  }) {
    dispatch('db/select', { table: 'contracts', callback: `contracts/${mutationsTypes.SET_CONTRACTS_LIST}`, limit: false }, { root: true });
    return this.$http.get('contract/list').then((result) => {
      if (result && result.data && result.data.data) {
        const wkContractList = result.data.data;
        dispatch('db/add', { table: 'contracts', data: wkContractList }, { root: true });
        const prom = [];
        for (let i = 0; i < wkContractList.length; i += 1) {
          if (!wkContractList[i].props.terminated &&
            (wkContractList[i].ts.to === null ||
            moment(wkContractList[i].ts.to).isAfter(moment(new Date())))) {
            prom.push(this.$http.get('contract/rights', {
              params: {
                id: wkContractList[i].id
              }
            }).then((pResult) => {
              const wrkItem = wkContractList[i];
              wrkItem.rights = pResult && pResult.data && pResult.data.data
                ? pResult.data.data
                : null;
              return wrkItem;
            }));
          }
        }
        return Promise.all(prom).then((resulto) => {
          dispatch('db/add', { table: 'contracts', data: wkContractList }, { root: true });
        }).finally(() => dispatch('db/select', { table: 'contracts', callback: `contracts/${mutationsTypes.SET_CONTRACTS_LIST}`, limit: false }, { root: true }));
      }
      return Promise.resolve();
    }).finally(() => dispatch('db/select', { table: 'contracts', callback: `contracts/${mutationsTypes.SET_CONTRACTS_LIST}`, limit: false }, { root: true }));
  }
};

// mutations
const mutations = {
  [mutationsTypes.SET_CONTRACTS_LIST]: (state, contracts) => {
    state.contractList.data = contracts;
    state.contractList.lastUpdate = new Date();
  }
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations
};
