<template>
  <div>
    <maintenance-widget />
    <welcome-widget />
    <tags-widget />
    <movements-widget />
    <invoices-widget />
  </div>
</template>

<script>
import WelcomeWidget from '@/components/home/widgets/Welcome';
import TagsWidget from '@/components/home/widgets/Tags';
import MovementsWidget from '@/components/home/widgets/Movements';
import InvoicesWidget from '@/components/home/widgets/Invoices';
import MaintenanceWidget from '@/components/home/widgets/Maintenance';

import {
  mapGetters
} from 'vuex';

export default {
  name: 'Dashboard',
  components: {
    'welcome-widget': WelcomeWidget,
    'tags-widget': TagsWidget,
    'movements-widget': MovementsWidget,
    'invoices-widget': InvoicesWidget,
    'maintenance-widget': MaintenanceWidget
  },
  computed: {
    ...mapGetters('invoices', ['invoices'])
  },
  mounted () {
    this.$store.dispatch('user/getUser');
    this.$store.dispatch('tags/getTags');
    this.$store.dispatch('movements/getMovements');
    this.$store.dispatch('contracts/getContracts');
    this.$store.dispatch('invoices/getInvoices');
    this.$store.dispatch('auth/maintenance');
  }
};
</script>

<style>

</style>
