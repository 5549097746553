<template>
  <section class="section">
    <div class="container">
      <breadcrumbs />
      <router-view v-if="$route && $route.params && $route.params.id" />
      <div
        v-else
        class="columns"
      >
        <div class="column is-6 has-text-centered">
          <h1 class="title">
            {{ $t('contracts.myContracts') }}
          </h1>
        </div>
        <div class="column is-6">
          <template
            v-for="contract in enrContracts"
          >
            <div
              v-if="!contract.props.terminated"
              :key="contract.id"
              class="card"
            >
              <header class="card-header is-size-3 is-size-4-mobile">
                <p class="card-header-title">
                  # {{ contract.id }}
                </p>
              </header>
              <div class="card-content">
                <div class="columns">
                  <div class="column">
                    <div class="content">
                      <p>
                        <span class="has-text-grey">{{ $t('contracts.product') }} :</span>
                        {{ contract.product.label }}<br>
                        <span class="has-text-grey">{{ $t('contracts.subscribedOn') }}</span>
                        {{ contract.ts.subscription | moment('LL') }}<br>
                        <span
                          v-if="contract.payment.periodicity || contract.periodicity"
                          class="has-text-grey"
                        >{{ $t('contracts.periodicity') }} :</span>
                        <template v-if="contract.payment.periodicity || contract.periodicity">
                          <template v-if="contract.periodicity === '30'">
                            Mensuelle
                          </template>
                          <template v-else-if="contract.periodicity === '90'">
                            Trimestrielle
                          </template>
                          <template v-else-if="contract.payment.periodicity === '30'">
                            Mensuelle
                          </template>
                          <template v-else-if="contract.payment.periodicity === '90'">
                            Trimestrielle
                          </template>
                          <template v-else>
                            {{ contract.payment.periodicity || contract.periodicity }}
                          </template>
                        </template>
                        <br>
                        <span
                          v-if="contract.payment.next || contract.next"
                          class="has-text-grey"
                        >{{ $t('contracts.nextInvoice') }} :</span>
                        <template v-if="contract.payment.next || contract.next">
                          {{ contract.payment.next || contract.next | moment('LL') }}
                        </template>
                      </p><p>
                        <span class="has-text-grey">{{ $t('contracts.paymentType') }} :</span>
                        {{ contract.payment.method.renewal }}
                      </p>
                    </div>
                  </div>
                  <div class="column">
                    <ul
                      v-if="contract.tags"
                      class="content"
                    >
                      <li
                        v-for="tag in contract.tags"
                        :key="tag.id"
                      >
                        <b-icon
                          size="is-small"
                          icon="credit-card"
                        />
                        {{ tag.id }} -
                        {{ tag.holder.firstname | recapitalize }}
                        {{ (tag.holder.lastname || '') | recapitalize }}
                      </li>
                    </ul>
                    <label
                      v-if="openedContracts[contract.id] &&
                        contract.rights && contract.rights.length > 0"
                      class="has-cursor-pointer"
                    >
                      <input
                        :id="'handle'+contract.id"
                        v-model="openedContracts[contract.id].isOpen"
                        type="checkbox"
                        class="is-hidden"
                      >
                      <b-icon
                        size="is-small"
                        :icon="openedContracts[contract.id].isOpen ? 'caret-down' : 'caret-right'"
                      />
                      <span v-html="$t('contracts.myRights')" />
                    </label>
                    <ul
                      v-if="openedContracts[contract.id] && openedContracts[contract.id].isOpen"
                      :key="contract.id"
                      class="has-text-right"
                    >
                      <li
                        v-for="right in contract.rights"
                        :key="right"
                      >
                        <small>
                          {{ right.parc.name }} : {{ right.profile.name }}
                        </small>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  v-if="contract.payment.method &&
                    contract.payment.method.renewal &&
                    contract.payment.method.renewal==='PRE'"
                  class="message"
                >
                  <div class="message-body">
                    {{ contract.iban.title }}
                    {{ contract.iban.firstname }}
                    {{ contract.iban.lastname }}<br>
                    {{ $t('contracts.bic') }} {{ contract.iban.swift }}<br>
                    {{ $t('contracts.iban') }} {{ contract.iban.iban }}
                  </div>
                </div>
              </div>
              <footer class="card-footer">
                <div class="buttons card-footer-item">
                  <router-link
                    :to="{name: 'Mes Factures', params: { contractId: contract.id }}"
                    class="button is-info is-outlined is-fullwidth"
                  >
                    <b-icon
                      icon="file-invoice-dollar"
                      size="is-small"
                    />
                    <span>
                      {{ $t('contracts.seeInvoices') }}
                    </span>
                  </router-link>
                  <!-- <router-link :to="{name: 'Modif. Administratives'}"
                class="button is-success is-outlined is-fullwidth">
                {{$t('contracts.ibanChange')}}
              </router-link> -->
                  <router-link
                    :to="{name: 'Modif. Administratives'}"
                    class="button is-primary is-outlined is-fullwidth"
                  >
                    {{ $t('contracts.adminChange') }}
                  </router-link>
                </div>
              </footer>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'ContractsList',
  data () {
    return {
      openedContracts: []
    };
  },
  computed: {
    enrContracts () {
      return [...this.contracts]
        .map((contract) => ({
          ...contract,
          isOpen: false,
          tags: this.tags
            .filter((tag) => tag.contract.id === contract.id)
        }));
    },
    ...mapGetters('contracts', ['contracts']),
    ...mapGetters('tags', ['tags'])
  },
  mounted () {
    this.$store.dispatch('contracts/getContracts').then(() => {
      this.openedContracts = [...this.contracts].reduce((openedContracts, contract) => ({
        ...openedContracts,
        [contract.id]: {
          isOpen: false
        }
      }), {});
    });
    this.$store.dispatch('tags/getTags');
  }
};
</script>

<style>

</style>
