<template>
  <div
    v-if="type === 'is-danger' && isActive"
    class="userNotification is-sticky is-danger z-2"
    @click="scrollTop"
  >
    <div class="container">
      <span
        v-if="tags.some(tag => tag.props.prohibited)"
        v-html="$t('welcome.prohibitedTags',
                   [prohibitedTags.length,
                    prohibitedTags.slice(0, 5).map(tag => tag.id).join(', ')])"
      />
      <span v-html="$t('welcome.overdueInvoices', [invoices.length])" />
      <br>
      <template v-if="invoices.length > 2">
        <router-link
          :to="{name: 'Mes Factures'}"
          class="is-info"
          v-html="$t('menu.myInvoices')"
        />
      </template>
      <template
        v-for="invoice in invoices"
        v-else
      >
        <router-link
          :key="`overdueInvoices${invoice.id}`"
          :to="{name: 'Payer Ma Facture', params: { id: invoice.id }}"
          class="is-info"
          v-html="$t('welcome.payOverdue', [invoice.id, $moment(invoice.ts.term).format('LL')])"
        />
        <br :key="`overdueInvoicesbr${invoice.id}`">
      </template>
    </div>
  </div>
  <div
    v-else-if="isActive"
    class="userNotification is-sticky is-info z-1"
    @click="scrollTop"
  >
    <div class="container">
      <span v-html="$t('welcome.outstandingInvoices', [invoices.length])" />
      <br>
      <template v-if="invoices.length > 2">
        <router-link
          :to="{name: 'Mes Factures'}"
          class="is-info"
          v-html="$t('menu.myInvoices')"
        />
      </template>
      <template
        v-for="invoice in invoices"
        v-else
      >
        <router-link
          :key="`outstandingInvoices${invoice.id}`"
          :to="{name: 'Payer Ma Facture', params: { id: invoice.id }}"
          class="is-info"
          v-html="$t('welcome.payOverdue', [invoice.id, $moment(invoice.ts.term).format('LL')])"
        />
        <br :key="`outstandingInvoicesbr${invoice.id}`">
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// TODO: implémenter les bons tests

export default {
  name: 'UserNotifications',
  components: {},
  props: ['isActive', 'type', 'invoices'],
  data () {
    return {
      homeLink: this.$router.resolve({
        name: 'Accueil'
      })
    };
  },
  computed: {
    prohibitedTags () {
      return this.tags.filter((tag) => tag.props.prohibited);
    },
    ...mapGetters('tags', ['tags'])
  },
  mounted () {
    this.$store.dispatch('tags/getTags');
  },
  methods: {
    scrollTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
};
</script>

<style>

</style>
