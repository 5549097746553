<template>
  <section class="hero is-primary">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-6 has-text-centered">
            <h1 class="title">
              {{ $t('welcome.welcome') }}
            </h1>
            <h2
              v-if="user"
              class="subtitle"
            >
              {{ user.firstname | recapitalize }} {{ user.lastname | recapitalize }}
            </h2>
          </div>
          <div class="column is-6">
            <div
              v-if="tags.some(tag => tag && tag.balance && tag.props &&
                !tag.props.terminated && (tag.applicable.to === null ||
                  $moment(tag.applicable.to).isAfter($moment(new Date()))) &&
                tag.balance.is_cd && tag.balance.type === 'EUR')"
              class="content"
            >
              <p class="has-text-centered has-text-weight-light">
                <span v-html="$t('welcome.contracts', [contracts.length])" />
                <br>
                {{ $t('welcome.left') }}
                <br>
                <span
                  v-html="$t('welcome.onCredit',
                             [$n(credit.amount, 'currency'), credit.tagCount])"
                />
                <template v-if="time.tagCount > 0">
                  <br>
                  <strong>{{ time.amount | duration("format", "h _") }}
                  </strong>
                  <span v-html="$t('welcome.onWork', [time.tagCount])" />
                </template>
              </p>
              <div
                v-if="!maintenance.effective"
                class="columns is-mobile"
              >
                <div class="column is-10 is-offset-1">
                  <router-link
                    :to="{name: 'Recharger ma Carte'}"
                    class="button is-success is-medium is-fullwidth"
                  >
                    <span class="icon">
                      <i class="fas fa-cart-plus" />
                    </span>
                    <span>{{ $t('welcome.rechargeTag') }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'WelcomeWidget',
  data () {
    return {
    };
  },
  computed: {
    credit () {
      const credit = { amount: 0, tagCount: 0 };
      for (let i = 0, j = this.tags.length; i < j; i += 1) {
        if (this.tags[i] && this.tags[i].balance && this.tags[i].balance.is_cd &&
          this.tags[i].balance.type === 'EUR' && !this.tags[i].props.terminated &&
          (this.tags[i].applicable.to === null ||
            this.$moment(this.tags[i].applicable.to).isAfter(this.$moment(new Date())))) {
          credit.amount += Number(this.tags[i].balance.value.ab || this.tags[i].balance.value.cd);
          credit.tagCount += 1;
        }
      }
      return credit;
    },
    time () {
      const time = { amount: this.$moment.duration(0), tagCount: 0 };
      for (let i = 0, j = this.tags.length; i < j; i += 1) {
        if (this.tags[i] && this.tags[i].balance &&
          this.tags[i].balance.is_cd && this.tags[i].balance.type === 'MIN' &&
          !this.tags[i].props.terminated && (this.tags[i].applicable.to === null ||
            this.$moment(this.tags[i].applicable.to).isAfter(this.$moment(new Date())))) {
          time.amount.add(Number(this.tags[i].balance.value.ab || this.tags[i].balance.value.cd), 'minutes');
          time.tagCount += 1;
        }
      }
      return time;
    },
    ...mapGetters('user', ['user']),
    ...mapGetters('tags', ['tags']),
    ...mapGetters('contracts', ['contracts']),
    ...mapState('auth', ['maintenance']) // Importing maintenance state
  }
};
</script>

<style>

</style>
