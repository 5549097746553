<template>
  <section class="login-form box">
    <b-field :label="$t('signup.email.mail')">
      <b-input
        v-model="email"
        expanded
        placeholder="john.doe@example.com"
        size="is-small"
        icon="envelope fa-xs"
        disabled
      />
    </b-field>
    <b-field
      grouped
      group-multiline
      expanded
      position="is-centered"
    >
      <div class="control">
        <button
          class="button is-pulled-left"
          :class="step ? 'is-info' : 'is-success'"
          @click="askCode"
        >
          {{ step ? $t('signup.email.resendCode') : $t('signup.email.sendMail') }}
        </button>
      </div>
      <div class="control">
        <button
          class="button is-danger"
          @click="$router.push({name: 'Email Reset'})"
        >
          {{ $t('signup.email.changeMail') }}
        </button>
      </div>
    </b-field>
    <b-field
      v-if="step"
      :label="$t('signup.email.enterCode')+email"
    >
      <b-input
        v-model="secretCode"
        placeholder="XXXXXX"
        icon="key fa-xs"
      />
    </b-field>
    <b-field
      v-if="step"
      grouped
      group-multiline
      expanded
      position="is-centered"
    >
      <div class="control">
        <button
          class="button is-success is-pulled-left"
          @click="sendCode"
        >
          {{ $t('signup.email.confirmCode') }}
        </button>
      </div>
    </b-field>
  </section>
</template>

<script>
export default {
  name: 'EmailConfirm',
  data () {
    return {
      clientId: '',
      clientInput: '',
      email: '',
      secretCode: '',
      step: false
    };
  },
  mounted () {
    this.$http.get('signup/info').then((result) => {
      this.email = result.data.data.email;
    });
  },
  methods: {
    askCode () {
      this.$http.post('signup/email/reset', {
        email: this.email
      }).then(() => {
        this.step = 'check';
      });
    },
    sendCode () {
      this.$http.post('signup/email/reset', {
        email: this.email,
        code: this.secretCode
      }).then(() => {
        this.$router.push({
          name: 'signupRouteur'
        });
      }).catch((error) => {
        this.$buefy.notification.open({
          duration: 30000,
          message: this.$t(error.code),
          type: 'is-danger',
          'has-icon': true
        });
      });
    }
  }
};
</script>

<style>

</style>
