export default {
  api: {
    baseURL: 'https://frontlines.parkings.mc/',
    requestLimit: 10,
    refreshEvery: 15 * 60 * 1000
  },
  signageUri: 'https://www.parkings.mc/?page=signage',
  reCaptcha: {
    sitekey: '6Lczn1AUAAAAAI20OeXfnvSh1aGnvbCljTDOveSw'
  },
  monetico: {
    post_url: 'https://p.monetico-services.com/paiement.cgi',
    version: '3.0',
    tpe: '1234567',
    societe: 'monacoP',
    url_retour: 'https://client.parkings.mc/home/cards/recharge',
    url_retour_ok: 'https://client.parkings.mc/home/account/transactions',
    url_retour_err: 'https://client.parkings.mc/home/account/transactions'
  },
  timestamp: {
    refreshFrequency: 30 * 1000,
    timeToBan: 15 * 60 * 1000
  },
  toolymctoolface: {
    ignoreCache: true
  }
};
