import config from '@/config';
import { mutationsTypes } from './mutations';
import { version } from '../../package.json';

export default {
  initialiseStore ({ commit, dispatch }) {
    commit(mutationsTypes.INIT_STORE, { config, version });
    dispatch('registerTable');
    dispatch('db/initIndexedDb');
  }
};
