<template>
  <div>
    <section class="section">
      <div class="container">
        <breadcrumbs />
        <div class="columns">
          <div class="column is-6 has-text-centered">
            <h1 class="title">
              {{ $t('myAccount.myAccount') }}
            </h1>
          </div>
          <div class="column is-6">
            <div class="columns is-mobile">
              <div class="column is-8 is-offset-2">
                <div class="content">
                  <p>
                    {{ $t('myAccount.iWish') }}
                  </p>
                  <ul>
                    <li>
                      <a
                        class="scroll"
                        href="#notifications-preferences"
                      >{{ $t('myAccount.cNotificationPreferences') }}</a>
                    </li>
                    <li>
                      <a
                        class="scroll"
                        href="#change-mail-anch"
                      >{{ $t('myAccount.cChangeMail') }}</a>
                    </li>
                    <li>
                      <a
                        class="scroll"
                        href="#change-cellular-anch"
                      >{{ $t('myAccount.cChangeCellular') }}</a>
                    </li>
                    <li>
                      <a
                        class="scroll"
                        href="#change-password-anch"
                      >{{ $t('myAccount.cChangePassword') }}</a>
                    </li><li>
                      <a
                        class="scroll"
                        href="#list-device-anch"
                      >{{ $t('myAccount.lListDevice') }}</a>
                    </li>
                    <li>
                      <a
                        class="scroll"
                        href="#list-payment-anch"
                      >{{ $t('myAccount.lListPayment') }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="notifications-preferences"
      class="hero is-primary"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6 has-text-centered">
              <h2 class="title is-size-3 has-text-centered-touch">
                {{ $t('myAccount.notificationsPreferences') }}
              </h2>
            </div>
            <div class="column is-6">
              <div class="title is-size-3 has-text-centered-touch">
&nbsp;
              </div>
              <div class="box">
                <b-field :label="$t('myAccount.iWantToReceiveOn')">
                  <b-field position="is-centered">
                    <b-radio-button
                      v-model="notificationSwitch"
                      native-value="email"
                      type="is-primary"
                    >
                      <b-icon icon="envelope" />
                      <span>{{ $t('myAccount.email.notificationMethod') }}</span>
                    </b-radio-button>
                    <b-radio-button
                      v-model="notificationSwitch"
                      native-value="cellular"
                      type="is-primary"
                      :disabled="user && !user.cellular"
                    >
                      <b-icon icon="mobile-alt" />
                      <span>{{ $t('myAccount.cellular.notificationMethod') }}</span>
                    </b-radio-button>
                  </b-field>
                </b-field>
                <b-field
                  grouped="grouped"
                  group-multiline="group-multiline"
                  expanded="expanded"
                  position="is-centered"
                >
                  <div class="control">
                    <button
                      class="button is-success is-pulled-left"
                      @click="sendNotificationsPreferences"
                    >
                      {{ $t('myAccount.confirmChangeNotificationPreference') }}
                    </button>
                  </div>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <email-reset />
    <cellular-reset />
    <ask-password-reset />
    <section
      id="list-device-anch"
      class="hero is-primary"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6 has-text-centered">
              <h2 class="title is-size-3 has-text-centered-touch">
                {{ $t('myAccount.listDevice') }}
              </h2>
            </div>
            <div class="column is-6">
              <div
                v-for="device in devices"
                :key="device"
                class="box"
              >
                <article class="media columns is-vcentered">
                  <div class="media-left">
                    <b-icon
                      v-if="device.device === 'unknown'"
                      icon="desktop"
                      pack="fa"
                      size="is-large"
                    />
                    <b-icon
                      v-else
                      icon="mobile"
                      pack="fa"
                      size="is-large"
                    />
                  </div>
                  <div class="media-content is-clipped">
                    <div class="columns is-vcentered">
                      <div class="column is-3 has-text-centered">
                        <span>
                          <strong class="is-size-5">
                            {{ device.name }}
                          </strong>
                          <br>
                          <span v-if="device.current">
                            {{ $t("myAccount.devices.current") }}
                          </span>
                        </span>
                        <small>
                          <b-icon
                            v-if="['OS X', 'IOS', 'iOS'].includes(device.os)"
                            icon="apple"
                            pack="fab"
                          />
                          <b-icon
                            v-else-if="['windows', 'Windows'].includes(device.os)"
                            icon="windows"
                            pack="fab"
                          />
                          <b-icon
                            v-else-if="device.os === 'Android'"
                            icon="android"
                            pack="fab"
                          />
                          <b-icon
                            v-else
                            icon="linux"
                            pack="fab"
                          />
                          {{ device.os }}
                        </small>
                        <br>
                        <small>
                          <b-icon
                            :icon="device.browser.toLowerCase()"
                            pack="fab"
                          />{{ device.browser }}
                        </small>
                      </div>
                      <div class="column is-5 has-text-centered">
                        {{ $t('myAccount.devices.addedOn') }}
                        {{ device.createdAt | moment("LL") }}
                      </div>
                      <div class="column has-text-centered">
                        <button
                          class="button is-danger"
                          @click="revokeDevice(device)"
                        >
                          <b-icon icon="minus-circle" />
                          <span>{{ $t('myAccount.devices.revoke') }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="list-payment-anch"
      class="hero is-light"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6 has-text-centered">
              <h2 class="title is-size-3 has-text-centered-touch">
                {{ $t('myAccount.listPayment') }}
              </h2>
            </div>
            <div
              v-if="creditCards && creditCards.length > 0"
              class="column is-6"
            >
              <div
                v-for="creditCard in creditCards"
                :key="creditCard"
                class="box"
              >
                <article class="media columns is-vcentered">
                  <div class="media-left">
                    <b-icon
                      icon="cc-visa"
                      pack="fab"
                      size="is-large"
                    />
                  </div>
                  <div class="media-content">
                    <div class="content">
                      <div class="columns is-vcentered is-gapless has-text-centered">
                        <div class="column is-3">
                          <strong>{{ creditCard.obfuscatedNumber }}</strong>
                        </div>
                        <div class="column is-5 is-offset-1">
                          {{ $t('myAccount.payment.validity') }}
                          {{ creditCard.validity }}
                        </div>
                        <div class="column has-text-centered">
                          <button
                            class="button is-danger"
                            @click="revokeCreditCard(creditCard)"
                          >
                            <b-icon icon="minus-circle" />
                            <span>{{ $t('myAccount.payment.revoke') }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div
              v-else
              class="column is-6"
            >
              {{ $t('myAccount.payment.noCreditCard') }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import EmailReset from '@/components/home/pages/account/MyAccount/EmailReset';
import CellularReset from '@/components/home/pages/account/MyAccount/CellularReset';
import AskPasswordReset from '@/components/home/pages/account/MyAccount/AskPasswordReset';

export default {
  name: 'MyAccount',
  components: {
    'email-reset': EmailReset,
    'cellular-reset': CellularReset,
    'ask-password-reset': AskPasswordReset
  },
  data () {
    return {
      selectedCard: '',
      dropFiles: [],
      ibanChangeContracts: [],
      notificationSwitch: ''
    };
  },
  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters('user', ['devices']),
    ...mapGetters('user', ['creditCards']),
    ...mapGetters('contracts', ['contracts'])
  },
  watch: {
    user (newValue, oldValue) {
      this.notificationSwitch = newValue.notifPreferences;
    }
  },
  mounted () {
    this.$store.dispatch('user/getUser');
    this.$store.dispatch('user/getDevices');
    this.$store.dispatch('user/getCreditCards');
    this.$store.dispatch('contracts/getContracts');
  },
  methods: {
    sendNotificationsPreferences () {
      this.$http.post('client/preferences/set', {
        notifPreferences: this.notificationSwitch
      }).then((response) => {
        if (response.data.success) {
          this.$store.dispatch('user/getUser');
          this.$buefy.notification.open({

            duration: 30000,
            message: this.$t('myAccount.successNotifChange', [this.$t(`myAccount.${this.notificationSwitch}.notificationMethod`)]),
            type: 'is-success'
          });
        }
      }).catch((error) => {
        this.$buefy.snackbar.open({
          indefinite: true,
          message: `${this.$t('myAccount.errorNotifChange', error)}`,
          type: 'is-warning',
          actionText: this.$t('myAccount.retry'),

          onAction: this.sendNotificationsPreferences
        });
      });
    },
    deleteDropFile (index) {
      this.dropFiles.splice(index, 1);
    },
    revokeDevice (device) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        hasIcon: true,
        icon: 'exclamation-triangle',
        confirmText: this.$t('myAccount.devices.confirmRevokeButton', [device.name]),
        message: this.$t('myAccount.devices.confirmRevoke', [device.name]),
        onConfirm: () => {
          this.$store.dispatch('user/revokeDevice', device).then((response) => {
            if (response.data.success) {
              this.$store.dispatch('user/getUser');
              this.$buefy.notification.open({

                duration: 30000,
                message: this.$t('myAccount.devices.successRevoke', [device.name]),
                type: 'is-success'
              });
            }
          }).catch((error) => {
            this.$buefy.snackbar.open({
              indefinite: true,
              message: `${this.$t('myAccount.devices.errorRevoke', error)}`,
              type: 'is-warning',
              actionText: this.$t('myAccount.retry'),

              onAction: this.revokeDevice(device)
            });
          });
        }
      });
    },
    revokeCreditCard (creditCard) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        confirmText: this.$t('myAccount.payment.confirmRevokeButton', [creditCard.obfuscatedNumber]),
        message: this.$t('myAccount.payment.confirmRevoke', [creditCard.obfuscatedNumber]),
        onConfirm: () => this.$store.dispatch('user/revokeCreditCard', creditCard).then((response) => {
          if (response.data.success) {
            this.$store.dispatch('user/getUser');
            this.$buefy.notification.open({
              duration: 30000,
              message: this.$t('myAccount.payment.successRevoke', [creditCard.name]),
              type: 'is-success'
            });
          }
        }).catch((error) => {
          this.$buefy.snackbar.open({
            indefinite: true,
            message: `${this.$t('myAccount.payment.errorRevoke', error)}`,
            type: 'is-warning',
            actionText: this.$t('myAccount.retry'),

            onAction: this.revokeCreditCard(creditCard)
          });
        })
      });
    }
  }
};
</script>

<style></style>
