const mutationsTypes = {
  SET_USER: 'SET_USER',
  SET_USER_ADDRESS: 'SET_USER_ADDRESS',
  SET_USER_STATS: 'SET_USER_STATS',
  SET_USER_DEVICES: 'SET_USER_DEVICES',
  SET_USER_CREDITCARDS: 'SET_USER_CREDITCARDS',
  SET_USER_TASKS: 'SET_USER_TASKS',
  SET_USER_TRANSACTIONS: 'SET_USER_TRANSACTIONS'
};

// initial state
const initState = {
  currentUser: {
    data: null,
    devices: [],
    creditCards: [],
    tasks: [],
    transactions: [],
    stats: {
      contracts: 0,
      tags: 0,
      movements: 0
    },
    lastUpdate: null
  }
};

// getters
const getters = {
  user: (state) => state.currentUser.data,
  stats: (state) => state.currentUser.stats,
  devices: (state) => state.currentUser.devices,
  creditCards: (state) => state.currentUser.creditCards,
  tasks: (state) => state.currentUser.tasks,
  transactions: (state) => state.currentUser.transactions
};

// actions
const actions = {
  getUser ({
    commit,
    state
  }) {
    return this.$http.get('client/get').then((result) => {
      if (result && result.data && result.data.data) {
        commit(mutationsTypes.SET_USER, result.data.data);
        if (!state.currentUser.addresses) {
          this.$http.get('client/addresses').then((addresses) => {
            commit(mutationsTypes.SET_USER_ADDRESS, addresses.data.data);
          });
        }
      }
    });
  },
  getTransactions ({
    commit
  }) {
    return this.$http.get('client/transactions').then((result) => {
      if (result && result.data && result.data.data) {
        commit(mutationsTypes.SET_USER_TRANSACTIONS, result.data.data);
      }
    });
  },
  getTasks ({
    commit
  }) {
    return this.$http.get('task/list').then((result) => {
      if (result && result.data && result.data.data) {
        commit(mutationsTypes.SET_USER_TASKS, result.data.data);
      }
    });
  },
  getDevices ({
    commit
  }) {
    return this.$http.get('device/list').then((result) => {
      if (result && result.data && result.data.data) {
        commit(mutationsTypes.SET_USER_DEVICES, result.data.data);
      }
    });
  },
  revokeDevice ({
    commit,
    dispatch
  }, device) {
    return this.$http.post('device/revoke', {
      uid: device.uid
    }).then((response) => {
      commit(mutationsTypes.SET_USER_DEVICES, []);
      dispatch('getDevices');
      return response;
    });
  },
  getCreditCards ({
    commit
  }) {
    return this.$http.get('creditCard/list').then((result) => {
      if (result && result.data && result.data.data) {
        commit(mutationsTypes.SET_USER_CREDITCARDS, result.data.data);
      }
    });
  },
  revokeCreditCard ({
    commit,
    dispatch
  }, creditCard) {
    return this.$http.post('creditCard/revoke', {
      id: creditCard.id
    }).then((response) => {
      commit(mutationsTypes.SET_USER_CREDITCARDS, []);
      dispatch('getCreditCards');
      return response;
    });
  },
  getStats ({
    commit
  }) {
    return this.$http.get('client/stats').then((result) => {
      if (result && result.data && result.data.data) {
        commit(mutationsTypes.SET_USER_STATS, result.data.data);
      }
    });
  }
};

// mutations
const mutations = {
  [mutationsTypes.SET_USER]: (state, user) => {
    state.currentUser.data = user;
    state.currentUser.lastUpdate = new Date();
  },
  [mutationsTypes.SET_USER_TRANSACTIONS]: (state, transactions) => {
    state.currentUser.transactions = transactions;
  },
  [mutationsTypes.SET_USER_TASKS]: (state, tasks) => {
    state.currentUser.tasks = tasks;
  },
  [mutationsTypes.SET_USER_ADDRESS] (state, addresses) {
    state.currentUser.data.addresses = addresses;
  },
  [mutationsTypes.SET_USER_STATS] (state, stats) {
    if (stats) state.currentUser.stats = stats;
  },
  [mutationsTypes.SET_USER_DEVICES] (state, devices) {
    state.currentUser.devices = devices;
  },
  [mutationsTypes.SET_USER_CREDITCARDS] (state, creditCards) {
    state.currentUser.creditCards = creditCards;
  }
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations
};
