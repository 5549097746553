<template>
  <form
    class="login-form box"
    @submit.prevent="submitForm"
  >
    <b-field :label="$t('login.idClient')">
      <b-input
        v-model="id"
        :placeholder="$t('login.idClient')"
        size="is-medium"
        icon="hashtag"
        :disabled="clientId || step"
      />
    </b-field>
    <b-field
      :label="$t('login.mailClient')"
      :class="{'is-hidden' : clientMail}"
    >
      <b-input
        v-model="mail"
        :placeholder="$t('login.mailClient')"
        size="is-medium"
        icon="envelope"
        :disabled="clientMail || step"
      />
    </b-field>
    <b-field
      v-if="loginState.verify && !loginState.banned"
      class="has-text-centered"
    >
      <vue-recaptcha
        class="g-recaptcha"
        size="compact"
        :sitekey="$store.state.config.reCaptcha.sitekey"
        @verify="verifyCaptcha"
      />
    </b-field>
    <b-field
      grouped="grouped"
      group-multiline="group-multiline"
      expanded="expanded"
      position="is-centered"
    >
      <div class="control">
        <button
          v-if="step"
          type="button"
          class="button is-pulled-left is-info"
          @click="askReset"
        >
          {{ $t('addDevice.resendCode') }}
        </button>
        <button
          v-else
          class="button is-pulled-left is-success"
          :disabled="!id || !mail"
        >
          {{ $t('signup.password.askReset') }}
        </button>
      </div>
    </b-field>
    <template v-if="step">
      <b-field :label="$t('signup.password.enterCode')">
        <b-input
          v-model="secret"
          placeholder="XXXXXX"
          icon="key fa-xs"
          :disabled="$route.params.secretCode"
        />
      </b-field>
      <b-field
        :label="$t('signup.password.enterPassword')"
        :type="(message.length === 0 && entropy >= 80) ? '' : 'is-danger'"
      >
        <b-input
          v-model="password"
          custom-class="passwordReset"
          placeholder="XXXXXXXXX"
          icon="key fa-xs"
        />
      </b-field>
      <b-field
        :label="$t('signup.password.entropy')+entropy+'/80'"
        :message="message"
        :type="message.length===0 ? 'is-success' : '' "
      >
        <progress
          :class="'progress is-small '+((entropy<20) ? 'is-danger ' :
            ((entropy<40)? 'is-warning ' : ((entropy<80)? 'is-warning ' : 'is-success ')))"
          :value="entropy"
          max="80"
        />
      </b-field>
      <b-field
        :message="messageV"
        type="is-success"
        style="text-decoration: line-through;"
      />
      <b-field
        :label="$t('signup.password.confirmPassword')"
        :type="(password===passwordCheck) && entropy >= 80 ? '' : 'is-danger'"
      >
        <b-input
          v-model="passwordCheck"
          custom-class="passwordReset"
          placeholder="XXXXXXXXX"
          icon="key fa-xs"
        />
      </b-field>
      <b-field
        grouped="grouped"
        group-multiline="group-multiline"
        expanded="expanded"
        position="is-centered"
      >
        <div class="control">
          <button
            class="button is-success is-pulled-left"
            :disabled="password === '' || password !== passwordCheck ||
              message.length > 0 || messageV.length < 5"
          >
            {{ $t('signup.password.confirmCode') }}
          </button>
        </div>
      </b-field>
    </template>
  </form>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import Entropy from 'string-entropy';
import TestPass from '@/tools/test-password-for';

const LOWERCASE_ALPHA = 'abcdefghijklmnopqrstuvwxyz';
const UPPERCASE_ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const DIGITS = '0123456789';
const PUNCT1 = '!@#$%^&*()';
const PUNCT2 = '~`-_=+[]{}\\|;:\'",.<>?/';

export default {
  name: 'PasswordReset',
  components: {
    VueRecaptcha
  },
  props: [
    'clientId', 'secretCode', 'clientMail'
  ],
  data () {
    return {
      id: this.clientId,
      mail: this.clientMail,
      secret: this.secretCode,
      password: '',
      passwordCheck: '',
      step: !!this.clientId,
      loginState: {
        verify: null,
        banned: null
      },
      captchaResponse: null,
      message: [],
      messageV: []
    };
  },
  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters('auth', ['timestamp']),
    banRemaining () {
      const diff = new Date(this.timestamp.banned - this.timestamp.now);
      return `${diff.getMinutes()}:${diff.getSeconds()}`;
    },
    banned () {
      return this.timestamp.now - this.timestamp.banned <= 0;
    },
    entropy () {
      return Entropy(this.password);
    }
  },
  watch: {
    password (newPass) {
      this.message = [];
      this.messageV = [];
      if (this.password.length < 9) {
        this.message.push(this.$t('signup.password.invalid.length'));
      } else {
        this.messageV.push(this.$t('signup.password.valid.length'));
      }
      if (!TestPass(this.password, LOWERCASE_ALPHA)) {
        this.message.push(this.$t('signup.password.invalid.lowercase'));
      } else {
        this.messageV.push(this.$t('signup.password.valid.lowercase'));
      }
      if (!TestPass(this.password, UPPERCASE_ALPHA)) {
        this.message.push(this.$t('signup.password.invalid.uppercase'));
      } else {
        this.messageV.push(this.$t('signup.password.valid.uppercase'));
      }
      if (!TestPass(this.password, DIGITS)) {
        this.message.push(this.$t('signup.password.invalid.digit'));
      } else {
        this.messageV.push(this.$t('signup.password.valid.digit'));
      }
      if (!TestPass(this.password, PUNCT1 + PUNCT2)) {
        this.message.push(this.$t('signup.password.invalid.special'));
      } else {
        this.messageV.push(this.$t('signup.password.valid.special'));
      }
    }
  },
  mounted () {
    this.createRecaptcha();
    this.$store.dispatch('auth/emptyMessage');
  },
  beforeDestroy () {
    // not tested
    document.getElementById('recaptchaScript').remove();
  },
  methods: {
    verifyCaptcha (response) {
      this.captchaResponse = response;
    },
    createRecaptcha () {
      const script = document.createElement('script');
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.id = 'recaptchaScript';
      script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit';
      document.getElementsByTagName('head')[0].appendChild(script);
    },
    submitForm () {
      if (!!this.secret && !!this.password && !!this.passwordCheck) this.sendPassword();
      else this.askReset();
    },
    askReset () {
      if (this.id && this.mail) {
        this.$http.post('client/password/reset', {
          clientId: this.id,
          clientMail: this.mail
        }).then((response) => {
          this.step = 'check';
        }).catch((error) => {
          if (error) {
            if (error.response && error.response.data) {
              if (error.response.data.verify) this.loginState.verify = error.response.data.verify;
              if (error.response.data.banned) this.loginState.banned = error.response.data.banned;
            }
            if (error.code) {
              this.$buefy.notification.open({
                duration: 30000,
                message: `${this.$t(error.response.data.data.code, [this.banRemaining])}`,
                position: 'is-top',
                type: 'is-warning'
              });
            } else {
              this.$buefy.snackbar.open({
                indefinite: true,
                message: `${this.$t(error.message) ||
            this.$t('signup.password.errorAskReset', error)}`,
                type: 'is-warning',
                actionText: this.$t('signup.password.retry'),

                onAction: this.askReset
              });
            }
          }
        });
      }
    },
    sendPassword () {
      if (this.password === this.passwordCheck) {
        this.$http.post('client/password/reset', {
          clientId: this.id,
          clientMail: this.mail,
          password: this.password,
          code: this.secret
        }).then((response) => {
          if (response.data) {
            if (response.data.verify) this.loginState.verify = response.data.verify;
            if (response.data.banned) this.loginState.banned = response.data.banned;
            if (response.data.success) {
              this.$buefy.notification.open({
                duration: 30000,
                message: `${this.$t('signup.password.passwordReseted')}`,
                position: 'is-top',
                type: 'is-success'
              });
            }
          }
          this.$router.push({
            name: 'Login'
          });
        }).catch((error) => {
          this.$buefy.snackbar.open({
            indefinite: true,
            message: `${this.$t(error.message) || this.$t('signup.password.errorReset', error)}`,
            type: 'is-warning',
            actionText: this.$t('signup.password.retry'),

            onAction: this.sendPassword
          });
        });
      }
    },
    validateMail (mail) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(mail);
    },
    validateCode (code) {
      const re = /^[a-zA-Z0-9]+$/;
      return re.test(code);
    }
  }
};
</script>

<style></style>
