import Dexie from 'dexie';
// import DexieFallBack from '../tools/dexieFallback';
const db = new Dexie('MonacoParkings');
// window.addEventListener('unhandledrejection', (rejection) => { db = new DexieFallBack(); });
// let db = new DexieFallBack();
// console.log(db);
const mutationsTypes = {
  INIT_INDEXEDDB: 'INIT_INDEXEDDB',
  CLEAR_INDEXEDDB: 'CLEAR_INDEXEDDB',
  ADD_TABLE: 'ADD_TABLE',
  EMPTY_TABLE: 'EMPTY_TABLE',
  PUSH_IN_TABLE: 'PUSH_IN_TABLE',
  UPDATE_ROW: 'UPDATE_ROW',
  UPDATE_COUNT: 'UPDATE_COUNT'
};

// initial state
const initState = {
  tables: {},
  counts: {}
};

// getters
const getters = {
  getTable: () => (table) => db[table].toArray(),
  getCounts: (state) => state.counts
};

// actions
const actions = {
  initIndexedDb ({ commit }, dbName) {
    commit(mutationsTypes.INIT_INDEXEDDB, dbName);
  },
  clearIndexedDb ({ commit }) {
    commit(mutationsTypes.CLEAR_INDEXEDDB);
  },
  addTable ({
    commit
    // state,
  }, table) {
    commit(mutationsTypes.ADD_TABLE, table);
  },
  emptyTable ({ commit }, table) {
    commit(mutationsTypes.EMPTY_TABLE, table);
  },
  add ({ commit }, { table, data }) {
    commit(mutationsTypes.PUSH_IN_TABLE, { table, data });
  },
  updateRow ({ commit }, {
    table, key, data
  }) {
    commit(mutationsTypes.UPDATE_ROW, {
      table, key, data
    });
  },
  select ({ commit, rootGetters }, {
    table, callback, limit, where, whereClause, attribute, and, sortBy
  }) {
    // console.log('SELECT', db[table]);

    let l = limit;
    if (l === null) {
      l = rootGetters &&
          rootGetters.config &&
          rootGetters.config.api &&
          rootGetters.config.api.requestLimit
        ? rootGetters.config.api.requestLimit
        : 10;
    }
    let query;
    if (whereClause) {
      query = db[table].where(where)[whereClause.test](whereClause.value);
    } else if (where) {
      query = db[table].where(where);
    } else {
      query = db[table].orderBy(':id');
    }
    if (typeof and === 'function') {
      query = query.and(and);
    }
    query = query.reverse();
    if (limit) query = query.limit(l);
    // if (sortBy) query = query.sortBy(sortBy);
    query = query.sortBy(sortBy || ':id').then((result) => {
      if (result) {
        commit(callback, attribute ? result[attribute] : result, { root: true });
        commit(mutationsTypes.UPDATE_COUNT, {
          table, where, data: result.length, limit: l
        });
      }
      return result;
    });
    return query;
  }
};

// mutations
const mutations = {
  [mutationsTypes.UPDATE_COUNT]: (state, {
    table, where, data, limit
  }) => {
    state.counts[table] = state.counts[table] || {};
    if (where) {
      state.counts[table][where[Object.keys(where)[0]]] = data < limit;
    } else {
      state.counts[table].All = data < limit;
    }
  },
  [mutationsTypes.PUSH_IN_TABLE]: (state, { table, data }) => {
    if (Array.isArray(data)) {
      db[table].bulkPut(data);
    } else if (data) {
      db[table].put(data);
    }
    // console.log('PUSH IN TABLE', db[table]);
  },
  [mutationsTypes.UPDATE_ROW]: (state, {
    table, key, data
  }) => {
    db[table].update(key, data);
  },
  [mutationsTypes.INIT_INDEXEDDB]: (state) => {
    db.close();
    db.version(db.verno + 1).stores(state.tables);
    return db.open();
  },
  [mutationsTypes.ADD_TABLE]: (state, table) => {
    Object.assign(state.tables, table);
  },
  [mutationsTypes.EMPTY_TABLE]: (state, table) => {
    db[table].clear();
  },
  [mutationsTypes.CLEAR_INDEXEDDB]: (state) => db.delete().then(() => db.open())
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations
};
