import Vue from 'vue';
/**
 * Converts a string to recapitalize.
 * Examples: string => String
 *           STRING => String
 *
 * @param  {String} str the string to convert
 * @return {String}
 */
Vue.filter('recapitalize', (value) => {
  if (!value) return '';
  const val = value.toString();
  return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
});
