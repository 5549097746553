<template>
  <div>
    <form
      ref="asyncForm"
      method="post"
      name="MoneticoFormulaire"
      :action="config.monetico.post_url"
    >
      <input
        type="hidden"
        name="version"
        :value="order.version"
      >
      <input
        type="hidden"
        name="TPE"
        :value="order.TPE"
      >
      <input
        type="hidden"
        name="date"
        :value="order.date"
      >
      <input
        type="hidden"
        name="montant"
        :value="order.montant"
      >
      <input
        type="hidden"
        name="reference"
        :value="order.reference"
      >
      <input
        type="hidden"
        name="MAC"
        :value="order.MAC"
      >
      <input
        type="hidden"
        name="url_retour"
        :value="order.url_retour"
      >
      <input
        type="hidden"
        name="url_retour_ok"
        :value="order.url_retour_ok"
      >
      <input
        type="hidden"
        name="url_retour_err"
        :value="order.url_retour_err"
      >
      <input
        type="hidden"
        name="lgue"
        :value="order.lgue"
      >
      <input
        type="hidden"
        name="societe"
        :value="order.societe"
      >
      <input
        type="hidden"
        name="texte-libre"
        :value="order['texte-libre']"
      >
      <input
        type="hidden"
        name="mail"
        :value="order.mail"
      >
      <input
        v-if="order.aliascb"
        type="hidden"
        name="aliascb"
        :value="order.aliascb"
      >
      <input
        type="hidden"
        name="contexte_commande"
        :value="order.contexte_commande"
      >
    </form>
    <div class="checkbox">
      <div class="field">
        <b-checkbox v-model="cgv">
          {{ $t('invoices.accept') }}
          <a
            class="is-underlined"
            href="https://www.parkings.mc/conditions-utilisation.html"
          >{{ $t('invoices.CGV') }}</a>
          {{ $t('invoices.ofMonacoParking') }}
        </b-checkbox>
      </div>
    </div>
    <p>
      <small>{{ $t('invoices.acceptedPayments') }}</small>
    </p>
    <p class="has-text-centered payment-methods">
      <b-icon
        pack="fab"
        icon="cc-visa"
        size="is-large"
        style="margin-right:5px"
      />
      <b-icon
        pack="fab"
        icon="cc-mastercard"
        size="is-large"
      />
    </p>
    <div v-if="creditCards.length>0">
      <b-field :label="$t('invoices.savedCreditCards')">
        <b-select
          v-model="selectedCreditCard"
          expanded="expanded"
          icon="credit-card"
        >
          <option value="">
            {{ $t('invoices.otherCreditCard') }}
          </option>
          <option
            v-for="card in creditCards"
            :key="card.id"
            :value="card.id"
          >
            {{ card.brand }} - {{ card.obfuscatedNumber }} - {{ card.validity | moment('MM/YYYY') }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="checkbox">
      <div class="field">
        <b-checkbox
          v-model="rememberCard"
          :disabled="selectedCreditCard !== ''"
        >
          {{ $t('invoices.rememberCard') }}
        </b-checkbox>
      </div>
    </div>
    <p>
      <button
        class="button is-medium is-fullwidth is-success"
        :disabled="!invoiceId || montant === 0 || !cgv"
        @click="sendPaymentOrder()"
      >
        <span class="icon">
          <i class="fa fa-fw fa-credit-card" />
        </span>
        <span>{{ $t('invoices.complete') }}</span>
      </button>
    </p>
    <button
      class="button is-fullwidth is-danger"
      @click="$router.push({ name: 'Accueil' });"
    >
      <span class="icon">
        <i class="fa fa-fw fa-times" />
      </span>
      <span>{{ $t('invoices.cancel') }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PayForm',
  props: ['montant', 'invoiceId'],
  data () {
    return {
      cgv: false,
      reference: '',
      lgue: '',
      texte_libre: '',
      rememberCard: false,
      selectedCreditCard: '',
      order: {
        MAC: '',
        TPE: '',
        date: '',
        lgue: '',
        mail: '',
        montant: '',
        reference: 0,
        societe: '',
        'texte-libre': '',
        version: '',
        options: ''
      }
    };
  },

  computed: {
    mac () {
      return `${this.config.monetico.tpe}*${this.date}*${this.montant}*${this.reference}*${this.texte_libre}*${this.config.monetico.version}*${this.lgue}*${this.config.monetico.societe}*${(this.user ? this.user.email : '')}**********`;
    },
    date () {
      return this.$moment().format('DD/MM/YYYY:HH:mm:ss');
    },
    ...mapGetters(['config']),
    ...mapGetters('user', ['creditCards']),
    ...mapGetters('user', ['user'])
  },
  mounted () {
    this.$store.dispatch('user/getUser');
    this.$store.dispatch('user/getCreditCards');
  },
  methods: {
    sendPaymentOrder () {
      if (this.cgv) {
        Promise.all([this.$http.get('invoice/overdue'), this.$http.get('invoice/outstanding')]).then((result) => {
          const overdue = result && result[0] && result[0].data && result[0].data.data &&
            result[0].data.data.find((invoice) => invoice.id === this.invoiceId);
          const overdueBalance = overdue && overdue.balance;
          const outstanding = result && result[1] && result[1].data && result[1].data.data &&
            result[1].data.data.find((invoice) => invoice.id === this.invoiceId);
          const outstandingBalance = outstanding && outstanding.balance;
          const balance = overdueBalance || outstandingBalance;
          if (balance) {
            this.$http.post('invoice/clear', {
              invoice: this.invoiceId,
              amount: balance,
              tokenize: this.rememberCard,
              creditCard: this.selectedCreditCard
            }).then((resulto) => {
              this.order = resulto.data.data;
              this.$nextTick(() => {
                this.$refs.asyncForm.submit();
              });
            }).catch((error) => {
              this.$buefy.snackbar.open({
                indefinite: true,
                message: `${this.$t('invoices.errorPayment', error)}`,
                type: 'is-warning',
                actionText: this.$t('invoices.retry'),
                onAction: this.sendPaymentOrder
              });
            });
          } else {
            this.$toast.open({
              duration: 5000,
              message: this.$t('invoices.alreadyCleared'),
              type: 'is-warning'
            });
            setTimeout(() => this.$router.push(
              { name: 'Accueil' }
            ), 5000);
          }
        });
      } else {
        this.$toast.open({
          duration: 5000,
          message: this.$t('invoices.pleaseAcceptCgv'),
          type: 'is-warning'
        });
      }
    }
  }
};
</script>

<style></style>
