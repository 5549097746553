<template>
  <section class="section">
    <div class="container">
      <breadcrumbs />
      <div class="columns">
        <div class="column is-6 has-text-centered">
          <h1 class="title">
            {{ $t('tags.myCards') }}
          </h1>
          <div class="buttons is-centered">
            <button
              class="button is-link"
              @click="expandAll"
            >
              <b-icon icon="angle-double-down" /> <span>{{ $t('tags.expandAll') }}</span>
            </button>
            <button
              class="button is-link"
              @click="hideAll"
            >
              <b-icon icon="angle-double-right" /> <span>{{ $t('tags.hideAll') }}</span>
            </button>
          </div>
        </div>
        <div class="column is-6">
          <div class="columns is-mobile">
            <div
              class="notification is-warning is-light"
              v-html="$t('tags.updateOnlyOut')"
            />
          </div>
          <div
            v-for="contract in tagsByContracts"
            :key="contract.contractId"
          >
            <label
              v-if="contract.contractId !== 'prohibited'"
              class="is-title is-size-4 has-cursor-pointer"
            >
              <input
                :id="'handle'+contract.contractId"
                v-model="contract.isOpen"
                type="checkbox"
                class="is-hidden"
              >
              <b-icon
                size="is-small"
                :icon="contract.isOpen ? 'caret-down' : 'caret-right'"
              />
              <span
                v-html="$t('tags.contractIdCards',
                           [contract.contractId, contract.tags.length])"
              />
            </label>
            <transition-group
              name="slide-fade"
              tag="div"
            >
              <template
                v-for="tag in contract.tags"
              >
                <div
                  v-if="contract.isOpen"
                  :key="tag.id"
                  class="card tagCard"
                  :class="tag.props.prohibited ? 'card-prohibited' : (tag.canceled ?
                    'card-canceled' : '')"
                  style="margin-bottom: 3rem; margin-left: 1.25rem;"
                >
                  <header class="card-header is-size-3 is-size-4-mobile">
                    <p class="card-header-title">
                      <span class="has-text-weight-light">#</span>&nbsp;{{ tag.id }}
                      <small class="has-text-grey">.{{ tag.key }}</small>
                      <span
                        v-if="!tag.props.prohibited && !tag.canceled"
                        class="tag"
                        style="margin-left: 1rem"
                        :class="tag.presence && tag.presence.state ? 'is-success' : 'is-danger'"
                      >
                        {{ tag.presence && tag.presence.state ? 'IN' : 'OUT' }}
                      </span>
                    </p>
                    <span
                      v-if="tag.props.prohibited"
                      class="card-header-icon has-text-danger"
                    >
                      <span>
                        <b-icon
                          size="is-small is-size-6"
                          icon="exclamation-triangle"
                        /> {{ $t("tags.prohibited") }}</span>
                    </span>
                    <span
                      v-else-if="tag.canceled"
                      class="card-header-icon has-text-warning"
                    >
                      {{ $t("tags.canceled") }}
                    </span>
                    <span
                      v-else-if="tagBalance(tag)"
                    >
                      {{ tagBalance(tag) }}
                      <small>{{ tag.balance.type === 'EUR' ? 'EUR' : 'H' }}</small>
                    </span>
                  </header>
                  <div class="card-content">
                    <div class="columns">
                      <div class="column is-6">
                        <div class="content">
                          <p>
                            <span class="has-text-weight-light">
                              <small>{{ $t('tags.holder') }}</small>
                            </span>
                            <br>
                            {{ tag.holder.firstname | recapitalize }}
                            {{ (tag.holder.lastname || '') | recapitalize }}
                            <br>
                            <span class="has-text-weight-light">
                              <small>{{ $t('tags.product') }}</small>
                            </span><br> {{ tag.product.label }}
                            <br>
                            <span class="has-text-weight-light">
                              <small>{{ $t('tags.validUntil') }}</small>
                              <br>
                              <strong v-if="tag.applicable.to">
                                {{ tag.applicable.to | moment("calendar") }}
                              </strong>
                              <strong v-else>∞</strong>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div
                          v-if="!tag.props.prohibited && !tag.canceled"
                          class="content has-text-right-desktop"
                        >
                          <p>
                            <span class="has-text-weight-light">
                              <small>{{ $t('tags.vehicles') }}</small>
                            </span>
                            <b-tooltip
                              v-if="tag.vehicles.length < 3"
                              :label="$t('tags.addPlate')"
                              type="is-success"
                            >
                              <button
                                class="button is-success is-small"
                                @click="uploadGreyCard({tagId: tag.id, vehicle: -1})"
                              >
                                <b-icon
                                  icon="plus"
                                  size="is-small"
                                />
                              </button>
                              <!-- <button class="button is-success is-small is-hidden-desktop">
                            <span class="is-hidden-desktop">{{$t('tags.addPlate')}}</span>
                            <b-icon icon='plus' size="is-small"></b-icon>
                          </button> -->
                            </b-tooltip>
                            <ul
                              v-if="tag.vehicles.find(vehicle => vehicle &&
                                (vehicle.brand || vehicle.plate || vehicle.color))"
                            >
                              <template
                                v-for="(vehicle, index) in tag.vehicles"
                              >
                                <li
                                  v-if="vehicle &&
                                    (vehicle.brand || vehicle.plate || vehicle.color)"
                                  :key="vehicle.brand+vehicle.plate+vehicle.color"
                                >
                                  <small>
                                    <b-icon
                                      icon="car"
                                      size="is-small"
                                    />
                                    {{ vehicle.brand }} {{ vehicle.plate }} {{ vehicle.color }}
                                  </small>
                                  <b-tooltip :label="$t('tags.changePlate')">
                                    <button
                                      class="button is-primary is-small"
                                      @click="uploadGreyCard({tagId: tag.id, vehicle: index})"
                                    >
                                      <b-icon
                                        icon="edit"
                                        size="is-small"
                                      />
                                    </button>
                                  </b-tooltip>
                                </li>
                              </template>
                            </ul>
                            <br>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer class="card-footer">
                    <p class="card-footer-item is-block has-text-left">
                      <b-button
                        type="is-grey"
                        icon-left="qrcode"
                        title="qrcode"
                        outlined
                        @click="toggleQrcode(tag.uuid)"
                      />
                    </p>
                    <div class="card-footer-item is-block has-text-right">
                      <div class="buttons">
                        <router-link
                          v-if="tag && !maintenance.effective && tag.balance && tag.props &&
                            !tag.props.terminated && (tag.applicable.to === null ||
                              $moment(tag.applicable.to).isAfter($moment(new Date()))) &&
                            tag.balance.is_cd && tag.balance.type === 'EUR'"
                          :to="{name: 'Recharger ma Carte', params: {id: tag.id}}"
                          class="button is-success is-outlined"
                        >
                          <b-icon icon="cart-plus" />
                          <span class="has-text-weight-light">{{ $t('tags.rechargeTag') }}</span>
                        </router-link>
                        <b-tooltip
                          :label="$t('tags.myAlerts')"
                          type="is-warning"
                        >
                          <router-link
                            v-if="tag && tag.balance && tag.props &&
                              !tag.props.terminated && (tag.applicable.to === null ||
                                $moment(tag.applicable.to).isAfter($moment(new Date()))) &&
                              tag.balance.is_cd"
                            :to="{name: 'Mes Alerts', hash:`#tag${tag.id}`}"
                            class="button is-warning is-outlined"
                          >
                            <b-icon icon="bell" />
                          </router-link>
                        </b-tooltip>
                        <b-tooltip
                          :label="$t('tags.myActivity')"
                          type="is-link"
                        >
                          <router-link
                            :to="{name: 'Mon Activité', params: {id: tag.id}}"
                            class="button is-link is-outlined"
                          >
                            <b-icon icon="chart-bar" />
                          </router-link>
                        </b-tooltip>
                      </div>
                    </div>
                  </footer>
                </div>
              </template>
            </transition-group>
          </div>
          <hr>
          <b-modal
            ref="myModal"
            :active.sync="isModalActive"
            has-modal-card
          >
            <div
              class="modal-card"
              style="width: auto"
            >
              <header class="modal-card-head">
                <p
                  v-if="selectedVehicle && selectedVehicle.vehicle >=0"
                  class="modal-card-title"
                  v-html="$t('tags.replaceVehicle',
                             [selectedVehicle.tagId, selectedVehicle.vehicle])"
                />
                <p
                  v-else
                  class="modal-card-title"
                  v-html="$t('tags.addVehicle')"
                />
              </header>
              <section class="modal-card-body">
                <div class="content">
                  <b-field :label="$t('adminChanges.joinVehiclePapers')">
                    <b-field
                      class="file"
                      style="justify-content: center;"
                    >
                      <b-upload
                        v-model="dropFile"
                        accept="image/*"
                      >
                        <a class="button is-primary">
                          <b-icon icon="upload" />
                          <span>{{ $t('adminChanges.uploadVehicleFile') }}</span>
                        </a>
                      </b-upload>
                      <span
                        v-if="dropFile"
                        class="file-name"
                      >
                        {{ dropFile.name }}
                      </span>
                    </b-field>
                  </b-field>
                </div>
              </section>
              <footer class="modal-card-foot">
                <button
                  class="button is-danger"
                  type="button"
                  @click="$refs.myModal.close()"
                >
                  {{ $t('tags.closeModal') }}
                </button>
                <b-button
                  v-if="selectedVehicle.vehicle === -1"
                  :disabled="!dropFile || isUploading"
                  :loading="isUploading"
                  class="button is-success"
                  @click="sendFiles"
                >
                  {{ $t('tags.sendVehicle') }}
                </b-button>
                <b-button
                  v-else
                  :disabled="!dropFile || isUploading"
                  :loading="isUploading"
                  class="button is-success"
                  @click="sendFiles"
                >
                  {{ $t('tags.changeVehicle') }}
                </b-button>
              </footer>
            </div>
          </b-modal>
          <b-modal
            ref="myQrcodeModal"
            :active.sync="isQrcodeModalActive"
            has-modal-card
          >
            <div
              class="modal-card"
              style="width: auto"
            >
              <section class="modal-card-body has-text-centered">
                <qrcode-vue
                  size="300"
                  :value="qrcode"
                />
              </section>
              <footer class="modal-card-foot">
                <button
                  class="button is-danger"
                  type="button"
                  @click="$refs.myQrcodeModal.close()"
                >
                  {{ $t('tags.closeModal') }}
                </button>
              </footer>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mapGetters, mapState
} from 'vuex';

import QrcodeVue from 'qrcode.vue';

export default {
  name: 'TagsList',
  components: {
    QrcodeVue
  },
  props: ['contractId', 'tagId'],
  data () {
    return {
      qrcode: '',
      isQrcodeModalActive: false,
      isUploading: false,
      tagsByContracts: [],
      isModalActive: false,
      selectedVehicle: {
        tagId: null,
        vehicle: -1
      },
      dropFile: null
    };
  },
  computed: {
    ...mapGetters('tags', ['tags']),
    ...mapState('auth', ['maintenance'])
  },
  mounted () {
    this.$store.dispatch('tags/getTags').then(() => {
      this.tagsByContracts = this.tags.reduce((previousContract, currentTag) => {
        const workingTag = currentTag;
        const v = currentTag.contract.id;
        const el = previousContract.find((r) => r && r.contractId === v);
        if (workingTag.props.terminated ||
          (workingTag.applicable.to !== null &&
            this.$moment(workingTag.applicable.to).isBefore(this.$moment(new Date())))) {
          workingTag.canceled = true;
        } else {
          workingTag.canceled = false;
        }
        if ((!this.tagId && !this.contractId) ||
          (this.tagId && workingTag.id === this.tagId) ||
          (!this.tagId && this.contractId && v === this.contractId)) {
          if (el) {
            el.tags.push(workingTag);
            el.tags.sort((a, b) => (((a.canceled && b.props.prohibited) ||
              (b.canceled && !a.canceled))
              ? -1
              : a.id - b.id));
          } else {
            previousContract.push({
              contractId: v,
              isOpen: true,
              tags: [workingTag]
            });
          }
          if (currentTag.props.prohibited) {
            const prohib = previousContract.find((r) => r && r.contractId === 'prohibited');
            if (prohib) {
              prohib.tags.push(workingTag);
              prohib.tags.sort((a, b) => ((a.canceled && b.prohibited) ||
                b.canceled || b.prohibited
                ? -1
                : a.id - b.id));
            } else {
              previousContract.push({
                contractId: 'prohibited',
                isOpen: true,
                tags: [workingTag]
              });
            }
          }
        }
        return previousContract;
      }, []).sort((a, b) => {
        if (a.contractId === 'prohibited') {
          return -1;
        }
        if (b.contractId === 'prohibited') {
          return +1;
        }
        return a.contractId - b.contractId;
      });

      // if (this.tagId) {
      //   this.tagsByContracts = this.tagsByContracts
      //     .reduce((filteredContract, contract) => {
      //       console.log([...filteredContract,
      //         { ...contract, tags: [contract.tags.find(tag => tag.id === this.tagId)] }]);
      //       return [...filteredContract,
      //         { ...contract, tags: [contract.tags.find(tag => tag.id === this.tagId)] }];
      //     },
      //     []);
      //   console.log(this.tagsByContracts);
      // } else if (this.contractId) {
      //   this.tagsByContracts = this.tagsByContracts
      //     .filter(contract => contract.contractId === this.contractId);
      // }
    });
  },
  methods: {
    toggleQrcode (uuid) {
      this.qrcode = uuid;
      this.isQrcodeModalActive = true;
    },
    sendFiles () {
      if (this.selectedVehicle && this.dropFile) {
        this.$buefy.dialog.confirm({
          type: 'is-warning',
          confirmText: this.selectedVehicle.vehicle === -1
            ? this.$t('tags.confirmVehicleAddButton', [this.selectedVehicle.tagId, this.selectedVehicle.vehicle])
            : this.$t('tags.confirmVehicleChangeButton', [this.selectedVehicle.tagId, this.selectedVehicle.vehicle]),
          message: this.selectedVehicle.vehicle === -1
            ? this.$t('tags.confirmVehicleAdd')
            : this.$t('tags.confirmVehicleChange', [this.selectedVehicle.tagId, this.selectedVehicle.vehicle]),
          onConfirm: () => {
            this.isUploading = true;
            const formData = new FormData();
            formData.append('tag', this.selectedVehicle.tagId);
            formData.append('vehicle', this.selectedVehicle.vehicle + 1);
            formData.append('documents[0]', this.dropFile);
            this.$http.post('contract/vehicle', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) => {
              this.isUploading = false;
              if (response.data.success) {
                this.dropFile = null;
                this.$refs.myModal.close();
                this.$buefy.notification.open({
                  duration: 30000,
                  message: this.$t('adminChanges.successChangeVehicle', [this.selectedVehicle.tagId, this.selectedVehicle.vehicle]),
                  type: 'is-success',
                  'has-icon': true
                });
              }
            }).catch((error) => {
              this.isUploading = false;
              this.dropFile = null;
              this.$buefy.snackbar.open({
                indefinite: true,
                message: `${this.$t('adminChanges.errorChangeVehicle', error)}`,
                type: 'is-warning',
                actionText: this.$t('adminChanges.retry'),

                onAction: this.sendFiles
              });
            });
          }
        });
      }
    },
    uploadGreyCard ({
      tagId,
      vehicle
    }) {
      this.isModalActive = true;
      this.selectedVehicle = {
        tagId,
        vehicle
      };
    },
    hideAll () {
      this.tagsByContracts = this.tagsByContracts.map((contract) => (contract.contractId === 'prohibited'
        ? contract
        : {
            ...contract,
            isOpen: false
          }));
    },
    expandAll () {
      this.tagsByContracts = this.tagsByContracts.map((contract) => (contract.contractId === 'prohibited'
        ? contract
        : {
            ...contract,
            isOpen: true
          }));
    },
    tagBalance (tag) {
      if (tag.balance.is_cd) {
        if (tag.balance.type === 'EUR') {
          return tag.balance.value.cd;
        }
        return Math.round(tag.balance.value.cd / 60);
      }
      if (tag.balance.value.ab) {
        return Math.round(tag.balance.value.ab / 60);
      }
      return false;
    }
  }
};
</script>
