import moment from 'moment';

const mutationsTypes = {
  SET_TAGS_LIST: 'SET_TAGS_LIST',
  SET_ALERTS_LIST: 'SET_ALERTS_LIST'
};

// initial state
const initState = {
  tagList: {
    data: [],
    lastUpdate: null
  },
  alertList: {
    data: [],
    lastUpdate: null
  }
};

// getters
const getters = {
  tags: (state) => state.tagList.data,
  alerts: (state) => state.alertList.data
};

// actions
const actions = {
  registerTable: {
    root: true,
    handler ({ dispatch }) {
      dispatch('db/addTable', { tags: '&id' }, { root: true });
      dispatch('db/addTable', { alerts: '&tag' }, { root: true });
    }
  },
  getTags ({
    dispatch
  }) {
    dispatch('db/select', { table: 'tags', callback: `tags/${mutationsTypes.SET_TAGS_LIST}`, limit: false }, { root: true });
    return this.$http.get('tag/list').then((result) => {
      if (result && result.data && result.data.data) {
        const wkTagList = result.data.data;
        // dispatch('db/add', { table: 'tags', data: wkTagList }, { root: true });
        const prom = [];
        for (let i = 0; i < wkTagList.length; i += 1) {
          if (!wkTagList[i].props.terminated &&
            (wkTagList[i].applicable.to === null ||
            moment(wkTagList[i].applicable.to).isAfter(moment(new Date())))) {
            prom.push(this.$http.get('tag/presence', {
              params: {
                id: wkTagList[i].id
              }
            }).then((pResult) => {
              // const wrkItem = wkTagList[i];
              wkTagList[i].presence = pResult && pResult.data && pResult.data.data
                ? pResult.data.data.presence
                : null;
              wkTagList[i].client = pResult && pResult.data && pResult.data.data
                ? pResult.data.data.client
                : null;
              return wkTagList[i];
            }));
          }
        }
        Promise.all(prom).then((resulto) => {
          dispatch('db/add', { table: 'tags', data: wkTagList }, { root: true });
        }).finally(() => dispatch('db/select', { table: 'tags', callback: `tags/${mutationsTypes.SET_TAGS_LIST}`, limit: false }, { root: true }));
      }
    });
    // TODO: Essayer de trigger la mise a jour de l'array element par element (+ rapide ?)
    // ((+ Les tags n'ont pas toujours besoin des presences: liste des cartes pour les mouvements))
    // https://gist.github.com/DawidMyslak/2b046cca5959427e8fb5c1da45ef7748 ??
    // this.$http.get('tag/list').then((result) => {
    //   const wkTagList = result.data.data;
    //   commit(mutationsTypes.SET_TAGS_LIST, wkTagList);
    //   for (let i = 0; i < wkTagList.length; i += 1) {
    //     // wkTagList[i].presence = null;
    //     this.$http.get('tag/presence', {
    //       params: {
    //         id: wkTagList[i].id,
    //       },
    //     }).then((pResult) => {
    //       // wkTagList[i].presence = pResult;
    //       commit(mutationsTypes.SET_TAG_PRESENCE, pResult.data.data);
    //     });
    //   }
    // });
  },
  getAlerts ({
    dispatch
  }) {
    dispatch('db/select', { table: 'alerts', callback: `tags/${mutationsTypes.SET_ALERTS_LIST}`, limit: false }, { root: true });
    return this.$http.get('tag/alert/list').then((result) => {
      if (result && result.data && result.data.data) {
        dispatch('db/emptyTable', 'alerts', { root: true });
        dispatch('db/add', { table: 'alerts', data: result.data.data }, { root: true });
      }
      return result;
    }).finally(() => {
      dispatch('db/select', { table: 'alerts', callback: `tags/${mutationsTypes.SET_ALERTS_LIST}`, limit: false }, { root: true });
    });
  }
};

// mutations
const mutations = {
  [mutationsTypes.SET_TAGS_LIST]: (state, tags) => {
    state.tagList.data = tags;
    state.tagList.lastUpdate = new Date();
  },
  [mutationsTypes.SET_ALERTS_LIST]: (state, tags) => {
    state.alertList.data = tags;
    state.alertList.lastUpdate = new Date();
  }
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations
};
