var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-primary"},[_c('div',{staticClass:"hero-body px-1"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3 has-text-centered"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('movements.myActivity'))+" ")])]),_c('div',{staticClass:"column is-9"},[_c('b-field',{attrs:{"grouped":"","group-multiline":""}},[_c('b-select',{attrs:{"disabled":!_vm.isPaginated},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},[_c('option',{attrs:{"value":"5"}},[_vm._v(" 5 par page ")]),_c('option',{attrs:{"value":"10"}},[_vm._v(" 10 par page ")]),_c('option',{attrs:{"value":"15"}},[_vm._v(" 15 par page ")]),_c('option',{attrs:{"value":"20"}},[_vm._v(" 20 par page ")])]),_c('div',{staticClass:"control is-flex"},[_c('b-switch',{model:{value:(_vm.isPaginated),callback:function ($$v) {_vm.isPaginated=$$v},expression:"isPaginated"}},[_vm._v(" Pagination ")]),(_vm.isMobile)?_c('b-field',[_c('b-input',{attrs:{"type":"text","placeholder":"N° Carte/Contrat "},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1):_vm._e()],1)],1),_c('b-table',{attrs:{"data":_vm.ordTags,"paginated":_vm.isPaginated,"per-page":_vm.perPage}},[_c('b-table-column',{attrs:{"field":"id","label":_vm.$t('carte'),"sortable":"","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
                  name: 'Toutes mes Cartes',
                  params: {
                    contractId: props.row.contract.id,
                    tagId: props.row.id
                  }
                }}},[_c('span',[_c('b-icon',{attrs:{"icon":"id-card","size":"is-small"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('tags.tagD', [props.row.id]))}})],1)])]}}])}),_c('b-table-column',{attrs:{"field":"contract.id","label":_vm.$t('contrat'),"sortable":"","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
                  name: 'Toutes mes Cartes',
                  params: {
                    contractId: props.row.contract.id
                  }
                }}},[_c('span',[_c('b-icon',{attrs:{"icon":"pen-alt","size":"is-small"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('tags.contractId', [props.row.contract.id]))}})],1)])]}}])}),_c('b-table-column',{attrs:{"field":"presence","label":_vm.$t('welcome.presence'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.presence)?_c('p',[_c('span',{staticClass:"tag",class:props.row.presence && props.row.presence.state ?
                    'is-success' : 'is-danger'},[_vm._v(" "+_vm._s(props.row.presence ? props.row.presence.state ? 'IN' : 'OUT' : '')+" ")]),_c('br'),(props.row.presence)?_c('small',{staticClass:"is-hidden-touch",attrs:{"v":""}},[_vm._v(" "+_vm._s(props.row.presence && props.row.presence.state ? props.row.presence.lastPassage.parc.name : _vm.$t('tags.missing'))+" ")]):_vm._e()]):((props.row.props.terminated ||
                  (props.row.applicable.to !== null &&
                    _vm.$moment(props.row.applicable.to).isBefore(_vm.$moment(new Date())))))?_c('p',[_c('span',{staticClass:"has-text-warning"},[_vm._v(" "+_vm._s(_vm.$t('tags.canceled'))+" ")])]):(props.row.props.prohibited)?_c('p',[_c('span',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(_vm.$t('tags.prohibited'))+" ")])]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"field":"parc","label":_vm.$t('welcome.parc'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.presence &&
                  props.row.presence.lastPassage &&
                  props.row.presence.lastPassage.parc &&
                  props.row.presence.lastPassage.parc.name)?_c('span',[(props.row.presence.state)?_c('b-icon',{staticClass:"has-text-success",attrs:{"icon":"sign-in-alt"}}):_vm._e(),_vm._v(" "+_vm._s(props.row.presence.lastPassage.parc.name)+" "),(!props.row.presence.state)?_c('b-icon',{staticClass:"has-text-danger",attrs:{"icon":"sign-out-alt"}}):_vm._e(),_c('br'),_c('small',[_vm._v(_vm._s(_vm._f("moment")(props.row.presence.lastPassage.ts,"LLL")))])],1):_vm._e()]}}])})],1),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-6 is-offset-3"},[_c('router-link',{staticClass:"button is-primary is-fullwidth is-outlined is-inverted",attrs:{"to":{name: 'Mon Activité'}}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-list-ul"})]),_c('span',[_vm._v(_vm._s(_vm.$t('movements.seeMore')))])])],1)])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }