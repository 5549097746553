<template>
  <div class="column is-two-thirds-mobile is-3-desktop has-text-justified">
    <section class="content">
      <h3 class="title is-3">
        {{ $t('signup.welcomeTitle') }}
      </h3>
      <p>{{ $t('signup.welcome') }}</p>
      <ol>
        <li>{{ $t('signup.mailMandatory') }}</li>
        <li>{{ $t('signup.cellularOptional') }}</li>
        <li>{{ $t('signup.passwordMandatory') }}</li>
        <li>{{ $t('signup.notificationMaybe') }}</li>
      </ol>
      <p>{{ $t('signup.pressReadyToStart') }}</p>
      <b-button
        icon-left="play"
        type="is-success"
        @click="startSignup"
      >
        {{ $t('signup.ready') }}
      </b-button>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SignupWelcome',
  data () {
    return {};
  },
  mounted () {},
  methods: {
    startSignup () {
      this.$router.push({
        name: 'signupRouteur'
      });
    }
  }
};
</script>

<style>

</style>
