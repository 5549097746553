
const mutationsTypes = {
  SET_LOGIN_STATE: 'SET_LOGIN_STATE',
  UPDATE_NOW_TIMESTAMP: 'UPDATE_NOW_TIMESTAMP',
  CLEAR_INTERVAL: 'CLEAR_INTERVAL',
  SET_INTERVAL: 'SET_INTERVAL',
  EMPTY_MESSAGE: 'EMPTY_MESSAGE',
  IS_LOGGING_IN: 'IS_LOGGING_IN',
  SET_REMEMBER_ME: 'SET_REMEMBER_ME',
  SET_LOGGIN_ERROR: 'SET_LOGGIN_ERROR',
  PUSH_MESSAGE: 'PUSH_MESSAGE',
  SET_MAINTENANCE: 'SET_MAINTENANCE'
};

// initial state
const initState = {
  timestamp: {
    now: null,
    banned: null,
    interval: null
  },
  loginState: {
    verify: false,
    banned: false,
    code: false,
    error: null
  },
  isLogginIn: false,
  rememberMe: false,
  message: [],
  maintenance: {
    effective: false,
    client_redirect: null,
    prevent: false,
    lines: []
  }
};

// getters
const getters = {
  timestamp: (state) => state.timestamp,
  loginState: (state) => state.loginState,
  isLogginIn: (state) => state.isLogginIn,
  rememberMe: (state) => state.rememberMe,
  maintenance: (state) => state.maintenance,
  message: (state) => state.message
};

// actions
const actions = {
  emptyMessage ({ commit }) {
    commit(mutationsTypes.EMPTY_MESSAGE);
  },
  pushMessage ({ commit }, message) {
    commit(mutationsTypes.PUSH_MESSAGE, message);
  },
  startTimer ({
    commit
  }) {
    commit(mutationsTypes.UPDATE_NOW_TIMESTAMP, new Date().getTime());
    commit(mutationsTypes.UPDATE_NOW_TIMESTAMP, setInterval(() => {
      commit(mutationsTypes.UPDATE_NOW_TIMESTAMP, new Date().getTime());
    }, 1000));
  },
  login ({
    commit
  }, {
    clientId, password, rememberMe, captchaResponse
  }) {
    commit(mutationsTypes.CLEAR_INTERVAL);
    commit(mutationsTypes.EMPTY_MESSAGE);
    commit(mutationsTypes.IS_LOGGING_IN, true);
    commit(mutationsTypes.SET_LOGGIN_ERROR, null);
    return this.$http.post('auth', {
      clientId,
      password,
      remember: rememberMe,
      captcha: captchaResponse
    }).then((response) => {
      commit(mutationsTypes.IS_LOGGING_IN, false);
      commit(mutationsTypes.CLEAR_INTERVAL);
      if (response.data.status === 'success') {
        commit(mutationsTypes.SET_LOGIN_STATE, {
          verify: false,
          banned: false,
          code: false,
          error: null
        });
      } else if (response.data.data) {
        commit(mutationsTypes.SET_LOGIN_STATE, response.data.data);
        if (response.data.data.code) {
          commit(mutationsTypes.PUSH_MESSAGE, response.data.data.code);
        }
      }
      return response;
    }).catch((e) => {
      if (e && e.response && e.response.data && e.response.data.data) {
        commit(mutationsTypes.SET_LOGIN_STATE, e.response.data.data);
        if (e.response.data.data.code) {
          commit(mutationsTypes.PUSH_MESSAGE, e.response.data.data.code);
        } else if (e.message) {
          commit(mutationsTypes.PUSH_MESSAGE, e.message);
        }
      }
    });
  },
  setRememberMe ({ commit }, rememberMe) {
    commit(mutationsTypes.SET_REMEMBER_ME, rememberMe);
  },
  async maintenance ({ commit }) {
    this.$httpSignage.get().then((result) => {
      commit('SET_MAINTENANCE', result.data);
    });
  },
  updateMaintenance ({ commit }, newMaintenance) {
    commit(mutationsTypes.SET_MAINTENANCE, newMaintenance);
  }

};

// mutations
const mutations = {
  [mutationsTypes.EMPTY_MESSAGE]: (state) => {
    state.message = [];
  },
  [mutationsTypes.PUSH_MESSAGE]: (state, message) => {
    state.message.push(message);
  },
  [mutationsTypes.CLEAR_INTERVAL]: (state) => {
    clearInterval(state.timestamp.interval);
  },
  [mutationsTypes.SET_INTERVAL]: (state, interval) => {
    state.timestamp.interval = interval;
  },
  [mutationsTypes.UPDATE_NOW_TIMESTAMP]: (state, timestamp) => {
    state.timestamp.now = timestamp;
  },
  [mutationsTypes.SET_LOGIN_STATE] (state, loginState) {
    state.loginState = loginState;
    if (state.loginState.banned) {
      state.timestamp.banned = new Date().getTime() + 15 * 60 * 1000;
    }
  },
  [mutationsTypes.IS_LOGGING_IN] (state, boolean) {
    state.isLogginIn = boolean;
  },
  [mutationsTypes.SET_REMEMBER_ME] (state, rememberMe) {
    state.rememberMe = rememberMe;
  },
  [mutationsTypes.SET_LOGGIN_ERROR] (state, error) {
    state.isLogginIn = error;
  },
  [mutationsTypes.SET_MAINTENANCE] (state, maintenance) {
    state.maintenance = maintenance;
  }
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations
};
