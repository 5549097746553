<template>
  <section class="login-form box">
    <form @submit.prevent="submitForm">
      <b-field
        :label="$t('signup.email.newMail')"
        :type="visitedMail ? errors.length ? 'is-danger' : 'is-success' : ''"
        :message="errors"
      >
        <b-input
          v-model="email"
          expanded
          placeholder="john.doe@example.com"
          size="is-small"
          icon="envelope fa-xs"
          :disabled="step ? true : false"
          @blur="visitedMail = true"
        />
      </b-field>
      <b-field
        grouped
        group-multiline
        expanded
        position="is-centered"
      >
        <div class="control">
          <button
            type="button"
            class="button is-pulled-left"
            :disabled="errors.length > 0"
            :class="step ? 'is-info' : 'is-success'"
            @click="askCode"
          >
            {{ step ? $t('signup.email.resendCode') : $t('signup.email.sendMail') }}
          </button>
        </div>
        <div
          v-if="step"
          class="control"
        >
          <button
            type="button"
            class="button is-danger"
            @click="step = null"
          >
            {{ $t('signup.email.changeMail') }}
          </button>
        </div>
      </b-field>
      <b-field
        v-if="step"
        :label="$t('signup.email.enterCode') + email"
        :type="!validateCode(secretCode) ? 'is-danger' : 'is-success'"
        :message="!validateCode(secretCode) ? $t('signup.email.invalidCode') : ''"
      >
        <b-input
          v-model="secretCode"
          placeholder="XXXXXX"
          icon="key fa-xs"
        />
      </b-field>
      <b-field
        v-if="step"
        grouped
        group-multiline
        expanded
        position="is-centered"
      >
        <div class="control">
          <button class="button is-success is-pulled-left">
            {{ $t('signup.email.confirmCode') }}
          </button>
        </div>
      </b-field>
    </form>
  </section>
</template>

<script>
export default {
  name: 'EmailReset',
  data () {
    return {
      clientId: '',
      clientInput: '',
      email: '',
      visitedMail: false,
      secretCode: '',
      step: null
    };
  },
  computed: {
    errors () {
      const errors = [];
      if (!this.email) errors.push(this.$t('signup.email.requiredEmail'));
      else if (!this.validateMail(this.email)) errors.push(this.$t('signup.email.invalidEmail'));
      return errors;
    }
  },
  mounted () {},
  methods: {
    submitForm () {
      if (!!this.email && !!this.secretCode) this.sendCode();
      else this.askCode();
    },
    askCode () {
      if (this.validateMail(this.email)) {
        this.$http.post('signup/email/reset', {
          email: this.email
        }).then(() => {
          this.step = 'check';
        });
      }
    },
    sendCode () {
      this.$http.post('signup/email/reset', {
        email: this.email,
        code: this.secretCode
      }).then(() => {
        this.$router.push({
          name: 'signupRouteur'
        });
      }).catch((error) => {
        this.$buefy.notification.open({
          duration: 30000,
          message: this.$t(error.code),
          type: 'is-danger',
          'has-icon': true
        });
      });
    },
    validateMail (mail) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(mail);
    },
    validateCode (code) {
      const re = /^[a-zA-Z0-9]+$/;
      return re.test(code);
    }
  }
};
</script>

<style>

</style>
