<template>
  <section class="login-form box">
    <p>{{ $t('signup.account.finish') }}</p>
    <b-field :label="$t('signup.account.mail')">
      <b-input
        v-model="email"
        icon="envelope fa-xs"
        disabled
      />
    </b-field>
    <b-field
      v-if="cellular"
      :label="$t('signup.account.cellular')"
    >
      <b-input
        v-model="cellular"
        icon="mobile fa-xs"
        disabled
      />
    </b-field>
    <b-field
      v-if="cellular"
      :label="$t('signup.account.notificationPreference')"
    >
      <b-field
        grouped
        group-multiline
        expanded
        position="is-centered"
      >
        <b-radio-button
          v-model="notifPreferences"
          native-value="email"
          type="is-info"
          class="is-inverted"
        >
          <b-icon
            :custom-class="notifPreferences === 'email' ? '' : 'has-text-dark'"
            icon="check"
          />
          <span :class="notifPreferences === 'email' ? '' : 'has-text-dark'">
            {{ $t('signup.account.mail') }}
          </span>
        </b-radio-button>
        <b-radio-button
          v-model="notifPreferences"
          native-value="cellular"
          type="is-info"
        >
          <b-icon
            :custom-class="notifPreferences === 'cellular' ? '' : 'has-text-dark'"
            icon="check"
          />
          <span :class="notifPreferences === 'cellular' ? '' : 'has-text-dark'">
            {{ $t('signup.account.cellular') }}
          </span>
        </b-radio-button>
      </b-field>
    </b-field>
    <b-field
      grouped
      group-multiline
      expanded
      position="is-centered"
    >
      <button
        class="button is-success is-pulled-left"
        @click="finishSignup"
      >
        {{ $t('signup.account.confirm') }}
      </button>
    </b-field>
  </section>
</template>

<script>
export default {
  name: 'AccountConfirm',
  data () {
    return {
      clientId: '',
      clientInput: '',
      email: '',
      cellular: '',
      notifPreferences: 'email'
    };
  },
  mounted () {
    this.$http.get('signup/info').then((result) => {
      this.email = result.data.data.email;
      this.cellular = result.data.data.cellular;
    });
  },
  methods: {
    finishSignup () {
      this.$http.post('signup/finish', {
        notifPreferences: this.notifPreferences
      }).then((response) => {
        if (response.data.success) {
          this.$buefy.notification.open({
            duration: 30000,
            message: `${this.$t('signup.password.passwordReseted')}`,
            position: 'is-top',
            type: 'is-success'
          });
        }
        this.$router.push({
          name: 'Login'
        });
      });
    }
  }
};
</script>

<style>

</style>
