<template>
  <router-view
    v-if="$route &&
      $route.name=='Payer Ma Facture' &&
      $route.params && $route.params.id"
  />
  <section
    v-else
    class="section"
  >
    <div class="container">
      <breadcrumbs />
      <div class="columns">
        <div class="column is-6 has-text-centered">
          <h1 class="title">
            {{ $t('invoices.myInvoice') }}
            {{ invoice ? invoice.id : '' }}
          </h1>
        </div>
        <div
          v-if="invoice"
          class="column is-6"
        >
          <div
            v-if="invoice.ts"
            class="box content"
          >
            <p v-if="invoice.details">
              <span class="has-text-grey">{{ $t('contracts.contractNumber') }}</span>
              {{ invoice.contract.id }}
            </p>
            <span class="has-text-grey">{{ $t('invoices.emittedDate') }}</span>
            {{ invoice.ts.emitted | moment("LL") }}
            <br>
            <span class="has-text-grey">{{ $t('invoices.termDate') }}</span>
            {{ invoice.ts.term | moment("LL") }}
          </div>
          <b-table
            ref="table"
            :data="invoice.details"
            striped
            :show-detail-icon="false"
            detailed
            custom-detail-row
          >
            <b-table-column
              v-slot="props"
              field="icon"
              :label="''"
              style="cursor: pointer;"
            >
              <b-icon
                v-if="props.row.excesses && props.row.excesses.length"
                size="is-small"
                :icon="props.row === selectedDetail ? 'minus' : 'plus'"
                @click.native="toggleRow(props.row)"
              />
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="label"
              :label="$t('invoices.details')"
              class="is-uppercase"
            >
              {{ props.row.label }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="amount"
              :label="$t('invoices.amount')"
              class="has-text-right"
            >
              {{ $n(props.row.amount_ati, 'currency') }}
            </b-table-column>

            <template
              slot="detail"
              slot-scope="props"
            >
              <tr />
              <tr
                v-for="(excess, index) in props.row.excesses"
                :key="props.row.id + ' ' +index"
              >
                <td class="has-text-centered">
                  #{{ index + 1 }}
                </td>
                <td class="has-text-centered">
                  <span><b-icon
                    icon="sign-in-alt"
                    type="is-success"
                    size="is-small"
                  /> {{ excess.ts.from | moment("lll") }}</span>
                  <br>
                  <span><b-icon
                    icon="sign-out-alt"
                    type="is-danger"
                    size="is-small"
                  /> {{ excess.ts.to | moment("lll") }}</span>
                  <br>
                  <span><b-icon
                    icon="clock"
                    size="is-small"
                  />{{ excess.ts.duration | duration("format","_HM_") }}</span>
                </td>
                <td class="has-text-centered">
                  {{ $n(excess.amount, 'currency') }}
                </td>
              </tr>
            </template>
            <template #footer>
              <div class="has-text-right">
                {{ $n(getReducedAmount, 'currency') }}
              </div>
            </template>
          </b-table>
          <br>
          <div
            v-if="invoice && invoice.payment && invoice.payment.method==='PRE'"
            class="box content"
          >
            <p>
              <span class="has-text-grey">{{ $t('invoices.withdrawalFrom') }}</span>
              {{ invoice.ts.paid | moment('LL') }}
              <span class="has-text-grey">{{ $t('invoices.withdrawalOn') }}</span>
            </p>
            <small v-if="invoice.payment.iban">
              {{ invoice.payment.iban.owner.title }}
              {{ invoice.payment.iban.owner.firstname }}
              {{ invoice.payment.iban.owner.lastname }}
              <br>
              {{ $t("invoices.bic") }}
              {{ invoice.payment.iban.bic }}
              <br>
              {{ $t("invoices.iban") }}
              {{ invoice.payment.iban.number }}
            </small>
          </div>
          <div class="buttons">
            <a
              class="button is-fullwidth is-medium is-primary"
              href="#"
              @click="downloadInvoice()"
            >
              <span class="icon">
                <b-icon
                  icon="cloud-download-alt"
                  size="is-small"
                />
              </span>
              <span>{{ $t('invoices.download') }}</span>
            </a>
            <router-link
              v-if="invoice.balance > 0"
              class="button is-fullwidth is-medium is-success"
              :to="{ name: 'Payer Ma Facture'}"
            >
              <span class="icon">
                <b-icon
                  icon="credit-card"
                  size="is-small"
                />
              </span>
              <span>{{ $t('invoices.settle') }} {{ $n(invoice.balance, 'currency') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'Invoice',
  props: {
    id: Number
  },
  data () {
    return {
      selectedDetail: null
    };
  },
  computed: {
    ...mapGetters(['config']),
    ...mapGetters('invoices', ['invoice']),
    totalDetail () {
      return this.invoice && this.invoice.details
        ? this.invoice.details
          .reduce((total, currentRow) => total + parseFloat(currentRow.amount_ati || 0), 0)
        : 0;
    },
    getReducedAmount () {
      return this.invoice.amount - (this.invoice.reduc_ttc || 0);
    }
  },
  mounted () {
    this.$store.dispatch('invoices/getInvoiceDetails', this.id);
  },
  methods: {
    toggleRow (row) {
      this.selectedDetail = this.selectedDetail === row ? null : row;
      this.$refs.table.toggleDetails(row);
    },
    downloadInvoice () {
      window.open(`${this.config.api.baseURL}invoice/download?id=${this.invoice.id}`);
    }
  }
};
</script>

<style></style>
