<template>
  <section
    v-if="maintenance.prevent"
    class="hero is-warning"
    style="margin-top: 50px"
  >
    <div class="hero-body px-1">
      <div class="container">
        <div class="columns">
          <div class="column is-3 has-text-centered">
            <i class="fas fa-5x fa-exclamation-triangle" />
          </div>
          <div class="column is-9">
            <ul
              class="is-size-5"
              style="text-align: left;"
            >
              <li
                v-for="(line, index) in maintenance.lines"
                :key="index"
                v-html="line"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MaintenanceWidget',
  computed: {
    ...mapState('auth', ['maintenance'])
  },
  methods: {
    updateFacturation () {
      const newFacturationData = {
        effective: false,
        client_redirect: '',
        prevent: false,
        lines: []
      };
      this.$store.dispatch('auth/maintenance', newFacturationData);
    },
    created () {
      this.updateFacturation();
    }
  }
};
</script>

<style>

</style>
