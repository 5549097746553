import Vue from 'vue';
import VueI18n from 'vue-i18n';
//
Vue.use(VueI18n);

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`,
 *  which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const numberFormats = {
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'code',
      minimumIntegerDigits: 1
    }
  }
};

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'fr', // set locale
  messages: loadLocaleMessages(), // set locale messages
  numberFormats
});

export default i18n;
