<template>
  <router-view />
</template>

<script>
export default {
  name: 'SignupRouteur',
  data () {
    return {
      toReRoute: [
        'signupRouteur'
      ]
    };
  },
  // ReRoute for router navigation
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     vm.selectRoute(vm);
  //   });
  // },
  // ReRoute for manually entered URL
  mounted () {
    this.selectRoute(this);
  },
  methods: {
    getStep (client) {
      if (client.hasResetPassword) {
        return 'Account Validate';
      }
      if (client.hasResetCellular) {
        return 'Password Reset';
      }
      if (client.hasResetEmail) {
        if (client.cellular) {
          return 'Cellular Confirm';
        }
        return 'Cellular Reset';
      }
      if (client.email) {
        return 'Email Confirm';
      }
      return 'Email Reset';
    },
    selectRoute (vm) {
      if (vm.toReRoute.indexOf(vm.$route.name) !== -1) {
        vm.$http.get('signup/info').then((response) => {
          if (response.data.status === 'success') {
            vm.$router.replace({
              name: vm.getStep(response.data.data)
            });
          }
        }).catch((error) => {
          vm.$notification.open({
            message: error,
            type: 'is-danger'
          });
        });
      }
    }
  }
};
</script>

<style>

</style>
