<template>
  <header>
    <nav
      class="navbar is-primary is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="container">
        <label
          id="drawer-handle-label"
          for="drawer-handle"
        >
          <div
            id="toggle-menu"
            class="navbar-burger is-pulled-left"
          >
            <span />
            <span />
            <span />
          </div>
        </label>
        <div class="navbar-brand">
          <a
            class="navbar-item"
            href="https://www.parkings.mc"
          >
            <picture>
              <source
                srcset="@/assets/mp-logo.png"
                media="(min-width: 800px)"
              >
              <source
                srcset="@/assets/mp-logo-grey.png"
                media="(max-width: 799px)"
              >
              <img
                src="@/assets/mp-logo.png"
                alt="Monaco Parkings"
              >
            </picture>
            {{ $t('MonacoParkings') }}
          </a>
        </div>
        <input
          id="drawer-handle"
          v-model="isMenuOpen"
          type="checkbox"
        >
        <div class="navbar-menu">
          <div
            class="navbar-start"
          >
            <template
              v-for="categ in menu.children"
            >
              <div
                v-if="categ.children && (categ.meta && !categ.meta.isMenuHidden || !categ.meta)"
                :key="categ.name"
                class="navbar-item has-dropdown is-hoverable"
              >
                <div class="navbar-link">
                  {{ $t(categ.label || categ.name) }}
                </div>
                <div class="navbar-dropdown">
                  <template
                    v-for="sub in categ.children"
                  >
                    <router-link
                      v-if="sub && (sub.meta && !sub.meta.isMenuHidden || !sub.meta)"
                      :key="sub.name"
                      class="navbar-item"
                      :to="sub"
                    >
                      {{ $t(sub.label || sub.name) }}
                    </router-link>
                    <hr
                      v-if="sub.meta && sub.meta.hasDivider"
                      :key="sub.name"
                      class="navbar-divider"
                    >
                  </template>
                </div>
              </div>
              <router-link
                v-else-if="categ.meta && !categ.meta.isMenuHidden || !categ.meta"
                :key="categ.name"
                class="navbar-item"
                :to="categ"
              >
                {{ $t(categ.label || categ.name) }}
              </router-link>
            </template>
          </div>
          <div class="navbar-end">
            <!-- <div class="navbar-item has-dropdown is-hoverable">
              <div class="navbar-link">
                <b-icon
                  icon="globe"
                  custom-size="fa-fw"
                />
                <span>{{ $t('lang') }}</span>
              </div>
              <div class="navbar-dropdown">
                <a
                  v-for="(lang, i) in $langs"
                  :key="`Lang${i}`"
                  class="navbar-item"
                  @click="changelocale(lang.locale)"
                >
                  <figure class="image is-16x16">
                    <img :src="require('@/assets/flags/'+lang.flag+'.png')">
                  </figure>
                  <span style="margin-left: 1rem;">{{ lang.label }}</span>
                </a>
              </div>
            </div> -->
            <div class="navbar-item has-dropdown is-hoverable">
              <div
                v-if="user"
                class="navbar-link"
              >
                <b-icon
                  icon="user-circle"
                  custom-size="fa-fw"
                />
                <span>{{ user.firstname | recapitalize }}
                  {{ user.lastname | recapitalize }}</span>
              </div>
              <div class="navbar-dropdown">
                <template
                  v-for="categ in menu.children"
                >
                  <template
                    v-if="categ.label==='Compte'"
                  >
                    <router-link
                      v-for="sub in categ.children"
                      :key="sub.name"
                      :to="sub"
                      class="navbar-item"
                    >
                      <b-icon
                        v-if="sub.meta && sub.meta.icon"
                        :icon="sub.meta.icon"
                        custom-size="fa-fw"
                      />
                      <span>{{ $t(sub.label || sub.name) }}</span>
                    </router-link>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
        <aside
          id="drawer"
          class="menu z-5"
        >
          <div class="columns is-mobile is-marginless">
            <h1 class="column is-8">
              <a href="https://www.parkings.mc">
                <picture>
                  <source
                    srcset="@/assets/mp-logo-grey+text.png"
                    media="(min-width: 800px)"
                  >
                  <source
                    srcset="@/assets/mp-logo-grey+text.png"
                    media="(max-width: 799px)"
                  >
                  <img
                    src="@/assets/mp-logo-grey+text.png"
                    alt="Monaco Parkings"
                  >
                </picture>
              </a>
            </h1>
            <label
              id="drawer-handle-label2"
              for="drawer-handle"
              class="column is-4"
            >
              <div
                id="toggle-menu2"
                class="navbar-burger is-active"
              >
                <span />
                <span />
                <span />
              </div>
            </label>
          </div>
          <!-- <div class="control is-expanded has-icons-left">
            <span class="select is-fullwidth is-borderless">
              <select>
                <option selected="selected">Français (FR)</option>
                <option>English (EN)</option>
                <option>Italiano (IT)</option>
              </select>
            </span>
            <span class="icon is-small is-left">
              <i class="fa fa-globe" />
            </span>
          </div> -->
          <div
            v-for="categ in menu.children"
            :key="`drop${categ.name}`"
            class="navbar-item has-dropdown is-hoverable"
          >
            <h2
              v-if="categ.children &&
                (categ.meta && !categ.meta.isMenuHidden || !categ.meta)"
              :key="`h2${categ.name}`"
              class="menu-label menu-label-background"
            >
              <b-icon
                v-if="categ.meta && categ.meta.icon"
                :icon="categ.meta.icon"
                custom-size="fa-fw"
              />
              <span>{{ $t(categ.label || categ.name) }}</span>
            </h2>
            <ul
              :key="`ul${categ.name}`"
              class="menu-list"
            >
              <li>
                <ul>
                  <template
                    v-for="sub in categ.children"
                  >
                    <li
                      v-if="sub && (sub.meta && !sub.meta.isMenuHidden || !sub.meta)"
                      :key="sub.name"
                    >
                      <router-link
                        class="navbar-item"
                        :class="(sub.name===$route.name ||
                          (sub && sub.name!==$route.name && sub.children &&
                            sub.children.some(element => element.name === $route.name))) ?
                          'is-active' : ''"
                        :to="sub"
                        @click.native="isMenuOpen=!isMenuOpen"
                      >
                        {{ $t(sub.label || sub.name) }}
                      </router-link>
                      <hr
                        v-if="sub.meta && sub.meta.hasDivider"
                        class="navbar-divider"
                      >
                    </li>
                  </template>
                </ul>
              </li>
            </ul>
            <ul
              v-if="categ.meta && !categ.meta.isMenuHidden || !categ.meta"
              class="menu-list"
            >
              <li>
                <router-link
                  class="navbar-item"
                  :to="categ"
                  :class="(categ.name===$route.name ||
                    (categ && categ.name!==$route.name && categ.children &&
                      categ.children.some(element => element.name === $route.name))) ?
                    'is-active' : ''"
                  @click.native="isMenuOpen=!isMenuOpen"
                >
                  <b-icon
                    v-if="categ.meta && categ.meta.icon"
                    :icon="categ.meta.icon"
                    custom-size="fa-fw"
                  />
                  <span>{{ $t(categ.label || categ.name) }}</span>
                </router-link>
              </li>
            </ul>
            <h2 class="menu-label menu-label-background">
              <b-icon
                icon="user-circle"
                custom-size="fa-fw"
              />
              <span v-if="user">
                {{ user.firstname | recapitalize }}
                {{ user.lastname | recapitalize }}
              </span>
            </h2>
          </div>
          <ul class="menu-list">
            <li>
              <ul>
                <template
                  v-for="categ in menu.children"
                >
                  <li
                    v-if="categ.label==='Compte'"
                    :key="categ.label"
                  >
                    <router-link
                      v-for="sub in categ.children"
                      :key="sub.name"
                      :to="sub"
                      class="navbar-item"
                      :class="(sub.name===$route.name ||
                        (sub && sub.name!==$route.name &&
                          sub.children &&
                          sub.children.some(element => element.name === $route.name))) ?
                        'is-active' : ''"
                      @click.native="isMenuOpen=!isMenuOpen"
                    >
                      <b-icon
                        v-if="sub.meta && sub.meta.icon"
                        :icon="sub.meta.icon"
                        custom-size="fa-fw"
                      />
                      <span>{{ $t(sub.label || sub.name) }}</span>
                    </router-link>
                  </li>
                </template>
              </ul>
            </li>
          </ul>
        </aside>
      </div>
    </nav>
  </header>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'Header',
  components: {},
  data () {
    return {
      menu: this.$router.options.routes[0],
      isMenuOpen: false
    };
  },
  computed: {
    ...mapGetters('user', ['user']),
    menuCategories () {
      return this.menu.children.filter();
    }
  },
  mounted () {
    this.$store.dispatch('user/getUser');
    [this.menu] = this.$router.options.routes;
  },
  methods: {
    changelocale (locale) {
      this.$i18n.locale = locale;
      this.$moment.locale(locale);
    }
  }
};
</script>

<style></style>
