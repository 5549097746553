<template>
  <section class="hero is-primary is-signup is-fullheight">
    <div class="hero-head">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a
              class="navbar-item"
              href="https://www.parkings.mc"
            >
              <picture>
                <source
                  srcset="@/assets/mp-logo.png"
                  media="(min-width: 800px)"
                >
                <source
                  srcset="@/assets/mp-logo-grey.png"
                  media="(max-width: 799px)"
                >
                <img
                  src="@/assets/mp-logo.png"
                  alt="Monaco Parkings"
                >
              </picture>
              {{ $t('MonacoParkings') }}
            </a>
          </div>
          <!-- <div class="navbar-end">
            <div class="navbar-item has-dropdown is-hoverable">
              <div class="navbar-link">
                <b-icon
                  icon="globe"
                  custom-size="fa-fw"
                />
                <span>{{ $t('lang') }}</span>
              </div>
              <div class="navbar-dropdown">
                <a
                  v-for="(lang, i) in $langs"
                  :key="`Lang${i}`"
                  class="navbar-item"
                  @click="changelocale(lang.locale)"
                >
                  <figure class="image is-16x16">
                    <img :src="require('@/assets/flags/'+lang.flag+'.png')">
                  </figure>
                  <span style="margin-left: 1rem;">{{ lang.label }}</span>
                </a>
              </div>
            </div>
          </div> -->
        </div>
      </nav>
    </div>
    <maintenance-widget />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-mobile is-centered">
          <!-- The key is used to refresh Signup.vue component, triggering
          the mounted() guard, routing the user to the next step in his signup process -->
          <router-view :key="$route.fullPath" />
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import MaintenanceWidget from '@/components/home/widgets/Maintenance';

export default {
  name: 'LayoutLite',
  components: {
    'maintenance-widget': MaintenanceWidget
  },
  computed: {
    ...mapGetters('frontlines', ['isLoading'])
  },
  mounted () {
    this.$store.dispatch('auth/maintenance');
  },
  methods: {
    changelocale (locale) {
      this.$i18n.locale = locale;
      this.$moment.locale(locale);
    }
  }
};
</script>

<style lang="sass" >
@import ~bulma/sass/utilities/initial-variables

.hero.has-background
  background-image: url('../../assets/homepage-desktop.jpg')

.login-form box label
  color: $white

</style>
