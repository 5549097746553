<template>
  <div>
    <section class="section">
      <div class="container">
        <breadcrumbs />
        <div class="columns">
          <div class="column is-6 has-text-centered">
            <h1 class="title">
              {{ $t('invoices.payInvoice') }}
            </h1>
          </div>
          <div class="column is-6">
            <div class="columns is-mobile">
              <div class="column is-8 is-offset-2">
                <b-field
                  v-if="dueInvoices && dueInvoices.length"
                  :label="$t('invoices.selectInvoice')"
                >
                  <b-select
                    v-model="selectedInvoice"
                    expanded="expanded"
                    icon="credit-card"
                  >
                    <option
                      v-for="invoice in dueInvoices"
                      :key="invoice.id"
                      :value="invoice.id"
                    >
                      {{ invoice.id }}
                      -
                      {{ invoice.ts.emitted | moment('LL') }}
                      -
                      {{ $n(invoice.amount, 'currency') }}
                    </option>
                  </b-select>
                </b-field>
                <div v-else>
                  {{ $t('invoices.noDue') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="Object.keys(selectedInvoiceData).length > 0"
      class="hero is-primary"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns has-text-centered-touch">
            <div class="column is-6 has-text-centered">
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('invoices.resumeInvoiceId') }}
              </span>
              <br>
              <small class="title is-size-3 is-size-4-touch coCardName">
                {{ selectedInvoice }}
              </small>
              <br>
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('invoices.resumeInvoiceEmitDate') }}
              </span>
              <br>
              <small class="title is-size-3 is-size-4-touch coCardName">
                {{ selectedInvoiceData.ts.emitted | moment('LL') }}
              </small>
              <br>
            </div>
            <div class="column is-6">
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('invoices.resumeAmount') }}
              </span>
              <h2 class="has-text-centered is-size-2">
                <span class="coAmount">{{ $n(selectedInvoiceData.balance, 'currency') }}</span>
              </h2>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="Object.keys(selectedInvoiceData).length > 0"
      class="hero is-dark"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-half content">
              <pay-form
                :montant="selectedInvoiceData.balance"
                :invoice-id="selectedInvoice"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import payForm from './PayForm';

export default {
  name: 'PayInvoices',
  components: {
    payForm
  },
  props: {
    id: Number
  },
  data () {
    return {
      selectedInvoice: this.id
    };
  },
  computed: {
    selectedInvoiceData () {
      return (this.dueInvoices &&
        (this.dueInvoices.find((invoice) => invoice.id === this.selectedInvoice) ||
        this.dueInvoices[0]))
        ? (this.dueInvoices.find((invoice) => invoice.id === this.selectedInvoice) ||
         this.dueInvoices[0])
        : {};
    },
    ...mapGetters('invoices', ['dueInvoices'])
  },
  watch: {
  },
  mounted () {
    this.$store.dispatch('invoices/getDueInvoices');
  }
};
</script>

<style>

</style>
