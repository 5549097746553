<template>
  <section class="hero is-success">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-6 has-text-centered">
            <h1 class="title">
              {{ $t('prefooter.myAccount') }}
            </h1>
          </div>
          <div class="column is-6">
            <div class="content">
              <div class="level is-mobile">
                <div class="level-item has-text-centered">
                  <div>
                    <router-link
                      :to="{
                        name: 'Mes Contrats'
                      }"
                    >
                      <p class="heading">
                        {{ $t('prefooter.contracts') }}
                      </p>
                      <p class="title is-size-1">
                        {{ stats.contracts }}
                      </p>
                    </router-link>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <router-link
                      :to="{
                        name: 'Toutes mes Cartes'
                      }"
                    >
                      <p class="heading">
                        {{ $t('prefooter.tags') }}
                      </p>
                      <p class="title is-size-1">
                        {{ stats.tags }}
                      </p>
                    </router-link>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <router-link
                      :to="{
                        name: 'Mon Activité'
                      }"
                    >
                      <p class="heading">
                        {{ $t('prefooter.movements') }}
                      </p>
                      <p class="title is-size-1">
                        {{ stats.movements }}
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PreFooter',
  data () {
    return {};
  },
  computed: {
    ...mapGetters('user', ['stats'])
  },
  created () {
    this.$store.dispatch('user/getStats');
  }
};
</script>

<style>

</style>
