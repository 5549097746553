<template>
  <div class="column is-two-thirds-mobile is-3-desktop has-text-centered">
    <h1 class="title is-4">
      <figure class="image is-96x96 mp-logo">
        <img src="../../assets/mp-logo.png">
      </figure>
      {{ $t('MonacoParkings') }}
    </h1>
    <h2 class="subtitle has-text-weight-light is-size-6">
      {{ $t('login.welcome') }}
      <span class="has-text-weight-semibold">{{ $t('login.clientSpace') }}</span>
    </h2>
    <form
      class="login-form box"
      @submit.prevent="login"
    >
      <b-field :type="(message.length>1 && clientId==='') ? 'is-danger' : ''">
        <b-input
          v-model="clientId"
          :placeholder="$t('login.idClient')"
          size="is-medium"
          icon="hashtag"
        />
      </b-field>
      <b-field :type="(message.length>1 && password==='') ? 'is-danger' : ''">
        <b-input
          v-model="password"
          :placeholder="$t('login.password')"
          icon="lock"
          type="password"
          size="is-medium"
        />
      </b-field>
      <b-field>
        <b-checkbox
          v-model="chkRememberMe"
          class="has-text-weight-light"
          value="yes"
        >
          {{ $t('login.rememberMe') }}
        </b-checkbox>
      </b-field>
      <b-field
        v-if="loginState.verify && !banned"
        class="has-text-centered"
      >
        <vue-recaptcha
          class="g-recaptcha"
          size="compact"
          :sitekey="$store.state.config.reCaptcha.sitekey"
          @verify="verifyCaptcha"
        />
      </b-field>
      <b-field
        v-if="banned"
        class="has-text-centered"
        :message="getError(message)"
        type="is-danger"
      >
        {{ banRemaining }}
        {{ $t('login.minutesLeft') }}
      </b-field>
      <b-field
        v-else
        class="has-text-centered"
        :message="getError(message)"
        type="is-danger"
      />
      <b-field
        class="has-text-centered"
        style="margin-top:3rem"
      >
        <button
          class="button is-success is-medium is-fullwidth"
          :class="{'is-loading' : isLoggingIn}"
          type="submit"
          :disabled="banned"
        >
          {{ $t('login.login') }}
        </button>
      </b-field>
      <b-field class="has-text-centered">
        <router-link
          class="button is-outlined is-small is-fullwidth"
          :to="{name: 'resetPassword'}"
        >
          {{ $t('login.forgetPassword') }}
        </router-link>
      </b-field>
    </form>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { mapGetters } from 'vuex';

export default {
  name: 'Login',
  components: {
    VueRecaptcha
  },
  data () {
    return {
      chkRememberMe: null, isLoggingIn: false, clientId: '', password: '', captchaResponse: null
    };
  },
  computed: {
    rememberMe () {
      return this.chkRememberMe
        ? 'yes'
        : 'no';
    },
    banRemaining () {
      const addZero = (timePart) => (timePart < 10 ? `0${timePart}` : timePart);
      const diff = new Date(this.timestamp.banned - this.timestamp.now);
      return `${addZero(diff.getMinutes())}:${addZero(diff.getSeconds())}`;
    },
    banned () {
      return this.timestamp.now - this.timestamp.banned <= 0;
    },
    // ...mapState('user', ['loginState']),
    ...mapGetters('auth', ['loginState', 'timestamp', 'isLogginIn', 'message'])
  },
  mounted () {
    this.createRecaptcha();
    this.$store.dispatch('auth/startTimer');
    if (this.$store.getters['auth/rememberMe']) {
      this.$router.push({ name: 'Accueil' });
    } else {
      // this.$store.dispatch('db/clearIndexedDb').then(() => {
      //   console.log('cleared');
      //   this.$store.dispatch('initialiseStore');
      // });
    }
  },
  beforeDestroy () {
    // not tested
    document.getElementById('recaptchaScript').remove();
  },
  methods: {
    verifyCaptcha (response) {
      this.captchaResponse = response;
    },
    createRecaptcha () {
      const script = document.createElement('script');
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.id = 'recaptchaScript';
      script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit';
      document.getElementsByTagName('head')[0].appendChild(script);
    },
    getRoute (target) {
      const tracks = {
        home: 'Accueil',
        addDevice: 'Add Device',
        suspicious: 'Suspicous Activity',
        signup: 'signup'
      };
      return tracks[target] || 'login';
    },
    getError (code) { // TODO: translations in vuex store ?
      const errors = {
        'auth.userUnknown': this.$t('login.userUnknown'),
        'auth.verify': this.$t('login.verify'),
        'auth.banned': this.$t('login.banned')
      };
      return errors[code] || code;
    },
    login () {
      this.$store.dispatch('auth/emptyMessage');
      if (this.clientId === '') {
        this.$store.dispatch('auth/pushMessage', this.$t('login.requiredClientId'));
        return;
      }
      if (this.password === '') {
        this.$store.dispatch('auth/pushMessage', this.$t('login.requiredPassword'));
        return;
      }
      if (this.loginState.verify && this.captchaResponse === null) {
        this.$store.dispatch('auth/pushMessage', this.$t('login.requiredCaptcha'));
        return;
      }
      this.$store.dispatch('auth/login', {
        clientId: this.clientId,
        password: this.password,
        rememberMe: this.rememberMe,
        captchaResponse: this.captchaResponse
      }).then((response) => {
        if (response && response.data && response.data.status === 'success') {
          this.$store.dispatch('db/clearIndexedDb');
          this.$store.dispatch('auth/setRememberMe', this.chkRememberMe);
          this.$router.push({
            name: this.getRoute(response.data.data.target)
          });
        }
      });
    }
  }
};
</script>
<style>
.g-recaptcha > div {
  margin: auto;
}
</style>
