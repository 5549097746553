<template>
  <section class="section">
    <div class="container">
      <breadcrumbs />
      <div class="columns">
        <div class="column is-6 has-text-centered">
          <h1 class="title">
            {{ $t('tasks.myTasks') }}
          </h1>
        </div>
        <div class="column is-6">
          <div class="content has-text-centered-touch">
            <b-table
              :data="tasks"
              striped
            >
              <b-table-column
                v-slot="props"
                field="status"
                :label="$t('tasks.status')"
                numeric
                centered
              >
                <b-icon
                  :icon="statusIcon(props.row.status).icon"
                  :type="statusIcon(props.row.status).type"
                />
                <br>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="type"
                :label="$t('tasks.type')"
              >
                {{ operationType(props.row.type) }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="details"
                :label="$t('tasks.details')"
              >
                <template v-if="props.row.type==='TRANS_CD'">
                  <strong>{{ $n(props.row.metadata.amount, 'currency') }}</strong>
                  {{ $t('tasks.tagTransferFrom') }}
                  <strong>{{ props.row.metadata.tagFrom }}</strong>
                  {{ $t('tasks.tagTransferTo') }}
                  <strong>{{ props.row.metadata.tagTo }}</strong>
                  <br>
                </template>
                <template v-if="props.row.type==='REGL_FAC'" />
                <template v-if="props.row.type==='RECH_CD'">
                  <strong>{{ $n(props.row.metadata.amount, 'currency') }}</strong>
                  {{ $t('tasks.tagTransferTo') }}
                  <strong>{{ props.row.metadata.tag }}</strong>
                </template>
                <template v-else />
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="registeredAt"
                :label="$t('tasks.registeredAt')"
              >
                {{ props.row.registeredAt | moment("LLL") }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="finishedAt"
                :label="$t('tasks.finishedAt')"
              >
                {{ props.row.finishedAt | moment("LLL") }}
              </b-table-column>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="empty-set"
                        size="is-large"
                      />
                    </p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'Tasks',
  data () {
    return {
      alert: []
    };
  },
  computed: {
    ordAlerts () {
      return [...this.tags]
        .map((a) => {
          const tag = a;
          tag.balance.hasThreshold = !!this.alerts.some((c) => c.tag === a.id);
          const alert = this.alerts.find((c) => c.tag === a.id);
          tag.balance.threshold = alert ? alert.threshold : null;
          return tag;
        })
        .sort((a, b) => (this.alerts.some((c) => c.tag === a.id) ? -1 : 1));
    },
    ...mapGetters('user', ['tasks'])
  },
  mounted () {
    this.$store.dispatch('user/getTasks'); // TODO: Set timeout to live refresh or refresh button
  },
  methods: {
    statusIcon (statusCode) {
      const status = {
        100: {
          tag: 'TODO',
          type: 'is-primary',
          icon: 'business-time'
        },
        101: {
          tag: 'PROCESSING',
          type: 'is-primary',
          icon: 'cog'
        },
        200: {
          tag: 'OK',
          type: 'is-success',
          icon: 'check-circle'
        },
        300: {
          tag: 'CANCELLED',
          type: 'is-danger',
          icon: 'block'
        },
        500: {
          tag: 'ERROR',
          type: 'is-danger',
          icon: 'exclamation-circle'
        },
        payetest: {
          tag: 'MONETICO_TEST',
          icon: ''
        },
        paiement: {
          tag: 'MONETICO_OK',
          icon: ''
        },
        Annulation: {
          tag: 'MONETICO_CANCEL',
          icon: ''
        }
      };
      return status[statusCode];
    },
    operationType (typeCode) {
      const keys = {
        TRANS_CD: 'tasks.tagTransfer',
        REGL_FAC: 'tasks.invoicePay',
        RECH_CD: 'tasks.tagRefill'
      };
      return this.$t(keys[typeCode]);
    }
  }
};
</script>

<style>

</style>
