<template>
  <router-view v-if="$route && $route.params && $route.params.id" />
  <section
    v-else
    class="section"
  >
    <div class="container">
      <breadcrumbs />
      <div class="columns">
        <div class="column is-6 has-text-centered">
          <h1 class="title">
            {{ $t('invoices.myInvoices') }}
          </h1>
        </div>
        <div class="column is-6">
          <div class="content has-text-centered-touch">
            <div class="columns is-mobile">
              <div class="column is-8 is-offset-2">
                <b-field>
                  <b-select
                    v-model="selectedContract"
                    expanded
                    icon="pen-alt"
                  >
                    <option
                      selected="selected"
                      value="All"
                    >
                      {{ $t('invoices.allMyContracts') }}
                    </option>
                    <option
                      v-for="contract in ordContracts"
                      :key="contract.id"
                      :value="contract.id"
                    >
                      {{ contract.id }} - {{ contract.product.label }} {{ contract.props.terminated
                        ? ('- ' + $t('invoices.terminatedContract')) : '' }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <b-table
              :data="invoices"
              striped
            >
              <b-table-column
                v-slot="props"
                field="id"
                :label="$t('invoices.invoiceId')"
                style="cursor: pointer"
                width="200"
              >
                <router-link
                  class="block"
                  :to="{name: 'Ma Facture Détaillée', params: {id : props.row.id}}"
                >
                  <b-icon
                    type="is-grey"
                    size="is-small"
                    icon="file-invoice-dollar"
                  />
                  {{ props.row.id }}
                  <b-icon
                    v-if="props.row.balance > 0 &&
                      $moment(props.row.ts.term).isBefore($moment(new Date()))"
                    icon="exclamation-circle"
                    type="is-danger"
                  />
                  <b-icon
                    v-else-if="props.row.balance > 0 &&
                      $moment(props.row.ts.term).isAfter($moment(new Date()))"
                    icon="exclamation-triangle"
                    type="is-danger"
                  />
                  <b-icon
                    v-else
                    icon="check-circle"
                    type="is-success"
                  />
                </router-link>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="amount"
                :label="$t('invoices.amount')"
              >
                {{ $n(getReducedAmount(props.row), 'currency') }}
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="balance"
                :label="$t('invoices.restOf')"
              >
                <span :class="props.row.balance > 0 ? 'has-text-danger' : ''">
                  {{ props.row.balance ? $n(-props.row.balance, 'currency') :'' }}
                </span>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="reconduction"
                :label="$t('invoices.reconduction')"
              >
                <small>{{ props.row.ts.emitted | moment('LL') }}</small>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="term"
                :label="$t('invoices.term')"
              >
                <small>{{ props.row.ts.term | moment('LL') }}</small>
              </b-table-column>
            </b-table>
          </div>
          <div class="columns is-mobile">
            <div class="column is-6 is-offset-3">
              <button
                class="button is-primary is-fullwidth"
                @click="loadMore"
              >
                <span class="icon">
                  <i class="fas fa-list-ul" />
                </span>
                <span>{{ $t('invoices.seeMore') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'Invoices',
  props: ['contractId'],
  data () {
    return {
      selectedContract: this.contractId || 'All'
    };
  },
  computed: {
    localInvoices () {
      return [...this.invoices]
        .sort((a, b) => (this.$moment(a.ts.term).isBefore(this.$moment(b.ts.term))
          ? 1
          : a.id - b.id));
    },
    ordContracts () {
      return [...this.contracts]
        .filter((contract) => contract.periodicity)
        .sort((a, b) => a.props.terminated - b.props.terminated);
    },
    ...mapGetters('invoices', ['invoices']),
    ...mapGetters('contracts', ['contracts'])
  },
  watch: {
    selectedContract (val) {
      this.$router.push({
        name: 'Mes Factures',
        params: {
          contractId: val
        }
      });
    },
    $props: {
      handler (val, oldVal) {
        this.selectedContract = val.id;
      },
      deep: true
    }
  },
  mounted () {
    if (!this.$route.params || !this.$route.params.id) {
      this.$store.dispatch('invoices/getInvoices', this.selectedContract);
      this.$store.dispatch('contracts/getContracts');
    }
  },
  methods: {
    loadMore () {
      this.$store.dispatch('invoices/getMoreInvoices', this.selectedContract);
    },
    getReducedAmount (invoice) {
      return invoice.amount - (invoice.reduc_ttc || 0);
    }
  }
};
</script>

<style>

</style>
