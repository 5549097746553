<template>
  <div class="column is-two-thirds-mobile is-3-desktop toast-padding">
    <form
      class="login-form box"
      @submit.prevent="submitForm"
    >
      <h3 class="title is-3">
        {{ $t('addDevice.newDeviceDetectedTitle') }}
      </h3>
      <p>{{ $t('addDevice.newDeviceDetected') }}</p>
      <b-field
        :label="$t('addDevice.name')"
        :message="errors"
      >
        <b-input
          v-model="deviceName.value"
          icon="tag fa-xs"
          :readonly="step ? true : false"
          @blur="deviceName.visited = true"
        />
      </b-field>
      <b-field
        grouped
        group-multiline
        expanded
        position="is-centered"
      >
        <div class="control">
          <button
            v-if="step"
            type="button"
            class="button is-pulled-left is-info"
            @click="askCode"
          >
            {{ $t('addDevice.resendCode') }}
          </button>
          <button
            v-else
            class="button is-pulled-left is-success"
            :disabled="!!errors.length"
          >
            {{ $t('addDevice.addDevice') }}
          </button>
        </div>
        <div
          v-if="step"
          class="control"
        >
          <button
            type="button"
            class="button is-danger"
            @click="secretCode = '', step = null"
          >
            {{ $t('addDevice.changeDeviceName') }}
          </button>
        </div>
      </b-field>
      <b-field
        v-if="step"
        :label="$t('addDevice.code')"
      >
        <b-input
          v-model="secretCode"
          placeholder="XXXXXX"
          icon="key fa-xs"
        />
      </b-field>
      <b-field
        v-if="step"
        grouped
        group-multiline
        expanded
        position="is-centered"
      >
        <div class="control">
          <button
            class="button is-success is-pulled-left"
            :disabled="!secretCode"
          >
            {{ $t('addDevice.confirmCode') }}
          </button>
        </div>
      </b-field>
    </form>
  </div>
</template>

<script>
export default {
  name: 'AddDevice',
  data () {
    return {
      deviceName: {
        value: 'Mon premier périphérique',
        visited: false
      },
      secretCode: '',
      step: false
    };
  },
  computed: {
    errors () {
      const errors = [];
      if (this.step && this.secretCode.length === 0) errors.push(this.$t('addDevice.requiredSecretCode'));
      if (this.deviceName.value.length === 0) errors.push(this.$t('addDevice.requiredDeviceName'));
      return errors;
    }
  },
  mounted () {},
  methods: {
    submitForm () {
      if (this.step === 'check') this.sendCode();
      else this.askCode();
    },
    isInputValid (input) {
      if (input.visited && !input.value) {
        return false;
      }
      return true;
    },
    askCode () {
      if (this.errors.length === 0) {
        this.$http.post('device/add', {
          name: this.deviceName.value
        }).then(() => {
          this.step = 'check';
        }).catch((error) => {
          this.$buefy.snackbar.open({
            indefinite: true,
            message: `${this.$t('addDevice.errorAskCode', error)}`,
            type: 'is-warning',
            actionText: this.$t('addDevice.retry'),

            onAction: this.askCode
          });
        });
      }
    },
    sendCode () {
      if (this.errors.length === 0) {
        this.$http.post('device/add', {
          name: this.deviceName.value,
          code: this.secretCode
        }).then((response) => {
          this.$router.push({
            name: 'Accueil'
          });
        }).catch((error) => {
          this.$buefy.snackbar.open({
            indefinite: true,
            message: `${this.$t('addDevice.errorSendCode', error)}`,
            type: 'is-warning',
            actionText: this.$t('addDevice.retry'),

            onAction: this.askCode
          });
        });
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.toast-padding
  padding-bottom: 3.5rem
</style>
