import Vue from 'vue';
import Vuex from 'vuex';
import storage from 'local-storage-fallback';

import config from '@/config';

import getters from './getters';
import actions from './actions';
import mutations from './mutations';

import db from './modules/db';

import auth from './modules/auth';

import user from './modules/user';

import tags from './modules/tags';
import contracts from './modules/contracts';
import invoices from './modules/invoices';
import excesses from './modules/excesses';
import movements from './modules/movements';

if (!('localStorage' in window)) {
  window.localStorage = storage;
}

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  // plugins: [axios(Vue)],
  modules: {
    auth,
    user,
    tags,
    contracts,
    movements,
    invoices,
    excesses,
    db
  },
  state: {
    config,
    version: ''
  },
  actions,
  getters,
  mutations
});

// Subscribe to store updates
store.subscribe((mutation, state) => {
  const toStoreLocal = state;
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(toStoreLocal));
});

export default store;
