<template>
  <section
    id="change-cellular-anch"
    class="hero is-primary"
  >
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-6 has-text-centered">
            <h2 class="title is-size-3 has-text-centered-touch">
              {{ $t('myAccount.changeCellular') }}
            </h2>
            <div class="columns">
              <div class="column is-8 is-offset-2">
                <div class="card">
                  <div class="card-header">
                    <p class="card-header-title">
                      {{ $t('myAccount.cellular.current') }}
                    </p>
                  </div>
                  <div class="card-content has-text-centered">
                    <b-field position="is-centered">
                      <b-input
                        v-if="user && user.cellular"
                        :key="user.cellular"
                        v-mask="'(+##) # ## ## ## ##'"
                        placeholder="X XX XX XX XX"
                        :value="user.cellular"
                        readonly="readonly"
                        icon="mobile-alt"
                      />
                      <b-input
                        v-else
                        placeholder="X XX XX XX XX"
                        readonly="readonly"
                        icon="mobile-alt"
                      />
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="title is-size-3 has-text-centered-touch">
&nbsp;
            </div>
            <div class="box">
              <form @submit.prevent="submitForm">
                <b-field :label="$t('signup.cellular.newCellular')">
                  <b-field
                    position="is-centered"
                    :type="(formattedCellular.length === 3 ||
                      formattedCellular.length === 16) ?
                      'is-success' : 'is-danger'"
                  >
                    <p class="control">
                      <span class="button is-static">+33</span>
                    </p>
                    <b-input
                      v-model="cellular"
                      v-mask="cellularMask"
                      placeholder="X XX XX XX XX"
                      expanded
                      :disabled="step"
                    />
                  </b-field>
                </b-field>
                <b-field
                  grouped
                  group-multiline
                  position="is-centered"
                >
                  <div class="control">
                    <button
                      v-if="step"
                      type="button"
                      class="button is-pulled-left is-info"
                      @click="askCode"
                    >
                      {{ $t('signup.cellular.resendCode') }}
                    </button>
                    <button
                      v-else
                      class="button is-pulled-left is-success"
                    >
                      {{ $t('signup.cellular.sendCellular') }}
                    </button>
                  </div>
                  <div
                    v-if="step"
                    class="control"
                  >
                    <button
                      type="button"
                      class="button is-danger"
                      @click="cellular = '', step = null"
                    >
                      {{ $t('signup.cellular.changeCellular') }}
                    </button>
                  </div>
                </b-field>
                <b-field
                  v-if="step"
                  :label="$t('signup.cellular.enterCode')+formattedCellular"
                >
                  <b-input
                    v-model="secretCode"
                    placeholder="XXXXXX"
                    icon="key fa-xs"
                  />
                </b-field>
                <b-field
                  v-if="step"
                  grouped
                  group-multiline
                  expanded
                  position="is-centered"
                >
                  <div class="control">
                    <button class="button is-success is-pulled-left">
                      {{ $t('signup.cellular.confirmCode') }}
                    </button>
                  </div>
                </b-field>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import {
  mask
} from 'vue-the-mask';

export default {
  name: 'CellularReset',
  directives: {
    mask
  },
  data () {
    return {
      clientId: '',
      clientInput: '',
      cellular: '',
      secretCode: '',
      step: false,
      cellularMask: {
        mask: 'P ## ## ## ##',
        tokens: {
          P: {
            pattern: /[67]/
          },
          '#': {
            pattern: /\d/
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters('user', ['user']),
    formattedCellular () {
      return this.formatCellular(this.cellular);
    }
  },
  mounted () {},
  methods: {
    submitForm () {
      if (!!this.cellular && !!this.secretCode) this.sendCode();
      else this.askCode();
    },
    formatCellular (cellular) {
      return `+33${cellular}`;
    },
    askCode () {
      this.$http.post('client/cellular/reset', {
        cellular: this.formattedCellular
      }).then(() => {
        this.step = 'check';
      }).catch((error) => {
        this.$buefy.snackbar.open({
          indefinite: true,
          message: `${this.$t('myAccount.cellular.errorChange', error)}`,
          type: 'is-warning',
          actionText: this.$t('adminChanges.retry'),

          onAction: this.askCode
        });
      });
    },
    sendCode () {
      this.$http.post('client/cellular/reset', {
        cellular: this.formattedCellular,
        code: this.secretCode
      }).then((response) => {
        if (response.data.success) {
          this.$buefy.notification.open({

            duration: 30000,
            message: this.$t('myAccount.cellular.successChange', [this.formattedCellular]),
            type: 'is-success'
          });
        }
      }).catch((error) => {
        this.$buefy.snackbar.open({
          indefinite: true,
          message: `${this.$t('myAccount.cellular.errorChange', error)}`,
          type: 'is-warning',
          actionText: this.$t('adminChanges.retry'),

          onAction: this.sendCode
        });
      }).finally(() => {
        this.$store.dispatch('user/getUser');
      });
    }
  }
};
</script>
