<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column is-3 has-text-centered">
          <h1 class="title">
            {{ $t('tags.myCardsBalance') }}
          </h1>
        </div>
        <div class="column is-9">
          <b-field
            grouped
            group-multiline
          >
            <b-select
              v-model="perPage"
              :disabled="!isPaginated"
            >
              <option value="5">
                5 par page
              </option>
              <option value="10">
                10 par page
              </option>
              <option value="15">
                15 par page
              </option>
              <option value="20">
                20 par page
              </option>
            </b-select>
            <div class="control is-flex">
              <b-switch v-model="isPaginated">
                Pagination
              </b-switch>
              <b-field v-if="isMobile">
                <b-input
                  v-model="filterText"
                  type="text"
                  placeholder="N° Carte/Contrat "
                />
              </b-field>
            </div>
          </b-field>
          <b-table
            :data="balancedTags"
            :paginated="isPaginated"
            :per-page="perPage"
            striped
          >
            <b-table-column
              v-slot="props"
              field="id"
              :label="$t('carte')"
              sortable
              searchable
            >
              <router-link
                :to="{
                  name: 'Toutes mes Cartes',
                  params: {
                    contractId: props.row.contract.id,
                    tagId: props.row.id
                  }
                }"
              >
                <span>
                  <b-icon
                    icon="id-card"
                    size="is-small"
                  />
                  <span v-html="$t('tags.tagD', [props.row.id])" />
                </span>
              </router-link>
              <!-- {{ props.row.id.toString() }} -->
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="contract.id"
              :label="$t('contrat')"
              sortable
              searchable
            >
              <router-link
                :to="{
                  name: 'Toutes mes Cartes',
                  params: {
                    contractId: props.row.contract.id
                  }
                }"
              >
                <span>
                  <b-icon
                    icon="pen-alt"
                    size="is-small"
                  />
                  <span v-html="$t('tags.contractId', [props.row.contract.id])" />
                </span>
              </router-link>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="product.label"
              :label="$t('welcome.holder')"
              sortable
            >
              <span class="is-uppercase">{{ props.row.product.label }}</span><br>
              <small>
                {{ props.row.holder.firstname | recapitalize }}
                {{ (props.row.holder.lastname || '') | recapitalize }}
              </small>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="balance.type"
              :label="$t('welcome.balance')"
              sortable
            >
              <template v-if="tagBalance(props.row)">
                {{ tagBalance(props.row) }}
                <small>
                  {{ props.row.balance.type === 'EUR' ? 'EUR' : 'H' }}
                </small>
              </template>
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$t('welcome.recharge')"
              sortable
            >
              <router-link
                :to="{name: 'Recharger ma Carte', params: { id: props.row.id}}"
                class="button is-success is-small"
              >
                <b-icon
                  icon="cart-plus"
                  size="is-small"
                />
                <span>{{ $t('welcome.recharge') }}</span>
              </router-link>
            </b-table-column>
          </b-table>
          <div class="columns is-mobile">
            <div class="column is-6 is-offset-3">
              <router-link
                :to="{name: 'Toutes mes Cartes'}"
                class="button is-primary is-fullwidth is-outlined"
              >
                <span class="icon">
                  <i class="fas fa-list-ul" />
                </span>
                <span>{{ $t('tags.seeDetail') }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'TagsWidget',
  data () {
    return {
      filterText: '',
      isPaginated: true,
      perPage: 5
    };
  },
  computed: {
    ...mapGetters('tags', ['tags']),
    balancedTags () {
      const filterText = this.filterText.toLowerCase();
      return this.tags
        .filter((tag) => tag && tag.balance && tag.props &&
        !tag.props.terminated &&
        (tag.applicable.to === null ||
          this.$moment(tag.applicable.to).isAfter(this.$moment(new Date()))) &&
        tag.balance.is_cd)
        .sort((a, b) => a.id - b.id).map((item) => {
          const newItem = { ...item }; // Create a copy to avoid mutation
          newItem.id = String(newItem.id); // Convert id to string
          if (newItem.contract) {
            newItem.contract.id = String(newItem.contract.id); // Convert contract.id to string
          }
          return newItem;
        })
        .filter((item) => (
          item.id.toLowerCase().includes(filterText) ||
          item.contract.id.toLowerCase().includes(filterText)
        ));
    },
    isMobile () {
      return window.innerWidth < 768; // Adjust the threshold as needed
    }
  },
  mounted () {
  },
  methods: {
    tagBalance (tag) {
      if (tag.balance.is_cd) {
        if (tag.balance.type === 'EUR') {
          return tag.balance.value.cd;
        }
        return Math.round(tag.balance.value.cd / 60);
      }
      if (tag.balance.value.ab) {
        return Math.round(tag.balance.value.ab / 60);
      }
      return false;
    }
  }
};
</script>

<style>

</style>
