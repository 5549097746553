<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style lang="sass" src="./sass/main.sass"></style>
<style lang="sass">
  @import ~@fortawesome/fontawesome-pro/scss/variables
  $fa-font-path : "~@fortawesome/fontawesome-pro/webfonts"
  @import ~@fortawesome/fontawesome-pro/scss/fontawesome
  @import ~@fortawesome/fontawesome-pro/scss/solid
  @import ~@fortawesome/fontawesome-pro/scss/brands
</style>
<!-- <style src="./override.css"/> -->
