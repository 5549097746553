import moment from 'moment';

const mutationsTypes = {
  SET_INVOICES_LIST: 'SET_INVOICES_LIST',
  SET_INVOICE_DETAILS: 'SET_INVOICE_DETAILS',
  PUSH_INVOICES_LIST: 'PUSH_INVOICES_LIST',
  SET_OVERDUE_INVOICES: 'SET_OVERDUE_INVOICES',
  SET_OUTSTANDING_INVOICES: 'SET_OUTSTANDING_INVOICES'
};

// initial state
const initState = {
  invoiceList: {
    data: [],
    lastUpdate: null
  },
  selectedInvoice: {
    data: {},
    lastUpdate: null
  },
  overdueInvoices: {
    data: [],
    lastUpdate: null
  },
  outstandingInvoices: {
    data: [],
    lastUpdate: null
  }
};

// getters
const getters = {
  invoices: (state) => state.invoiceList.data,
  invoice: (state) => state.selectedInvoice.data,
  overdueInvoices: (state) => state.overdueInvoices.data,
  outstandingInvoices: (state) => state.outstandingInvoices.data,
  dueInvoices: (state) => [...state.overdueInvoices.data, ...state.outstandingInvoices.data]
};

// actions
const actions = {
  registerTable: {
    root: true,
    handler ({ dispatch }) {
      dispatch('db/addTable', { invoices: 'id, balance, contract.id' }, { root: true });
    }
  },
  getInvoiceExcesses ({ state, dispatch }, id) {
    dispatch('db/select', {
      table: 'invoices', callback: `invoices/${mutationsTypes.SET_INVOICE_DETAILS}`, where: { ':id': id }, sortBy: 'ts.term'
    }, { root: true });
    this.$http.get('invoice/excesses', {
      params: {
        id
      }
    }).then((result) => {
      if (result && result.data && result.data.data) {
        const mySelectedInvoice = { ...state.selectedInvoice };
        for (let i = 0, l = mySelectedInvoice.data.details.length; i < l; i += 1) {
          if (mySelectedInvoice.data.details[i].product.type === 'DEPAS') {
            mySelectedInvoice.data.details[i].excesses = [];
            for (let j = 0, k = result.data.data.length; j < k; j += 1) {
              if (result.data.data[j].parc.id ===
                mySelectedInvoice.data.details[i].parc.id &&
              result.data.data[j].tag.id ===
                mySelectedInvoice.data.details[i].tag.id) {
                mySelectedInvoice.data.details[i].excesses.push(result.data.data[j]);
              }
            }
          }
        }
        dispatch('db/updateRow', { table: 'invoices', key: id, data: mySelectedInvoice.data },
          { root: true });
      }
    }).finally(() => {
      dispatch('db/select', {
        table: 'invoices', callback: `invoices/${mutationsTypes.SET_INVOICE_DETAILS}`, where: { ':id': id }, sortBy: 'ts.term'
      }, { root: true });
    });
  },
  getInvoiceDetails ({
    state,
    dispatch
  }, id) {
    dispatch('db/select', {
      table: 'invoices', callback: `invoices/${mutationsTypes.SET_INVOICE_DETAILS}`, where: { ':id': id }, sortBy: 'ts.term'
    }, { root: true });
    this.$http.get('invoice/details', {
      params: {
        id
      }
    }).then((result) => {
      if (result && result.data && result.data.data) {
        dispatch('db/updateRow', {
          table: 'invoices',
          key: id,
          data: {
            details: result.data.data
          }
        }, { root: true });
      }
    })
      .finally(() => {
        dispatch('db/select', {
          table: 'invoices',
          callback:
      `invoices/${mutationsTypes.SET_INVOICE_DETAILS}`,
          where: { ':id': id },
          sortBy: 'ts.term'
        }, { root: true });
        dispatch('getInvoiceExcesses', id);
      });
  },
  reloadInvoices ({
    state,
    dispatch,
    rootGetters
  }) {
    const limit = state.invoiceList.data.length + rootGetters &&
        rootGetters.config &&
        rootGetters.config.api &&
        rootGetters.config.api.requestLimit
      ? rootGetters.config.api.requestLimit
      : 10;
    this.$http.get('invoice/list', {
      params: {
        limit
      }
    }).then((result) => {
      if (result && result.data && result.data.data) {
        dispatch('db/add', { table: 'invoices', data: result.data.data }, { root: true });
      }
    }).finally(() => {
      dispatch('db/select', {
        table: 'invoices', callback: `invoices/${mutationsTypes.SET_INVOICES_LIST}`, limit, sortBy: 'ts.term'
      }, { root: true });
    });
  },
  getDueInvoices ({
    state,
    dispatch,
    rootGetters
  }) {
    // const refresh = rootGetters &&
    //   rootGetters.config &&
    //   rootGetters.config.api &&
    //   rootGetters.config.api.refreshEvery
    //   ? rootGetters.config.api.refreshEvery : 5 * 60 * 1000;
    dispatch('db/select', {
      table: 'invoices',
      callback: `invoices/${mutationsTypes.SET_OUTSTANDING_INVOICES}`,
      where: 'balance',
      whereClause: { test: 'above', value: 0 },
      and: (invoice) => moment(invoice.ts.term).isAfter(moment(new Date())),
      sortBy: 'ts.term'
    }, { root: true });
    dispatch('db/select', {
      table: 'invoices',
      callback: `invoices/${mutationsTypes.SET_OVERDUE_INVOICES}`,
      where: 'balance',
      whereClause: { test: 'above', value: 0 },
      and: (invoice) => moment(invoice.ts.term).isBefore(moment(new Date())),
      sortBy: 'ts.term'
    }, { root: true });
    Promise.all([this.$http.get('invoice/overdue'), this.$http.get('invoice/outstanding')]).then((result) => {
      if (result && result[0] && result[0].data && result[0].data.data) {
        dispatch('db/add', { table: 'invoices', data: result[0].data.data }, { root: true });
      }
      if (result && result[1] && result[1].data && result[1].data.data) {
        dispatch('db/add', { table: 'invoices', data: result[1].data.data }, { root: true });
      }
    }).finally(() => {
      dispatch('db/select', {
        table: 'invoices',
        callback: `invoices/${mutationsTypes.SET_OUTSTANDING_INVOICES}`,
        where: 'balance',
        whereClause: { test: 'above', value: 0 },
        and: (invoice) => moment(invoice.ts.term).isAfter(moment(new Date())),
        sortBy: 'ts.term'
      }, { root: true });
      dispatch('db/select', {
        table: 'invoices',
        callback: `invoices/${mutationsTypes.SET_OVERDUE_INVOICES}`,
        where: 'balance',
        whereClause: { test: 'above', value: 0 },
        and: (invoice) => moment(invoice.ts.term).isBefore(moment(new Date())),
        sortBy: 'ts.term'
      }, { root: true });
    });
  },
  getInvoices ({
    state,
    dispatch,
    rootGetters
  }, contractId) {
    const limit = rootGetters &&
      rootGetters.config &&
      rootGetters.config.api &&
      rootGetters.config.api.requestLimit
      ? rootGetters.config.api.requestLimit
      : 10;

    if (Number.isInteger(contractId)) {
      dispatch('db/select', {
        table: 'invoices', callback: `invoices/${mutationsTypes.SET_INVOICES_LIST}`, limit, where: { 'contract.id': contractId }, sortBy: 'ts.term'
      }, { root: true });
      this.$http.get('contract/invoice/list', {
        params: {
          limit,
          id: contractId
        }
      }).then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'invoices', data: result.data.data }, { root: true });
        }
      }).finally(() => {
        dispatch('db/select', {
          table: 'invoices',
          callback: `invoices/${mutationsTypes.SET_INVOICES_LIST}`,
          limit,
          where: { 'contract.id': contractId }
        }, { root: true });
      });
    } else {
      dispatch('db/select', {
        table: 'invoices', callback: `invoices/${mutationsTypes.SET_INVOICES_LIST}`, limit, sortBy: 'ts.term'
      }, { root: true });
      this.$http.get('invoice/list', {
        params: {
          limit
        }
      }).then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'invoices', data: result.data.data }, { root: true });
        }
      }).finally(() => {
        dispatch('db/select', {
          table: 'invoices', callback: `invoices/${mutationsTypes.SET_INVOICES_LIST}`, limit, sortBy: 'ts.term'
        }, { root: true });
      });
    }
  },
  getMoreInvoices ({
    state,
    dispatch,
    rootGetters
  }, contractId) {
    const limit = state.invoiceList.data.length + (rootGetters &&
        rootGetters.config &&
        rootGetters.config.api &&
        rootGetters.config.api.requestLimit
      ? rootGetters.config.api.requestLimit
      : 10);
    const offset = state.invoiceList.data.length ? state.invoiceList.data[state.invoiceList.data.length - 1].ts.emitted : '';
    if (Number.isInteger(contractId)) {
      dispatch('db/select', {
        table: 'invoices', callback: `invoices/${mutationsTypes.SET_INVOICES_LIST}`, limit, where: { 'contract.id': contractId }, sortBy: 'ts.term'
      }, { root: true });
      this.$http.get('contract/invoice/list', {
        params: {
          limit,
          id: contractId,
          offset
        }
      }).then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'invoices', data: result.data.data }, { root: true });
        }
      }).finally(() => {
        dispatch('db/select', {
          table: 'invoices',
          callback: `invoices/${mutationsTypes.SET_INVOICES_LIST}`,
          limit,
          where: { 'contract.id': contractId },
          sortBy: 'ts.term'
        }, { root: true });
      });
    } else {
      dispatch('db/select', {
        table: 'invoices', callback: `invoices/${mutationsTypes.SET_INVOICES_LIST}`, limit, sortBy: 'ts.term'
      }, { root: true });
      this.$http.get('invoice/list', {
        params: {
          limit: rootGetters &&
          rootGetters.config &&
          rootGetters.config.api &&
          rootGetters.config.api.requestLimit
            ? rootGetters.config.api.requestLimit
            : 10,
          offset
        }
      }).then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'invoices', data: result.data.data }, { root: true });
        }
      }).finally(() => {
        dispatch('db/select', {
          table: 'invoices', callback: `invoices/${mutationsTypes.SET_INVOICES_LIST}`, limit, sortBy: 'ts.term'
        }, { root: true });
      });
    }
  }
};

// mutations
const mutations = {
  [mutationsTypes.SET_INVOICE_DETAILS]: (state, invoice) => {
    [state.selectedInvoice.data] = invoice;
    state.selectedInvoice.lastUpdate = new Date();
  },
  [mutationsTypes.SET_INVOICES_LIST]: (state, invoices) => {
    state.invoiceList.data = invoices;
    state.invoiceList.lastUpdate = new Date();
  },
  [mutationsTypes.PUSH_INVOICES_LIST]: (state, invoices) => {
    state.invoiceList.data.push(...invoices);
    state.invoiceList.lastUpdate = new Date();
  },
  [mutationsTypes.SET_OVERDUE_INVOICES]: (state, invoices) => {
    state.overdueInvoices.data = invoices;
    state.overdueInvoices.lastUpdate = new Date();
  },
  [mutationsTypes.SET_OUTSTANDING_INVOICES]: (state, invoices) => {
    state.outstandingInvoices.data = invoices;
    state.outstandingInvoices.lastUpdate = new Date();
  }
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations
};
