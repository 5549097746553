<template>
  <section class="hero is-primary">
    <div class="hero-body px-1">
      <div class="container">
        <div class="columns">
          <div class="column is-3 has-text-centered">
            <h1 class="title">
              {{ $t('movements.myActivity') }}
            </h1>
          </div>
          <div class="column is-9">
            <b-field
              grouped
              group-multiline
            >
              <b-select
                v-model="perPage"
                :disabled="!isPaginated"
              >
                <option value="5">
                  5 par page
                </option>
                <option value="10">
                  10 par page
                </option>
                <option value="15">
                  15 par page
                </option>
                <option value="20">
                  20 par page
                </option>
              </b-select>
              <div class="control is-flex">
                <b-switch v-model="isPaginated">
                  Pagination
                </b-switch>
                <b-field v-if="isMobile">
                  <b-input
                    v-model="filterText"
                    type="text"
                    placeholder="N° Carte/Contrat "
                  />
                </b-field>
              </div>
            </b-field>
            <b-table
              :data="ordTags"
              :paginated="isPaginated"
              :per-page="perPage"
            >
              <b-table-column
                v-slot="props"
                field="id"
                :label="$t('carte')"
                sortable
                searchable
              >
                <router-link
                  :to="{
                    name: 'Toutes mes Cartes',
                    params: {
                      contractId: props.row.contract.id,
                      tagId: props.row.id
                    }
                  }"
                >
                  <span>
                    <b-icon
                      icon="id-card"
                      size="is-small"
                    />
                    <span v-html="$t('tags.tagD', [props.row.id])" />
                  </span>
                </router-link>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="contract.id"
                :label="$t('contrat')"
                sortable
                searchable
              >
                <router-link
                  :to="{
                    name: 'Toutes mes Cartes',
                    params: {
                      contractId: props.row.contract.id
                    }
                  }"
                >
                  <span>
                    <b-icon
                      icon="pen-alt"
                      size="is-small"
                    />
                    <span v-html="$t('tags.contractId', [props.row.contract.id])" />
                  </span>
                </router-link>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="presence"
                :label="$t('welcome.presence')"
                sortable
              >
                <p v-if="props.row.presence">
                  <span
                    class="tag"
                    :class="props.row.presence && props.row.presence.state ?
                      'is-success' : 'is-danger'"
                  >
                    {{ props.row.presence ? props.row.presence.state ?
                      'IN' : 'OUT' : '' }}
                  </span>
                  <br>
                  <small
                    v-if="props.row.presence"
                    class="is-hidden-touch"
                    v
                  >
                    {{ props.row.presence && props.row.presence.state ?
                      props.row.presence.lastPassage.parc.name : $t('tags.missing') }}
                  </small>
                </p>
                <p
                  v-else-if="(props.row.props.terminated ||
                    (props.row.applicable.to !== null &&
                      $moment(props.row.applicable.to).isBefore($moment(new Date()))))"
                >
                  <span class="has-text-warning">
                    {{ $t('tags.canceled') }}
                  </span>
                </p>
                <p v-else-if="props.row.props.prohibited">
                  <span class="has-text-danger">
                    {{ $t('tags.prohibited') }}
                  </span>
                </p>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="parc"
                :label="$t('welcome.parc')"
                sortable
              >
                <span
                  v-if="props.row.presence &&
                    props.row.presence.lastPassage &&
                    props.row.presence.lastPassage.parc &&
                    props.row.presence.lastPassage.parc.name"
                >
                  <b-icon
                    v-if="props.row.presence.state"
                    icon="sign-in-alt"
                    class="has-text-success"
                  />
                  {{ props.row.presence.lastPassage.parc.name }}
                  <b-icon
                    v-if="!props.row.presence.state"
                    icon="sign-out-alt"
                    class="has-text-danger"
                  />
                  <br>
                  <small>{{ props.row.presence.lastPassage.ts | moment("LLL") }}</small>
                </span>
              </b-table-column>
            </b-table>
            <div class="columns is-mobile">
              <div class="column is-6 is-offset-3">
                <router-link
                  :to="{name: 'Mon Activité'}"
                  class="button is-primary is-fullwidth is-outlined is-inverted"
                >
                  <span class="icon">
                    <i class="fas fa-list-ul" />
                  </span>
                  <span>{{ $t('movements.seeMore') }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'MovementsWidget',
  data () {
    return {
      filterText: '',
      isPaginated: true,
      perPage: 5
    };
  },
  computed: {
    ordTags () {
      const filterText = this.filterText.toLowerCase();
      const tags = this.tags.filter((tag) => tag.props &&
        !tag.props.prohibited &&
        !tag.props.terminated &&
        (tag.applicable.to === null ||
          this.$moment(tag.applicable.to).isAfter(this.$moment(new Date()))))
        .sort((a, b) => ((!b.presence ||
          !b.presence.lastPassage ||
          !b.presence.lastPassage.parc ||
          !b.presence.lastPassage.parc.name)
          ? -1
          : a.id - b.id))
        .sort((a, b) => a.id - b.id).map((item) => {
          const newItem = { ...item }; // Create a copy to avoid mutation
          newItem.id = String(newItem.id); // Convert id to string
          if (newItem.contract) {
            newItem.contract.id = String(newItem.contract.id); // Convert contract.id to string
          }
          return newItem;
        })
        .filter((item) => (
          item.id.toLowerCase().includes(filterText) ||
          item.contract.id.toLowerCase().includes(filterText)
        ));
      return tags;
    },
    isMobile () {
      return window.innerWidth < 768; // Adjust the threshold as needed
    },
    ...mapGetters('movements', ['movements']),
    ...mapGetters('tags', ['tags'])
  },
  mounted () {
  }
};
</script>

<style>

</style>
