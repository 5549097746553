var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-success"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6 has-text-centered"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('prefooter.myAccount'))+" ")])]),_c('div',{staticClass:"column is-6"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-item has-text-centered"},[_c('div',[_c('router-link',{attrs:{"to":{
                      name: 'Mes Contrats'
                    }}},[_c('p',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t('prefooter.contracts'))+" ")]),_c('p',{staticClass:"title is-size-1"},[_vm._v(" "+_vm._s(_vm.stats.contracts)+" ")])])],1)]),_c('div',{staticClass:"level-item has-text-centered"},[_c('div',[_c('router-link',{attrs:{"to":{
                      name: 'Toutes mes Cartes'
                    }}},[_c('p',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t('prefooter.tags'))+" ")]),_c('p',{staticClass:"title is-size-1"},[_vm._v(" "+_vm._s(_vm.stats.tags)+" ")])])],1)]),_c('div',{staticClass:"level-item has-text-centered"},[_c('div',[_c('router-link',{attrs:{"to":{
                      name: 'Mon Activité'
                    }}},[_c('p',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t('prefooter.movements'))+" ")]),_c('p',{staticClass:"title is-size-1"},[_vm._v(" "+_vm._s(_vm.stats.movements)+" ")])])],1)])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }