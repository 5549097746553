<template>
  <div>
    <section class="section">
      <div class="container">
        <breadcrumbs />
        <div class="columns">
          <div class="column is-6 has-text-centered">
            <h1 class="title">
              {{ $t('tags.transfer') }}
            </h1>
          </div>
          <div class="column is-6">
            <div class="columns is-mobile">
              <div class="column is-8 is-offset-2">
                <b-field v-if="tags.some(tag => tag.balance.is_cd)">
                  <b-select
                    v-model="selectedFromCard"
                    expanded="expanded"
                    icon="credit-card"
                  >
                    <option
                      selected="selected"
                      value="All"
                    >
                      {{ $t('tags.fromTag') }}
                    </option>
                    <template
                      v-for="tag in tags"
                    >
                      <option

                        v-if="tag && tag.balance && tag.props &&
                          !tag.props.terminated && (tag.applicable.to === null ||
                            $moment(tag.applicable.to).isAfter($moment(new Date()))) &&
                          tag.balance.is_cd && tag.balance.type === 'EUR' &&
                          (tag.balance.value.ab > 0 || tag.balance.value.cd > 0)"
                        :key="tag.id"
                        :value="tag.id"
                      >
                        {{ tag.id }}
                        -
                        {{ tag.holder.title }}
                        {{ tag.holder.firstname }}
                        {{ tag.holder.lastname }}
                        -
                        {{ tag.balance.value.cd }}
                        {{ tag.balance.type }}
                      </option>
                    </template>
                  </b-select>
                </b-field>
                <b-field v-if="tags.some(tag => tag.balance.is_cd)">
                  <b-select
                    v-model="selectedToCard"
                    expanded="expanded"
                    icon="credit-card"
                  >
                    <option
                      selected="selected"
                      value="All"
                    >
                      {{ $t('tags.toTag') }}
                    </option>
                    <template
                      v-for="tag in toTags"
                    >
                      <option
                        v-if="tag && tag.balance && tag.props &&
                          !tag.props.terminated && (tag.applicable.to === null ||
                            $moment(tag.applicable.to).isAfter($moment(new Date()))) &&
                          tag.balance.is_cd && tag.balance.type === 'EUR'"
                        :key="tag.id"
                        :value="tag.id"
                      >
                        {{ tag.id }}
                        -
                        {{ tag.holder.title }}
                        {{ tag.holder.firstname }}
                        {{ tag.holder.lastname }}
                        -
                        {{ tag.balance.value.cd }}
                        {{ tag.balance.type }}
                      </option>
                    </template>
                  </b-select>
                </b-field>
                <div
                  v-else
                  class="notification has-text-centered"
                >
                  {{ $t('tags.noCD') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="tags.some(tag => tag.balance.is_cd)"
      class="hero is-primary"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6 has-text-centered">
              <h1 class="title">
                {{ $t('tags.rechargeAmount') }}
              </h1>
            </div>
            <div class="column is-6">
              <div class="columns is-mobile">
                <div class="column is-8 is-offset-2">
                  <b-field
                    :label="$t('tags.selectOrType')"
                    custom-class="has-text-white"
                  >
                    <b-field>
                      <b-autocomplete
                        v-model.number="tagRechargeAmount"
                        icon="euro-sign"
                        :open-on-focus="true"
                        :data="amountList"
                        expanded
                      />
                      <p class="control">
                        <span class="button is-static">EUR</span>
                      </p>
                    </b-field>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="tags.some(tag => tag.balance.is_cd)"
      class="hero is-light"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns has-text-centered-touch">
            <div class="column is-6 has-text-centered">
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('tags.useCreditTagId') }}
              </span><br>
              <small class="title is-size-3 is-size-4-touch coCardName">
                {{ selectedFromCard }}
                -
                {{ tagHolder.title }}
                {{ tagHolder.firstname }}
                {{ tagHolder.lastname }}</small>
              <br>
            </div>
            <div class="column is-6">
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('tags.resumeAmount') }}
              </span>
              <h2 class="has-text-centered is-size-2">
                <span class="coAmount">{{ tagRechargeAmount }}</span>
                <small>
                  EUR</small>
              </h2>
              <hr>
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('tags.resumeNewSold') }}
              </span>
              <h2 class="has-text-centered is-size-2">
                <span
                  class="coCredit"
                  :class="(tagBalance - tagRechargeAmount < 0) ? 'has-text-danger' : ''"
                >{{ $n(tagBalance - tagRechargeAmount, 'currency') }}</span>
              </h2>
              <small
                v-if="tagBalance - tagRechargeAmount < 0"
                class="has-text-danger"
              > {{ $t("tags.noNegativeFrom") }}</small>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="tags.some(tag => tag.balance.is_cd)"
      class="hero is-primary"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns has-text-centered-touch">
            <div class="column is-6 has-text-centered">
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('tags.addCreditTagId') }}
              </span><br>
              <small class="title is-size-3 is-size-4-touch coCardName">
                {{ selectedToCard }}
                -
                {{ tagHolder.title }}
                {{ tagHolder.firstname }}
                {{ tagHolder.lastname }}</small>
              <br>
            </div>
            <div class="column is-6">
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('tags.resumeAmount') }}
              </span>
              <h2 class="has-text-centered is-size-2">
                <span class="coAmount">{{ tagRechargeAmount }}</span>
                <small>
                  EUR</small>
              </h2>
              <hr>
              <span class="subtitle is-size-4 is-size-5-touch">
                {{ $t('tags.resumeNewSold') }}
              </span>
              <h2 class="has-text-centered is-size-2">
                <span
                  class="coCredit"
                  :class="(tagBalanceTo + tagRechargeAmount > 150) ? 'has-text-warning' : ''"
                >{{ $n(tagRechargeAmount + tagBalanceTo, 'currency') }}</span>
              </h2>
              <small
                v-if="tagBalanceTo+tagRechargeAmount > 150"
                class="has-text-warning"
              >{{ $t('tags.cantTransferOverMaxBalance') }}</small>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="tags.some(tag => tag.balance.is_cd)"
      class="hero is-dark"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-half content">
              <div class="checkbox">
                <div class="field">
                  <b-checkbox v-model="cgv">
                    {{ $t('tags.accept') }}
                    <a
                      class="is-underlined"
                      href="https://www.parkings.mc/conditions-utilisation.html"
                    >{{ $t('tags.CGV') }}</a>
                    {{ $t('tags.ofMonacoParking') }}
                  </b-checkbox>
                </div>
              </div>
              <button
                class="button is-medium is-fullwidth is-success"
                :disabled="tagBalanceTo + tagRechargeAmount > 150 ||
                  tagBalance === 0 ||
                  tagBalance - tagRechargeAmount < 0 ||
                  !cgv"
                @click="sendTransferOrder()"
              >
                <span class="icon">
                  <i class="fa fa-fw fa-exchange-alt" />
                </span>
                <span>{{ $t('tags.completeTransfer') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'Transfer',
  data () {
    return {
      selectedFromCard: 'All',
      selectedToCard: 'All',
      tagRechargeAmount: 10,
      cgv: false,
      amountList: [10, 20, 30, 50, 100]
    };
  },

  computed: {
    toTags () {
      return this.tags.filter((tag) => tag.id !== this.selectedFromCard);
    },
    tagHolder () {
      return (this.tags.find((tag) => tag.id === this.selectedFromCard) || this.tags[0])
        ? (this.tags.find((tag) => tag.id === this.selectedFromCard) || this.tags[0]).holder
        : {};
    },
    tagBalance () {
      return (this.tags.find((tag) => tag.id === this.selectedFromCard) ||
        this.tags[0])
        ? parseFloat((this.tags.find((tag) => tag.id === this.selectedFromCard) ||
        this.tags[0]).balance.value.cd) || 0
        : 0;
    },
    tagBalanceTo () {
      return (this.tags.find((tag) => tag.id === this.selectedToCard) ||
        this.tags[0])
        ? parseFloat((this.tags.find((tag) => tag.id === this.selectedToCard) ||
        this.tags[0]).balance.value.cd) || 0
        : 0;
    },
    ...mapGetters('tags', ['tags'])
  },
  watch: {
    selectedFromCard (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => {
          this.tagRechargeAmount = Number(this.tagBalance);
        });
      }
    }
  },
  mounted () {
    this.$store.dispatch('tags/getTags');
  },
  methods: {
    sendTransferOrder () {
      if (this.cgv) {
        this.$buefy.dialog.confirm({
          type: 'is-success',
          confirmText: this.$t('tags.confirmTransfer'),
          message: this.$t('tags.confirmTransferMessage',
            [this.$n(this.tagRechargeAmount, 'currency'), this.selectedFromCard, this.selectedToCard]),
          onConfirm: () => this.$http.post('tag/transfer', {
            tagFrom: this.selectedFromCard,
            tagTo: this.selectedToCard,
            amount: this.tagRechargeAmount
          }).then((result) => {
            this.$store.dispatch('tags/getTags');
            if (result.data.success) {
              this.$buefy.notification.open({
                duration: 5000,
                message: this.$t('tags.transferOk'),
                type: 'is-success'
              });
              this.$router.push({
                name: 'Mes Opérations'
              });
            } else {
              this.$buefy.snackbar.open({
                indefinite: true,
                message: `${this.$t('tags.transferError')}`,
                type: 'is-warning',
                actionText: this.$t('tags.retry'),

                onAction: this.sendTransferOrder
              });
            }
          }).catch((error) => {
            this.$buefy.snackbar.open({
              indefinite: true,
              message: `${this.$t('tags.transferError', error)}`,
              type: 'is-warning',
              actionText: this.$t('tags.retry'),

              onAction: this.sendTransferOrder
            });
          })
        });
      } else {
        this.$buefy.notification.open({
          duration: 5000,
          message: this.$t('tags.pleaseAcceptCgv'),
          type: 'is-warning'
        });
        this.$router.replace({
          name: 'Transferer mon Solde'
        });
      }
    }
  }

};
</script>

<style></style>
