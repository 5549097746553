<template>
  <section class="section">
    <div class="container">
      <breadcrumbs />
      <div class="columns">
        <div class="column is-6 has-text-centered">
          <h1 class="title">
            {{ $t('transactions.myTransactions') }}
          </h1>
        </div>
        <div class="column is-6">
          <div class="content has-text-centered-touch">
            <b-table
              :data="transactions"
              striped
            >
              <b-table-column
                v-slot="props"
                field="device"
                :label="$t('transactions.device')"
              >
                {{ props.row.tag ? $t('transactions.tagNumber')+' '+ props.row.tag :
                  $t('transactions.invoiceNumber')+' '+ props.row.invoice }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="orderedAt"
                :label="$t('transactions.orderedAt')"
              >
                {{ props.row.orderedAt | moment("LLL") }}
              </b-table-column>

              <!-- <b-table-column
                v-slot="props" field="paymentRegisteredAt"
                :label="$t('transactions.paymentRegisteredAt')">
                    {{ props.row.paymentRegisteredAt | moment("LLL") }}
                </b-table-column> -->

              <b-table-column
                v-slot="props"
                field="paymentUpdatedAt"
                :label="$t('transactions.paymentUpdatedAt')"
              >
                {{ props.row.paymentUpdatedAt | moment("LLL") }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="amountAti"
                :label="$t('transactions.amountAti')"
              >
                {{ props.row.amountAti }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="creditCardDetails"
                :label="$t('transactions.creditCardDetails')"
              >
                <strong>{{ props.row.creditCardBrand }}</strong>
                <strong>{{ props.row.creditCardNumber }}</strong>
                <strong>{{ props.row.creditCardValidity }}</strong>
                <br>
              </b-table-column>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="empty-set"
                        size="is-large"
                      />
                    </p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Transactions',
  data () {
    return {
    };
  },
  computed: {
    ...mapGetters('user', ['transactions'])
  },
  mounted () {
    this.$store.dispatch('user/getTransactions');
  },
  methods: {
  }
};
</script>

<style>

</style>
