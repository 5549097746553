<template>
  <div>
    <section class="section">
      <div class="container">
        <breadcrumbs />
        <div class="columns">
          <div class="column is-6">
            <h1 class="title has-text-centered">
              {{ $t('movements.myActivity') }}
            </h1>
          </div>
          <div class="column is-6">
            <div class="columns is-mobile">
              <div class="column is-8 is-offset-2">
                <b-field>
                  <b-select
                    v-model="selectedCard"
                    expanded
                    icon="credit-card"
                  >
                    <option
                      selected="selected"
                      value="All"
                    >
                      {{ $t('movements.allMyCards') }}
                    </option>
                    <template
                      v-for="tag in tags"
                    >
                      <option
                        v-if="!tag.props.terminated &&
                          (tag.applicable.to === null ||
                            $moment(tag.applicable.to).isAfter($moment(new Date())))"
                        :key="tag.id"
                        :value="tag.id"
                      >
                        {{ tag.id }} - {{ tag.holder.title }} {{ tag.holder.firstname }}
                        {{ tag.holder.lastname }}
                      </option>
                    </template>
                  </b-select>
                </b-field>
              </div>
            </div>
            <template v-if="movements.length">
              <table class="table is-striped is-fullwidth">
                <tbody>
                  <tr
                    v-for="movement in filteredMovements"
                    :key="movement.id"
                  >
                    <td class="has-text-weight-light">
                      <b-icon
                        type="is-grey"
                        size="is-small"
                        icon="credit-card"
                      />
                      {{ movement.tag }}
                      <br>
                      {{ movement.holder.title | recapitalize }}
                      {{ movement.holder.firstname | recapitalize }}
                      {{ movement.holder.lastname | recapitalize }}
                    </td>
                    <td class="has-text-centered">
                      {{ movement.parc.name }}
                    </td>
                    <td class="has-text-weight-light has-text-centered">
                      <small>
                        <b-icon
                          icon="sign-in-alt"
                          type="is-success"
                          size="is-small"
                        />
                        {{ movement.ts_in | moment("LLL") }}
                      </small>
                      <br>
                      <small v-if="movement.type==='OUT'">
                        <b-icon
                          icon="sign-out-alt"
                          type="is-danger"
                          size="is-small"
                        />
                        {{ movement.ts | moment("LLL") }}
                      </small>
                      <br>
                      <small
                        v-if="movement.type==='OUT'"
                        class="has-text-centered-right has-text-grey"
                      >
                        <b-icon
                          icon="clock"
                          size="is-small"
                        />
                        <strong v-if="movement.duration.ms">
                          {{ movement.duration.ms | duration("format") }}
                        </strong>
                      </small>
                    </td>
                    <td class="has-text-centered">
                      <b-tooltip
                        v-if="movement.recognition.plate"
                        :label="movement.recognition.concordance ?
                          $t('movements.concordance') : $t('movements.noConcordance')"
                        :type="movement.recognition.concordance ? 'is-success' : 'is-danger'"
                      >
                        <button class="button is-small">
                          <b-icon icon="car" />
                          <span>
                            {{ movement.recognition.plate }}
                          </span>
                          <b-icon
                            v-if="movement.recognition.concordance"
                            icon="equals"
                            type="is-success"
                          />
                          <b-icon
                            v-else
                            icon="not-equal"
                            type="is-danger"
                          />
                        </button>
                      </b-tooltip>
                      <br>
                      <small
                        v-if="movement.transaction.amount"
                        class="has-text-centered"
                      >{{ $n(movement.transaction.amount, 'currency') }}</small>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="columns is-mobile">
                <div class="column is-6 is-offset-3">
                  <button
                    class="button is-primary is-fullwidth"
                    @click="loadMore"
                  >
                    <span class="icon">
                      <i class="fas fa-list-ul" />
                    </span>
                    <span>{{ $t('movements.showMore') }}</span>
                  </button>
                </div>
              </div>
            </template>
            <div
              v-else
              class="title"
            >
              {{ $t('movements.none') }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ActivityList',
  props: {
    id: {
      type: [Number],
      default: null
    }
  },
  data () {
    return {
      selectedCard: this.id || 'All',
      selectedCardIsCd: false
    };
  },
  computed: {
    filteredMovements () {
      return this.movements.filter((movement) => {
        const mTag = this.tags.find((tag) => tag.id === movement.tag);
        return !mTag || !mTag.balance || !mTag.balance.is_cd || movement.ts === movement.ts_in ||
        (mTag && mTag.balance && mTag.balance.is_cd && this.$moment.duration(
          this.$moment(movement.ts).diff(this.$moment(movement.ts_in))
        ).asHours() > 1 && movement.transaction.amount);
      });
    },
    ...mapGetters('tags', ['tags']),
    ...mapGetters('movements', ['movements'])
  },
  watch: {
    selectedCard (val) {
      if (Number.isInteger(val)) {
        const myTag = this.tags.find((tag) => tag.balance &&
          tag.balance.is_cd);
        this.selectedCardIsCd = myTag ? myTag.balance.is_cd : false;
        this.$router.push({ name: 'Mon Activité', params: { id: val } });
      } else {
        this.$router.push({ name: 'Mon Activité' });
      }
    },
    $props: {
      handler (val, oldVal) {
        this.selectedCard = val.id;
        this.$store.dispatch('movements/getMovements', val.id);
      },
      deep: true
    }
  },
  mounted () {
    this.$store.dispatch('tags/getTags');
    this.$store.dispatch('movements/getMovements', this.id || null);
  },
  methods: {
    loadMore () {
      this.$store.dispatch('movements/getMoreMovements', this.selectedCard);
    }
  }
};
</script>
