<template>
  <section class="section">
    <div class="container">
      <breadcrumbs />
      <div class="columns">
        <div class="column is-6 has-text-centered">
          <h1 class="title">
            {{ $t('alerts.myAlerts') }}
          </h1>
        </div>
        <div
          v-if="tags.some(tag => tag.balance.is_cd)"
          class="column is-6"
        >
          <div class="content">
            <p>{{ $t('alerts.willReceiveAlerts') }}</p>
            <div
              v-if="user && user.notifPreferences === 'cellular'"
              class="box has-text-centered"
            >
              <div class="control has-icons-left has-icons-right">
                <b-input
                  v-mask="'(+##) # ## ## ## ##'"
                  placeholder="X XX XX XX XX"
                  :value="user ? user.cellular : ''"
                  readonly="readonly"
                  icon="mobile-alt"
                />
                <span class="icon is-small is-left">
                  <i class="fa fa-mobile-alt" />
                </span>
                <span class="icon is-small is-right">
                  <i class="fa fa-check" />
                </span>
              </div>
              <small>{{ $t('alerts.incorrectCellular') }}</small>
              <br>
              <router-link
                :to="{name: 'Mon Compte'}"
                class="is-small"
              >
                <small>{{ $t('alerts.updateCellular') }}</small>
              </router-link>
            </div>
            <div
              v-else
              class="box has-text-centered"
            >
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  placeholder="Email"
                  :value="user ? user.email : ''"
                  readonly="readonly"
                  type="email"
                >
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope" />
                </span>
                <span class="icon is-small is-right">
                  <i class="fa fa-check" />
                </span>
              </div>
              <small>{{ $t('alerts.incorrectMail') }}</small>
              <br>
              <router-link
                :to="{name: 'Mon Compte'}"
                class="is-small"
              >
                <small>{{ $t('alerts.updateMail') }}</small>
              </router-link>
            </div>
          </div>
          <div class="content has-text-centered-touch">
            <h2 class="title is-size-3">
              {{ $t('alerts.wishReceive') }}
            </h2>
            <div class="box">
              <template v-for="tag in myAlerts">
                <b-field
                  :key="`tag${tag.id}`"
                  grouped
                >
                  <i
                    :id="`tag${tag.id}`"
                    class="similiAnchor"
                  />
                  <b-switch
                    :key="tag.id"
                    :value="true"
                    type="is-success"
                    @input="cancelAlert($event, tag)"
                  />
                  <b-field
                    :addons="false"
                    expanded
                  >
                    <span class="control-label">
                      {{ $t('alerts.tagId') }}
                      <strong>{{ tag.id }}</strong>
                      {{ $t('alerts.ownedBy') }}
                      {{ tag.holder.title }}
                      {{ tag.holder.firstname| recapitalize }}
                      {{ tag.holder.lastname | recapitalize }}
                      {{ $t('alerts.creditLessThan') }}
                    </span>
                    <b-field>
                      <b-input
                        v-model="tag.balance.threshold"
                        size="is-medium"
                        type="number"
                        expanded
                      />
                      <p class="control">
                        <span class="button is-static is-medium">
                          {{ tag.balance.type === "MIN" ? "H" : "EUR" }}
                        </span>
                      </p>
                    </b-field>
                  </b-field>
                </b-field>
                <hr
                  :key="`hr${tag.id}`"
                >
              </template>
              <template v-for="tag in settedAlerts">
                <b-field
                  :key="`tag${tag.id}`"
                  grouped
                >
                  <i
                    :id="`tag${tag.id}`"
                    class="similiAnchor"
                  />
                  <b-switch
                    :key="tag.id"
                    :value="true"
                    type="is-success"
                    @input="removeAlert($event, tag)"
                  />
                  <b-field
                    :addons="false"
                    expanded
                  >
                    <span class="control-label">
                      {{ $t('alerts.tagId') }}
                      <strong>{{ tag.id }}</strong>
                      {{ $t('alerts.ownedBy') }}
                      {{ tag.holder.title }}
                      {{ tag.holder.firstname | recapitalize }}
                      {{ tag.holder.lastname | recapitalize }}
                      {{ $t('alerts.creditLessThan') }}
                    </span>
                    <b-field>
                      <b-input
                        v-model="tag.balance.threshold"
                        size="is-medium"
                        type="number"
                        expanded
                      />
                      <p class="control">
                        <span class="button is-static is-medium">
                          {{ tag.balance.type === "MIN" ? "H" : "EUR" }}
                        </span>
                      </p>
                    </b-field>
                  </b-field>
                </b-field>
                <hr
                  :key="`hr${tag.id}`"
                >
              </template>
              <template v-for="tag in noAlerts">
                <b-field
                  :key="`tag${tag.id}`"
                  grouped
                >
                  <i
                    :id="`tag${tag.id}`"
                    class="similiAnchor"
                  />
                  <b-switch
                    :key="tag.id"
                    :value="false"
                    type="is-success"
                    @input="addAlert($event, tag)"
                  />
                  <b-field
                    :addons="false"
                    expanded
                  >
                    <span class="control-label">
                      {{ $t('alerts.tagId') }}
                      <strong>{{ tag.id }}</strong>
                      {{ $t('alerts.ownedBy') }}
                      {{ tag.holder.title }}
                      {{ tag.holder.firstname | recapitalize }}
                      {{ tag.holder.lastname | recapitalize }}
                      {{ $t('alerts.creditLessThan') }}
                    </span>
                    <b-field>
                      <b-input
                        size="is-medium"
                        disabled
                        type="number"
                        expanded
                      />
                      <p class="control">
                        <span class="button is-static is-medium">
                          {{ tag.balance.type === "MIN" ? "H" : "EUR" }}
                        </span>
                      </p>
                    </b-field>
                  </b-field>
                </b-field>
                <hr
                  :key="`hr${tag.id}`"
                >
              </template>
              <template v-for="tag in cancelledAlerts">
                <b-field
                  :key="`tag${tag.id}`"
                  grouped
                >
                  <i
                    :id="`tag${tag.id}`"
                    class="similiAnchor"
                  />
                  <b-switch
                    :key="tag.id"
                    :value="false"
                    type="is-success"
                    @input="emptyAlert($event, tag)"
                  />
                  <b-field
                    :addons="false"
                    expanded
                  >
                    <span class="control-label">
                      {{ $t('alerts.tagId') }}
                      <strong>{{ tag.id }}</strong>
                      {{ $t('alerts.ownedBy') }}
                      {{ tag.holder.title }}
                      {{ tag.holder.firstname | recapitalize }}
                      {{ tag.holder.lastname | recapitalize }}
                      {{ $t('alerts.creditLessThan') }}
                    </span>
                    <b-field>
                      <b-input
                        size="is-medium"
                        disabled
                        type="number"
                        expanded
                      />
                      <p class="control">
                        <span class="button is-static is-medium">
                          {{ tag.balance.type === "MIN" ? "H" : "EUR" }}
                        </span>
                      </p>
                    </b-field>
                  </b-field>
                </b-field>
                <hr
                  :key="`tag${tag.id}`"
                >
              </template>
            </div>
            <button
              class="button is-success is-medium is-fullwidth"
              @click="setAlerts"
            >
              <span class="icon">
                <i class="fa fa-check" />
              </span><span>{{ $t('alerts.save') }}</span>
            </button>
          </div>
        </div>
        <div
          v-else
          class="column is-6"
        >
          <div class="columns is-mobile">
            <div class="column is-8 is-offset-2">
              <div class="notification has-text-centered">
                {{ $t('tags.noCD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'Alerts',
  data () {
    return {
      cancelledAlerts: [],
      settedAlerts: [],
      myAlerts: [],
      noAlerts: []
    };
  },
  computed: {
    ...mapGetters('tags', ['tags']),
    ...mapGetters('tags', ['alerts']),
    ...mapGetters('user', ['user'])
  },
  mounted () {
    this.$store.dispatch('tags/getTags').then(() => {
      this.$store.dispatch('tags/getAlerts').then(() => {
        this.myAlerts = this.alerts.map((alert) => ({
          id: alert.tag,
          holder: this.tags.find((tag) => tag.id === alert.tag).holder,
          balance: {
            ...this.tags.find((tag) => tag.id === alert.tag).balance, threshold: alert.threshold
          }
        })).sort((a, b) => a.id - b.id);
        this.noAlerts = [...this.tags]
          .filter((tag) => tag && tag.balance && tag.props &&
          !tag.props.terminated &&
          (tag.applicable.to === null ||
            this.$moment(tag.applicable.to).isAfter(this.$moment(new Date()))) &&
          tag.balance.is_cd &&
          !this.myAlerts.find((alert) => alert.id === tag.id))
          .sort((a, b) => a.id - b.id);
      }).then(() => {
        this.$store.dispatch('user/getUser');
      });
    });
  },
  methods: {
    addAlert (event, tag) {
      this.settedAlerts.push(this.noAlerts.find((alert) => alert.id === tag.id));
      this.settedAlerts.sort((a, b) => a.id - b.id);
      this.noAlerts = this.noAlerts.filter((alert) => alert.id !== tag.id);
      this.noAlerts.sort((a, b) => a.id - b.id);
    },
    removeAlert (event, tag) {
      this.noAlerts.push(this.settedAlerts.find((alert) => alert.id === tag.id));
      this.noAlerts.sort((a, b) => a.id - b.id);
      this.settedAlerts = this.settedAlerts.filter((alert) => alert.id !== tag.id);
      this.settedAlerts.sort((a, b) => a.id - b.id);
    },
    cancelAlert (event, tag) {
      this.cancelledAlerts.push(this.myAlerts.find((alert) => alert.id === tag.id));
      this.cancelledAlerts.sort((a, b) => a.id - b.id);
      this.myAlerts = this.myAlerts.filter((alert) => alert.id !== tag.id);
      this.myAlerts.sort((a, b) => a.id - b.id);
    },
    emptyAlert (event, tag) {
      this.myAlerts.push(this.cancelledAlerts.find((alert) => alert.id === tag.id));
      this.myAlerts.sort((a, b) => a.id - b.id);
      this.cancelledAlerts = this.cancelledAlerts.filter((alert) => alert.id !== tag.id);
      this.cancelledAlerts.sort((a, b) => a.id - b.id);
    },
    setAlerts () {
      if (this.cancelledAlerts.length > 0) {
        const cancelledAlertsPromiseSet = [];
        for (let i = 0, j = this.cancelledAlerts.length; i < j; i += 1) {
          cancelledAlertsPromiseSet.push(
            this.$http.post('tag/alert/unset', {
              tag: this.cancelledAlerts[i].id
            })
          );
        }
        Promise.all(cancelledAlertsPromiseSet).then((responses) => {
          if (responses.length && responses.every((response) => response.data.success)) {
            this.$buefy.notification.open({

              duration: 30000,
              message: this.$t('alerts.successCancelledAlert', [this.cancelledAlerts.map((alert) => alert.id).join(', ')]),
              type: 'is-success'
            });
            this.noAlerts = [...this.noAlerts, ...this.cancelledAlerts].sort((a, b) => a.id - b.id);
            this.cancelledAlerts = [];
          }
        }).catch((errors) => {
          this.$buefy.snackbar.open({
            indefinite: true,
            message: `${this.$t('alerts.errorCancelledAlert', errors)}`,
            type: 'is-warning',
            actionText: this.$t('adminChanges.retry'),

            onAction: this.setAlerts
          });
        });
      }

      const settedAlerts = [...this.settedAlerts, ...this.myAlerts];
      if (settedAlerts.length > 0) {
        const settedAlertsPromiseSet = [];
        for (let i = 0, j = settedAlerts.length; i < j; i += 1) {
          if (settedAlerts[i].balance.threshold) {
            settedAlertsPromiseSet.push(
              this.$http.post('tag/alert/set', {
                tag: settedAlerts[i].id,
                threshold: settedAlerts[i].balance.threshold
              })
            );
          }
        }
        Promise.all(settedAlertsPromiseSet).then((responses) => {
          if (responses.length && responses.every((response) => response.data.success)) {
            this.$buefy.notification.open({

              duration: 30000,
              message: this.$t('alerts.successSettedAlert', [settedAlerts.map((alert) => alert.id).join(', ')]),
              type: 'is-success'
            });
            this.myAlerts = settedAlerts.sort((a, b) => a.id - b.id);
            this.settedAlerts = [];
          }
        }).catch((errors) => {
          this.$buefy.snackbar.open({
            indefinite: true,
            message: `${this.$t('alerts.errorSettedAlert', errors)}`,
            type: 'is-warning',
            actionText: this.$t('adminChanges.retry'),

            onAction: this.setAlerts
          });
        });
      }
    }
  }
};
</script>

<style lang="sass">
.similiAnchor
  display: block
  position: relative
  top: -3.25rem
  visibility: hidden
</style>
