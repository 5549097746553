<template>
  <form
    class="login-form box"
    @submit.prevent="submitForm"
  >
    <p>{{ $t('signup.password.choosePassword') }}</p>
    <b-field
      :label="$t('signup.password.enterPassword')"
      :type="(message.length === 0 && entropy >= 80) ? '' : 'is-danger'"
    >
      <b-input
        v-model="password"
        type="password"
        placeholder="XXXXXXXXX"
        icon="key fa-xs"
      />
    </b-field>
    <b-field
      :label="$t('signup.password.entropy')+entropy+'/80'"
      :message="message"
      :type="message.length===0 ? 'is-success' : '' "
    >
      <progress
        :class="'progress is-small '+((entropy<20) ? 'is-danger ' : ((entropy<40)?
          'is-warning ' : ((entropy<80)? 'is-warning ' : 'is-success ')))"
        :value="entropy"
        max="80"
      />
    </b-field>
    <b-field
      :message="messageV"
      type="is-success"
      style="text-decoration: line-through;"
    />
    <b-field
      :label="$t('signup.password.confirmPassword')"
      :type="(password===passwordCheck) && entropy >= 80 ? '' : 'is-danger'"
    >
      <b-input
        v-model="passwordCheck"
        type="password"
        placeholder="XXXXXXXXX"
        icon="key fa-xs"
      />
    </b-field>
    <b-field
      grouped
      group-multiline
      expanded
      position="is-centered"
    >
      <button
        :disabled="password === '' || password !== passwordCheck ||
          message.length > 0 || messageV.length < 5"
        class="button is-success is-pulled-left"
      >
        {{ $t('signup.password.validatePassword') }}
      </button>
    </b-field>
  </form>
</template>

<script>
import Entropy from 'string-entropy';
import TestPass from '@/tools/test-password-for';

const LOWERCASE_ALPHA = 'abcdefghijklmnopqrstuvwxyz';
const UPPERCASE_ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const DIGITS = '0123456789';
const PUNCT1 = '!@#$%^&*()';
const PUNCT2 = '~`-_=+[]{}\\|;:\'",.<>?/';

export default {
  name: 'ResetPassword',
  data () {
    return {
      password: '',
      passwordCheck: '',
      step: false,
      message: [],
      messageV: []
    };
  },
  computed: {
    entropy () {
      return Entropy(this.password);
    }
  },
  watch: {
    password (newPass) {
      this.message = [];
      this.messageV = [];
      if (this.password.length < 9) {
        this.message.push(this.$t('signup.password.invalid.length'));
      } else {
        this.messageV.push(this.$t('signup.password.valid.length'));
      }
      if (!TestPass(this.password, LOWERCASE_ALPHA)) {
        this.message.push(this.$t('signup.password.invalid.lowercase'));
      } else {
        this.messageV.push(this.$t('signup.password.valid.lowercase'));
      }
      if (!TestPass(this.password, UPPERCASE_ALPHA)) {
        this.message.push(this.$t('signup.password.invalid.uppercase'));
      } else {
        this.messageV.push(this.$t('signup.password.valid.uppercase'));
      }
      if (!TestPass(this.password, DIGITS)) {
        this.message.push(this.$t('signup.password.invalid.digit'));
      } else {
        this.messageV.push(this.$t('signup.password.valid.digit'));
      }
      if (!TestPass(this.password, PUNCT1 + PUNCT2)) {
        this.message.push(this.$t('signup.password.invalid.special'));
      } else {
        this.messageV.push(this.$t('signup.password.valid.special'));
      }
    }
  },
  mounted () {},
  methods: {
    submitForm () {
      if (this.password.length > 9 &&
        this.passwordCheck.length > 9 &&
        this.password === this.passwordCheck &&
        this.messageV.length === 5 &&
        this.message.length === 0) {
        this.sendCode();
      }
    },
    sendCode () {
      if (this.password === this.passwordCheck) {
        this.$http.post('signup/password/reset', {
          password: this.password,
          code: this.secretCode
        }).then(() => {
          this.$router.push({
            name: 'signupRouteur'
          });
        });
      } else {
        this.$buefy.notification.open({
          duration: 5000,
          message: 'Something\'s not good',
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
    }
  }
};
</script>

<style>

</style>
