<template>
  <div class="hero">
    Good Bye
  </div>
</template>

<script>

export default {
  name: 'Logout',
  mounted () {
    const vm = this;
    this.$store.dispatch('auth/setRememberMe', false);
    this.$store.dispatch('db/clearIndexedDb');
    this.$http.get('logout').then(() => {
      vm.$router.push({
        name: 'Login'
      });
    });
  }
};
</script>
<style>
.g-recaptcha > div {
  margin: auto;
}
</style>
