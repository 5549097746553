const mutationsTypes = {
  SET_BEARER: 'SET_BEARER',
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING'
};

export default {
  namespaced: true,
  state: {
    token: '',
    isLoading: false
  },
  getters: {
    bearer: (state) => state.token,
    isLoading: (state) => state.isLoading > 0
  },
  actions: {
    setBearer: ({ commit }, token) => commit(mutationsTypes.SET_BEARER, token),
    startLoading ({ commit }) {
      commit(mutationsTypes.START_LOADING);
    },
    stopLoading ({ commit }) {
      commit(mutationsTypes.STOP_LOADING);
    }
  },
  mutations: {
    [mutationsTypes.SET_BEARER] (state, token) {
      state.token = token;
    },
    [mutationsTypes.START_LOADING] (state) {
      state.isLoading += 1;
    },
    [mutationsTypes.STOP_LOADING] (state) {
      state.isLoading -= 1;
    }
  }
};
