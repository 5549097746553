<template>
  <nav
    class="breadcrumb has-arrow-separator"
    aria-label="breadcrumbs"
  >
    <ul>
      <li>
        <router-link :to="homeLink.location">
          <b-icon icon="map-signs" />{{ homeLink.location.name }}
        </router-link>
      </li>
      <template
        v-for="layer in matched"
      >
        <li
          v-if="!layer.meta.isLayout && layer.name !==homeLink.resolved.name"
          :key="layer.name"
        >
          <router-link
            :to="{name: layer.name, params: layer.params}"
          >
            {{ layer.name }}
          </router-link>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  components: {},
  data () {
    return {
      homeLink: this.$router.resolve({
        name: 'Accueil'
      })
    };
  },
  computed: {
    matched () {
      return this.$route.matched;
    }
  }
};
</script>

<style>

</style>
