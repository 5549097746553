const mutationsTypes = {
  SET_MOVEMENTS_LIST: 'SET_MOVEMENTS_LIST',
  PUSH_MOVEMENTS_LIST: 'PUSH_MOVEMENTS_LIST'
};

// initial state
const initState = {
  movementList: {
    data: [],
    lastUpdate: null
  }
};

// getters
const getters = {
  movements: (state) => state.movementList.data
};

// actions
const actions = {
  registerTable: {
    root: true,
    handler ({ dispatch }) {
      dispatch('db/addTable', { movements: '&ts, tag' }, { root: true });
    }
  },
  reloadMovements ({
    state,
    dispatch,
    rootGetters
  }, tagId) {
    const limit = state.movementList.data.length + rootGetters &&
      rootGetters.config &&
      rootGetters.config.api &&
      rootGetters.config.api.requestLimit
      ? rootGetters.config.api.requestLimit
      : 10;
    if (Number.isInteger(tagId)) {
      this.$http.get('tag/movements', {
        params: {
          id: tagId
        }
      }).then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'movements', data: result.data.data }, { root: true });
        }
      }).finally(() => {
        dispatch('db/select', {
          table: 'movements', callback: `movements/${mutationsTypes.SET_MOVEMENTS_LIST}`, limit, where: { tag: tagId }, attribute: 'movements'
        }, { root: true });
      });
    } else {
      this.$http.get('movement/list').then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'movements', data: result.data.data }, { root: true });
        }
      }).finally(() => dispatch('db/select', { table: 'movements', callback: `movements/${mutationsTypes.SET_MOVEMENTS_LIST}`, limit }, { root: true }));
    }
  },
  getMovements ({
    state,
    dispatch,
    rootGetters
  }, tagId) {
    const limit = rootGetters &&
      rootGetters.config &&
      rootGetters.config.api &&
      rootGetters.config.api.requestLimit
      ? rootGetters.config.api.requestLimit
      : 10;
    if (Number.isInteger(tagId)) {
      dispatch('db/select', {
        table: 'movements', callback: `movements/${mutationsTypes.SET_MOVEMENTS_LIST}`, limit, where: { tag: tagId }
      }, { root: true });
      this.$http.get('tag/movements', {
        params: {
          id: tagId
        }
      }).then((result) => {
        if (result && result.data && result.data.data) { dispatch('db/add', { table: 'movements', data: result.data.data }, { root: true }); }
      })
        .finally(() => {
          dispatch('db/select', {
            table: 'movements', callback: `movements/${mutationsTypes.SET_MOVEMENTS_LIST}`, limit, where: { tag: tagId }
          }, { root: true });
        });
      // }
    } else {
      dispatch('db/select', { table: 'movements', callback: `movements/${mutationsTypes.SET_MOVEMENTS_LIST}`, limit }, { root: true });
      this.$http.get('movement/list').then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'movements', data: result.data.data }, { root: true });
        }
      })
        .finally(() => dispatch('db/select', { table: 'movements', callback: `movements/${mutationsTypes.SET_MOVEMENTS_LIST}`, limit }, { root: true }));
    }
  },
  getMoreMovements ({
    state,
    // commit,
    rootGetters,
    dispatch
  }, tagId) {
    const limit = state.movementList.data.length + (rootGetters &&
  rootGetters.config &&
  rootGetters.config.api &&
  rootGetters.config.api.requestLimit
      ? rootGetters.config.api.requestLimit
      : 10);
    const refresh = rootGetters &&
        rootGetters.config &&
        rootGetters.config.api &&
        rootGetters.config.api.refreshEvery
      ? rootGetters.config.api.refreshEvery
      : 5 * 60 * 1000;
    if (Number.isInteger(tagId)) {
      if (new Date() - state.movementList.lastUpdate > refresh) {
        dispatch('reloadMovements', tagId);
      } else {
        const offset = state.movementList.data[state.movementList.data.length - 1].ts;
        this.$http.get('tag/movements', {
          params: {
            id: tagId,
            offset,
            limit: rootGetters &&
          rootGetters.config &&
          rootGetters.config.api &&
          rootGetters.config.api.requestLimit
              ? rootGetters.config.api.requestLimit
              : 10
          }
        }).then((result) => {
          if (result && result.data && result.data.data) {
            dispatch('db/add', { table: 'movements', data: result.data.data }, { root: true });
          }
        })
          .finally(() => {
            dispatch('db/select', {
              table: 'movements', callback: `movements/${mutationsTypes.SET_MOVEMENTS_LIST}`, limit, where: { tag: tagId }
            }, { root: true });
          });
      }
    } else if (new Date() - state.movementList.lastUpdate > refresh) {
      dispatch('reloadMovements');
    } else {
      const offset = state.movementList.data[state.movementList.data.length - 1].ts;
      this.$http.get('movement/list', {
        params: {
          offset,
          limit: rootGetters &&
        rootGetters.config &&
        rootGetters.config.api &&
        rootGetters.config.api.requestLimit
            ? rootGetters.config.api.requestLimit
            : 10
        }
      }).then((result) => {
        if (result && result.data && result.data.data) {
          dispatch('db/add', { table: 'movements', data: result.data.data }, { root: true });
        }
      })
        .finally(() => dispatch('db/select', { table: 'movements', callback: `movements/${mutationsTypes.SET_MOVEMENTS_LIST}`, limit }, { root: true }));
    }
  }
};

// mutations
const mutations = {
  [mutationsTypes.SET_MOVEMENTS_LIST]: (state, movements) => {
    state.movementList.data = movements;
    state.movementList.lastUpdate = new Date();
  },
  [mutationsTypes.PUSH_MOVEMENTS_LIST]: (state, movements) => {
    state.movementList.data.push(...movements);
    state.movementList.lastUpdate = new Date();
  }
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations
};
