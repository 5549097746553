<template>
  <router-view />
</template>

<script>
export default {
  name: 'CategoryLayout'
};
</script>

<style>

</style>
