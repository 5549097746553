// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Buefy from 'buefy';
import Vue from 'vue';
import VueMoment from 'vue-moment';

import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import 'moment/locale/fr';
import 'moment/locale/ko';
import 'moment/locale/en-gb';

import config from '@/config';

import '@/filters';

import App from '@/App';
import router from '@/router';
import store from '@/store';

import Frontlines from '@/plugins/axios-frontlines';

import LayoutHeader from '@/components/layout/Header';
import LayoutPreFooter from '@/components/layout/PreFooter';
import LayoutFooter from '@/components/layout/Footer';
import Breadcrumbs from '@/components/layout/Breadcrumbs';
import i18n from './i18n';
import './registerServiceWorker';

Vue.use(Buefy, {
  defaultIconPack: 'fas'
});
momentDurationFormatSetup(moment);
Vue.use(VueMoment, { moment });

Vue.use(i18n);
Vue.use(Frontlines);

Vue.config.productionTip = false;

const frontlines = new Frontlines({ baseURL: config.api.baseURL });

Vue.component('LayoutHeader', LayoutHeader);
Vue.component('LayoutPreFooter', LayoutPreFooter);
Vue.component('LayoutFooter', LayoutFooter);
Vue.component('Breadcrumbs', Breadcrumbs);

Vue.prototype.$langs = [{
  locale: 'fr',
  label: 'Français',
  flag: 'fr'
}, {
  locale: 'en',
  label: 'English',
  flag: 'gb'
}, {
  locale: 'it',
  label: 'Italiano',
  flag: 'it'
}];

// // Register a global custom directive called `v-focus`
// Vue.directive('focus', {
//   // When the bound element is inserted into the DOM...
//   inserted (el) {
//     // console.log(el);
//     // Focus the element
//     el.focus();
//   }
// });

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  frontlines,
  components: {
    App
  },
  data: {},
  computed: {},
  beforeCreate () {
    this.$store.dispatch('initialiseStore');
    this.$moment.locale('fr');
  },
  router,
  i18n,
  methods: {},
  template: '<App/>'
});
