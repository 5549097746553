<template>
  <div>
    <section class="section">
      <div class="container">
        <breadcrumbs />
        <div class="columns">
          <div class="column is-6 has-text-centered">
            <h1 class="title">
              {{ $t('adminChanges.adminChanges') }}
            </h1>
          </div>
          <div class="column is-6">
            <div class="columns is-mobile">
              <div class="column is-8 is-offset-2">
                <div class="content">
                  <p>
                    {{ $t('adminChanges.iWish') }}
                  </p>
                  <ul>
                    <li>
                      <a
                        class="scroll"
                        href="#new-vehicle-anch"
                      >{{ $t('adminChanges.newVehicle') }}</a>
                    </li>
                    <!-- <li>
                    <a class="scroll" href="#new-address-anch">{{$t('adminChanges.changeIban')}}</a>
                  </li> -->
                    <li>
                      <a
                        class="scroll"
                        href="#dematerialize-invoices-anchor"
                      >{{ $t('adminChanges.dematerializeInvoices') }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="new-vehicle-anch"
      class="hero is-primary"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6 has-text-centered">
              <h2 class="title is-size-3 has-text-centered-touch">
                {{ $t('adminChanges.newVehicle') }}
              </h2>
            </div>
            <div class="column is-6">
              <div>
                <b-field :label="$t('adminChanges.selectTag')">
                  <b-select
                    v-model="selectedCard"
                    expanded="expanded"
                    icon="credit-card"
                  >
                    <option
                      selected="selected"
                      value="None"
                    >
                      {{ $t('adminChanges.noVehicle') }}
                    </option>
                    <template
                      v-for="tag in tags"
                    >
                      <optgroup
                        v-if="!tag.props.terminated &&
                          (tag.applicable.to === null ||
                            $moment(tag.applicable.to).isAfter($moment(new Date())))"
                        :key="tag.id"
                        :label="tag.id +'-'+tag.holder.title+' '+tag.holder.firstname
                          +' '+tag.holder.lastname"
                      >
                        <option
                          v-if="tag.vehicles[0] &&
                            (tag.vehicles[0].brand ||
                              tag.vehicles[0].plate ||
                              tag.vehicles[0].color)"
                          :value="{tag: tag.id, vehicle: 1}"
                        >
                          {{ tag.vehicles[0].brand }}
                          {{ tag.vehicles[0].plate }}
                          {{ tag.vehicles[0].color }}
                        </option>
                        <option
                          v-else
                          :value="{tag: tag.id, vehicle: 1}"
                        >
                          {{ $t('adminChanges.addVehicle') }}
                        </option>
                        <option
                          v-if="tag.vehicles[1] &&
                            (tag.vehicles[1].brand ||
                              tag.vehicles[1].plate ||
                              tag.vehicles[1].color)"
                          :value="{tag: tag.id, vehicle: 2}"
                        >
                          {{ tag.vehicles[1].brand }}
                          {{ tag.vehicles[1].plate }}
                          {{ tag.vehicles[1].color }}
                        </option>
                        <option
                          v-else-if="tag.vehicles[0] &&
                            (tag.vehicles[0].brand ||
                              tag.vehicles[0].plate ||
                              tag.vehicles[0].color)"
                          :value="{tag: tag.id, vehicle: 2}"
                        >
                          {{ $t('adminChanges.addVehicle') }}
                        </option>
                        <option
                          v-if="tag.vehicles[2] &&
                            (tag.vehicles[2].brand ||
                              tag.vehicles[2].plate ||
                              tag.vehicles[2].color)"
                          :value="{tag: tag.id, vehicle: 3}"
                        >
                          {{ tag.vehicles[2].brand }}
                          {{ tag.vehicles[2].plate }}
                          {{ tag.vehicles[2].color }}
                        </option>
                        <option
                          v-else-if="tag.vehicles[1] &&
                            (tag.vehicles[1].brand ||
                              tag.vehicles[1].plate ||
                              tag.vehicles[1].color)"
                          :value="{tag: tag.id, vehicle: 3}"
                        >
                          {{ $t('adminChanges.addVehicle') }}
                        </option>
                      </optgroup>
                    </template>
                  <!-- <option :value="tag.id" v-for="tag in tags"
                  v-if="!tag.props.terminated && (tag.applicable.to === null ||
                  $moment(tag.applicable.to).isAfter($moment(new Date())))">{{tag.id}}
                    -
                    {{tag.holder.title}}
                    {{tag.holder.firstname}}
                    {{tag.holder.lastname}}
                  </option> -->
                  </b-select>
                </b-field>
                <b-field :label="$t('adminChanges.joinVehiclePapers')">
                  <b-field
                    class="file"
                    style="justify-content: center;"
                  >
                    <b-upload
                      v-model="dropFile"
                      :disabled="selectedCard === 'None'"
                    >
                      <a
                        class="button is-primary is-inverted"
                        :disabled="selectedCard === 'None'"
                      >
                        <b-icon icon="upload" />
                        <span>{{ $t('adminChanges.uploadVehicleFile') }}</span>
                      </a>
                    </b-upload>
                    <span
                      v-if="dropFile"
                      class="file-name"
                    >
                      {{ dropFile.name }}
                    </span>
                  </b-field>
                </b-field>
                <b-button
                  :disabled="selectedCard === 'None' || !dropFile || isUploading === true"
                  :loading="isUploading"
                  class="button is-success is-fullwidth"
                  @click="sendFiles"
                >
                  {{ $t('adminChanges.askChangeVehicle') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="dematerialize-invoices-anchor"
      class="hero is-light"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6 has-text-centered">
              <h2 class="title is-size-3 has-text-centered-touch">
                {{ $t('adminChanges.dematerializeInvoices') }}
              </h2>
            </div>
            <div class="column is-6">
              <b-field :label="$t('adminChanges.selectContracts')" />
              <div
                v-for="contract in ordDemat"
                :key="contract.id"
                class="field"
              >
                <b-checkbox
                  v-if="contract.props.dematerialized"
                  :native-value="contract.id"
                  :value="true"
                  :disabled="contract.props.dematerialized"
                >
                  {{ contract.id }}
                  <small>{{ contract.product.label }}</small>
                  -
                  {{ contract.iban.title }}
                  {{ contract.iban.firstname }}
                  {{ contract.iban.lastname }}
                </b-checkbox>
                <b-checkbox
                  v-else
                  v-model="invoicesDematerializeContracts"
                  :native-value="contract.id"
                  :disabled="contract.props.dematerialized"
                >
                  {{ contract.id }}
                  <small>{{ contract.product.label }}</small>
                  -
                  {{ contract.iban.title }}
                  {{ contract.iban.firstname }}
                  {{ contract.iban.lastname }}
                </b-checkbox>
              </div>
              <button
                class="button is-success is-fullwidth"
                @click="dematerialize()"
              >
                {{ $t('adminChanges.askDematerializedInvoices') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import {
  mask
} from 'vue-the-mask';

export default {
  name: 'ModifAdmin',
  components: {
  },
  directives: {
    mask
  },
  data () {
    return {
      selectedCard: 'None',
      isUploading: false,
      dropFile: null,
      ibanChangeContracts: [],
      iban: {
        title: 'Mr',
        firstname: '',
        lastname: '',
        swift: '',
        iban: ''
      },
      invoicesDematerializeContracts: [],
      percentCompleted: 0
    };
  },
  computed: {
    ordDemat () {
      return [...this.contracts]
        .filter((contract) => !contract.product.id.startsWith('CD.'))
        .sort((x) => (x.props.dematerialized ? -1 : 1));
    },
    ...mapGetters('tags', ['tags']),
    ...mapGetters('contracts', ['contracts'])
  },
  mounted () {
    this.$store.dispatch('tags/getTags');
    this.$store.dispatch('contracts/getContracts');
  },
  methods: {
    changeIban () {
      for (let i = 0, j = this.ibanChangeContracts.length; i < j; i += 1) {
        this.$http.post('contract/iban', {
          ...this.iban,
          contract: this.ibanChangeContracts[i].id,
          id: this.ibanChangeContracts[i].iban.id
        }).then((response) => {
          if (response.data.success) {
            this.$buefy.notification.open({
              duration: 30000,
              message: this.$t('adminChanges.successChangeIban', [this.ibanChangeContracts[i].id]),
              type: 'is-success',
              'has-icon': true
            });
          }
        }).catch((error) => {
          this.$buefy.snackbar.open({
            indefinite: true,
            message: `${this.$t('myAccount.errorChangeIban', error)}`,
            type: 'is-warning',
            actionText: this.$t('adminChanges.retry'),

            onAction: this.changeIban
          });
        });
      }
    },
    sendFiles () {
      if (this.selectedCard !== 'None') {
        this.isUploading = true;
        const formData = new FormData();
        formData.append('tag', this.selectedCard.tag);
        formData.append('vehicle', this.selectedCard.vehicle);
        formData.append('documents[0]', this.dropFile);
        this.$http.post('contract/vehicle', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          this.isUploading = false;
          if (response.data.success) {
            this.dropFile = null;
            this.$buefy.notification.open({
              duration: 30000,
              message: this.$t('adminChanges.successChangeVehicle', [this.selectedCard.tag, this.selectedCard.vehicle]),
              type: 'is-success',
              'has-icon': true
            });
          }
        }).catch((error) => {
          this.isUploading = false;
          this.dropFile = null;
          this.$buefy.snackbar.open({
            indefinite: true,
            message: `${this.$t('adminChanges.errorChangeVehicle', error)}`,
            type: 'is-warning',
            actionText: this.$t('adminChanges.retry'),

            onAction: this.sendFiles
          });
        });
      }
    },
    dematerialize () {
      for (let i = 0, j = this.invoicesDematerializeContracts.length; i < j; i += 1) {
        this.$http.post('contract/dematerialize', {
          contract: this.invoicesDematerializeContracts[i]
        }).then((response) => {
          if (response.data.success) {
            this.$buefy.notification.open({
              duration: 30000,
              message: this.$t('myAccount.dematerializeInvoices.successAskChange', [this.invoicesDematerializeContracts.join(', ')]),
              type: 'is-success',
              'has-icon': true
            });
          }
        }).catch((error) => {
          this.$buefy.snackbar.open({
            indefinite: true,
            message: `${this.$t('myAccount.dematerializeInvoices.errorAskChange', error)}`,
            type: 'is-warning',
            actionText: this.$t('adminChanges.retry'),

            onAction: this.dematerialize
          });
        });
      }
    }
  }
};
</script>

<style></style>
